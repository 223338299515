<template>
  <div class="flex min-h-[calc(100vh-40px)] bg-gray-50 relative">
    <!-- Toggle Button - Only show when sidebar is closed -->
    <button 
      v-show="!isOpen"
      @click="toggleSidebar" 
      class="fixed top-4 left-4 z-40 p-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-all duration-300 shadow-lg md:left-0"
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Sidebar with smooth transition -->
    <aside 
      class="fixed md:relative inset-y-0 left-0 transform transition-transform duration-300 ease-in-out overflow-y-auto bg-gray-800 h-full"
      :class="[
        isOpen ? 'translate-x-0 w-64' : '-translate-x-full md:-translate-x-64',
        'z-30'
      ]"
    >
      <div class="flex justify-between items-center p-4 sticky top-0 bg-gray-800 z-10">
        <h2 class="text-lg font-bold text-white">
          Transport Management
        </h2>
        <button 
          @click="toggleSidebar" 
          class="text-white p-2 hover:bg-gray-700 rounded-lg transition-colors duration-200"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      
      <!-- <nav class="mt-4">
  <ul class="flex flex-col space-y-2 pb-4">
    <li v-for="(route, index) in routes" :key="index">
      <router-link 
        :to="route.path"
        class="block py-3 px-4 text-white hover:bg-gray-700 rounded transition-colors duration-200 relative group"
        :class="{ 'bg-gray-700': $route.path === route.path }"
        @click="handleRouteClick"
      >
        <div class="flex items-center space-x-3">
          <i :class="route.icon" class="text-xl"></i>
          <span class="text-sm">{{ route.name }}</span>
        </div>
      </router-link>
    </li>
  </ul>
</nav> -->
<nav class="mt-4">
  <!-- Settings Menu -->
  <button 
    @click="toggleMenu('settings')" 
    class="block py-3 px-4 text-white font-medium hover:bg-gray-700 rounded transition-colors duration-200 w-full"
  >
    ตั้งค่าข้อมูล
    <i :class="[isSettingsMenuOpen ? 'fa-angle-up' : 'fa-angle-down', 'fas ml-2']"></i>
  </button>
  <ul v-if="isSettingsMenuOpen" class="flex flex-col space-y-2 pb-4">
    <li v-for="(route, index) in settingsRoutes" :key="index">
      <router-link
        :to="route.path"
        class="block py-3 px-4 text-white hover:bg-gray-700 rounded transition-colors duration-200 relative group"
        :class="{ 'bg-gray-700': $route.path === route.path }"
        @click="handleRouteClick"
      >
        <div class="flex items-center space-x-3">
          <i :class="route.icon" class="text-xl"></i>
          <span class="text-sm">{{ route.name }}</span>
        </div>
      </router-link>
    </li>
  </ul>

  <!-- Save Menu -->
  <button 
    @click="toggleMenu('save')" 
    class="block py-3 px-4 text-white font-medium hover:bg-gray-700 rounded transition-colors duration-200 w-full"
  >
    บันทึกข้อมูล
    <i :class="[isSaveMenuOpen ? 'fa-angle-up' : 'fa-angle-down', 'fas ml-2']"></i>
  </button>
  <ul v-if="isSaveMenuOpen" class="flex flex-col space-y-2 pb-4">
    <li v-for="(route, index) in saveRoutes" :key="index">
      <router-link
        :to="route.path"
        class="block py-3 px-4 text-white hover:bg-gray-700 rounded transition-colors duration-200 relative group"
        :class="{ 'bg-gray-700': $route.path === route.path }"
        @click="handleRouteClick"
      >
        <div class="flex items-center space-x-3">
          <i :class="route.icon" class="text-xl"></i>
          <span class="text-sm">{{ route.name }}</span>
        </div>
      </router-link>
    </li>
  </ul>

  <!-- Report Menu -->
  <button 
    @click="toggleMenu('report')" 
    class="block py-3 px-4 text-white font-medium hover:bg-gray-700 rounded transition-colors duration-200 w-full"
  >
    รายงาน
    <i :class="[isReportMenuOpen ? 'fa-angle-up' : 'fa-angle-down', 'fas ml-2']"></i>
  </button>
  <ul v-if="isReportMenuOpen" class="flex flex-col space-y-2 pb-4">
    <li v-for="(route, index) in reportRoutes" :key="index">
      <router-link
        :to="route.path"
        class="block py-3 px-4 text-white hover:bg-gray-700 rounded transition-colors duration-200 relative group"
        :class="{ 'bg-gray-700': $route.path === route.path }"
        @click="handleRouteClick"
      >
        <div class="flex items-center space-x-3">
          <i :class="route.icon" class="text-xl"></i>
          <span class="text-sm">{{ route.name }}</span>
        </div>
      </router-link>
    </li>
  </ul>
</nav>
    </aside>

    <!-- Main Content with smooth transition -->
    <div 
      class="flex-1 flex flex-col min-h-0 transition-all duration-300 ease-in-out"
      :class="{ 
        'ml-0 md:ml-16': isOpen,
        'ml-0': !isOpen 
      }"
    >
      <main class="flex-1 overflow-auto p-4 pb-16">
        <div class="container mx-auto">
          <slot></slot>
        </div>
      </main>
    </div>

    <!-- Overlay for mobile -->
    <div 
      v-if="isOpen && isMobile" 
      class="fixed inset-0 bg-black bg-opacity-50 z-20"
      @click="toggleSidebar"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'TransportLayout',
  data() {
    return {
      isOpen: true,
      isMobile: false,
      routes: [
        { 
          path: '/transport/routesettings', 
          name: 'ตั้งค่าเส้นทาง',
          icon: 'fas fa-route' ,
          group: 'settings'
        },
        { 
          path: '/transport/producttypesettings', 
          name: 'ตั้งค่าประเภทสินค้า',
          icon: 'fas fa-truck' ,
          group: 'settings'
        },
        { 
          path: '/transport/employeesettings', 
          name: 'ตั้งค่าข้อมูลพนักงาน',
          icon: 'fas fa-user' ,
          group: 'settings'
        },
        { 
          path: '/transport/vehiclesettings', 
          name: 'ตั้งค่าข้อมูลรถ',
          icon: 'fas fa-wrench' ,
          group: 'settings'
        },
        {
          path: '/transport/transportsettings',
          name: 'ค่าขนส่ง',
          icon: 'fas fa-dollar-sign',
          group: 'settings'
        },
        {
          path: '/transport/savedata',
          name: 'บันทึกข้อมูลตั๋ว',
          icon: 'fas fa-save',
          group: 'save'
        },
        {
          path: '/transport/savedata',
          name: 'ข้อมูลตั๋ว',
          icon: 'fas fa-save',
          group: 'save'
        },
        {
          path: '/transport/savedata',
          name: 'บันทึกข้อมูลน้ำมัน',
          icon: 'fas fa-save',
          group: 'save'
        },
        {
          path: '/transport/savedata',
          name: 'ข้อมูลน้ำมัน',
          icon: 'fas fa-save',
          group: 'save'
        },
        {
          path: '/transport/reports',
          name: 'รายงาน ปตท.',
          icon: 'fas fa-chart-bar',
          group: 'report'
        },
        {
          path: '/transport/reports',
          name: 'รายงานประจำวัน',
          icon: 'fas fa-chart-bar',
          group: 'report'
        },
        {
          path: '/transport/reports',
          name: 'รายงานประจำเดือน',
          icon: 'fas fa-chart-bar',
          group: 'report'
        },
        {
          path: '/transport/reports',
          name: 'รายงานเส้นทาง',
          icon: 'fas fa-chart-bar',
          group: 'report'
        },
        {
          path: '/transport/reports',
          name: 'รายงานค่าเที่ยว',
          icon: 'fas fa-chart-bar',
          group: 'report'
        },
      ],
      isSettingsMenuOpen: true,
      isSaveMenuOpen: true,
      isReportMenuOpen: true,
      activeGroup: null
    }
  },
  computed: {
    settingsRoutes() {
      return this.routes.filter(route => route.group === 'settings')
    },
    saveRoutes() {
      return this.routes.filter(route => route.group === 'save')
    },
    reportRoutes() {
      return this.routes.filter(route => route.group === 'report')
    }
    
   
  },
  methods: {
    toggleMenu(group) {
      switch(group) {
        case 'settings':
          this.isSettingsMenuOpen = !this.isSettingsMenuOpen
          break
        case 'save':
          this.isSaveMenuOpen = !this.isSaveMenuOpen
          break
        case 'report':
          this.isReportMenuOpen = !this.isReportMenuOpen
          break
      }
    },
    toggleSettingsMenu () {
      this.isSettingsMenuOpen = !this.isSettingsMenuOpen
    },
    toggleReportMenu() {
      this.isReportMenuOpen = !this.isReportMenuOpen
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen
      if (this.isMobile) {
        document.body.style.overflow = this.isOpen ? 'hidden' : ''
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 768
      if (!this.isMobile) {
        this.isOpen = true
        document.body.style.overflow = ''
      }
    },
    handleRouteClick() {
      if (this.isMobile) {
        this.isOpen = false
        document.body.style.overflow = ''
      }
    }
  },
  watch: {
    '$route'() {
      if (this.isMobile) {
        this.isOpen = false
        document.body.style.overflow = ''
      }
    }
  },
  mounted() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile)
    document.body.style.overflow = ''
  }
}
</script>