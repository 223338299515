<template>
    <TransportLayout>
      <div class="container mx-auto px-4 py-6">
        <!-- Header -->
        <div class="flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold text-gray-800">รายการตั๋วขนส่ง</h1>
          <button 
            @click="exportTickets" 
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-9.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            ส่งออก
          </button>
        </div>
  
        <!-- Filter Section -->
        <div class="bg-white shadow-md rounded-lg p-6 mb-6">
          <div class="grid md:grid-cols-3 gap-4">
            <!-- Multiselect ค้นหาชื่อ พขร. -->
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2">พขร.</label>
        <Multiselect 
          v-model="selectedDriver" 
          :options="driverOptions"
          placeholder="เลือก พขร."
          label="name"
          track-by="id"
        ></Multiselect>
      </div>
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
              <Multiselect 
                v-model="filterDepartment" 
                :options="departmentOptions"
                placeholder="เลือกหน่วยงาน"
                label="name"
                track-by="id"
              ></Multiselect>
            </div>
            
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">วันที่เริ่มงาน</label>
              <input 
                type="date" 
                v-model="startDateFilter"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
            
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">วันที่จบงาน</label>
              <input 
                type="date" 
                v-model="endDateFilter"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
          </div>
          
          <div class="mt-4 flex justify-end space-x-2">
            <button 
              @click="searchTickets"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
              ค้นหา
            </button>
            
            <button 
              @click="resetFilters"
              class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm14 5a1 1 0 00-1-1h-5a1 1 0 100 2h3.101a7.002 7.002 0 01-11.601 2.566 1 1 0 11-1.885.666A9.002 9.002 0 0016.101 7H11a1 1 0 010-2h5a1 1 0 011 1v5a1 1 0 11-2 0z" clip-rule="evenodd" />
              </svg>
              รีเซ็ต
            </button>
          </div>
        </div>
  
        <!-- Loading Spinner -->
        <div v-if="isLoading" class="flex justify-center items-center h-64">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
  
        <!-- Tickets Table -->
        <div v-else-if="tickets.length" class="bg-white shadow-md rounded-lg overflow-hidden">
            <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              วันที่
            </th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              หมายเลขตั๋ว
            </th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              พขร.
            </th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              คู่รถ
            </th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              จำนวน Trip
            </th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              รวมระยะทาง
            </th>
            <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              การกระทำ
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr 
            v-for="ticket in tickets" 
            :key="ticket.ticket_id" 
            class="hover:bg-gray-50"
          >
            <td class="px-4 py-4 whitespace-nowrap">
              {{ formatDate(ticket.start_date) }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.ticket_number }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.fullnameDriver1 }} 
              {{ ticket.fullnameDriver2 ? `& ${ticket.fullnameDriver2}` : '' }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.pairedcarcode }} ({{ ticket.pairedcarlicense }})
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.total_trips }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap">
              {{ ticket.total_distance }} กม.
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-center">
              <div class="flex justify-center space-x-2">
                <button 
                  @click="openTicketModal(ticket)"
                  class="text-blue-500 hover:text-blue-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button 
                @click="editTicket(ticket.ticket_id)" 
                  class="text-green-500 hover:text-green-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button 
                  @click="deleteTicket(ticket.ticket_id)"
                  class="text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
</div>
      <!-- Pagination -->
      <div class="px-4 py-3 bg-gray-50 flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
        <span class="text-sm text-gray-700">
          แสดง {{ tickets.length }} รายการ จากทั้งหมด {{ totalTickets }}
        </span>
        <div class="flex items-center space-x-2">
          <button 
            @click="prevPage" 
            :disabled="currentPage === 1"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            ก่อนหน้า
          </button>
          <span class="text-sm text-gray-700">
            หน้า {{ currentPage }} / {{ totalPages }}
          </span>
          <button 
            @click="nextPage"
            :disabled="currentPage === totalPages"
            class="px-3 py-1 bg-gray-200 text-gray-700 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-300"
          >
            ถัดไป
          </button>
        </div>
      </div>
    </div>

    <!-- No Data State -->
    <div v-else class="text-center py-6 bg-white rounded-lg shadow">
      ไม่พบข้อมูลตั๋วขนส่ง
        </div>

        <div v-if="isModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
    <h2 class="text-lg font-bold text-gray-800 mb-4">รายละเอียดตั๋วขนส่ง</h2>
    <p><strong>วันที่:</strong> {{ formatDate(selectedTicket?.created_at) }}</p>
    <p><strong>หมายเลขตั๋ว:</strong> {{ selectedTicket?.ticket_number }}</p>
    <p><strong>พขร.:</strong> {{ selectedTicket?.fullnameDriver1 }} {{ selectedTicket?.fullnameDriver2 }}</p>
    <p><strong>คู่รถ:</strong> {{ selectedTicket?.pairedcarcode }} ({{ selectedTicket?.pairedcarlicense }})</p>
    <p><strong>จำนวน Trip:</strong> {{ selectedTicket?.total_trips }}</p>
    <p><strong>รวมระยะทาง:</strong> {{ selectedTicket?.total_distance }} กม.</p>
    <div class="mt-4 flex justify-end space-x-2">
      <button 
        @click="isModalOpen = false" 
        class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
      >
        ปิด
      </button>
    </div>
  </div>
</div>

<div 
  v-if="isEditModalOpen" 
  class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
>
  <div class="relative w-full max-w-5xl mx-auto my-6">
    <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
      <!-- Header -->
      <div class="flex items-start justify-between p-5 border-b border-solid rounded-t">
        <h3 class="text-xl font-semibold">แก้ไขตั๋วขนส่ง</h3>
        <button 
          @click="closeEditModal"
          class="text-gray-400 hover:text-gray-600 text-2xl"
        >
          &times;
        </button>
      </div>

      <!-- Modal Body -->
      <div class="relative p-6 flex-auto max-h-[80vh] overflow-y-auto">
        <!-- ข้อมูลตั๋วขนส่ง -->
        <h4 class="text-lg font-bold text-gray-700 mb-4">ข้อมูลตั๋วขนส่ง</h4>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <!-- หน่วยงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
            <multiselect 
              v-model="editTicketData.departmentId" 
              :options="departmentOptions" 
              placeholder="เลือกหน่วยงาน"
              label="name"
              track-by="id"
            />
          </div>

          <!-- กลุ่มงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">กลุ่มงาน</label>
            <multiselect 
              v-model="editTicketData.workGroupId" 
              :options="workGroupOptions" 
              placeholder="เลือกกลุ่มงาน"
              label="name"
              track-by="id"
            />
          </div>

          <!-- วันที่เริ่มงาน -->
          <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">วันที่เริ่มงาน</label>
  <input 
  v-model="formattedStartDate" 
  type="date" 
  class="w-full px-3 py-2 border rounded"
/>
</div>

          <!-- วันที่จบงาน -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">วันที่จบงาน</label>
            <input 
  v-model="editTicketData.completionDate" 
  type="date" 
  class="w-full px-3 py-2 border rounded"
/>
          </div>

          <!-- จำนวน Trip -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">จำนวน Trip</label>
            <input 
              :value="trips.length" 
              disabled 
              class="w-full px-3 py-2 border rounded bg-gray-100"
            />
          </div>

          <!-- รวมระยะทาง -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">รวมระยะทาง</label>
            <input 
              :value="calculateOverallDistance()" 
              disabled 
              class="w-full px-3 py-2 border rounded bg-gray-100"
            />
          </div>

          <!-- พขร.1 -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 1</label>
            <multiselect 
              v-model="editTicketData.driver1Id" 
              :options="employeeOptions" 
              placeholder="เลือก พขร.1"
              label="fullName"
              track-by="id"
            />
          </div>

          <!-- พขร.2 -->
          <div>
            <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 2</label>
            <multiselect 
              v-model="editTicketData.driver2Id" 
              :options="employeeOptions" 
              placeholder="เลือก พขร.2"
              label="fullName"
              track-by="id"
            />
          </div>

          <!-- คู่รถ -->
          <div class="col-span-2">
            <label class="block text-gray-700 text-sm font-bold mb-2">คู่รถ</label>
            <multiselect 
              v-model="editTicketData.vehiclePairId" 
              :options="vehiclePairOptions" 
              placeholder="เลือกคู่รถ"
              label="vehicleCodeLabel"
              track-by="id"
            />
          </div>
        </div>

        <!-- รายการ Trips -->
        <h4 class="text-lg font-bold text-gray-700 mt-6 mb-4">รายการ Trips</h4>
        <div class="space-y-6">
          <div 
            v-for="(trip, index) in trips" 
            :key="index" 
            class="grid grid-cols-2 md:grid-cols-3 gap-4 border p-4 rounded"
          >
            <!-- Trip No -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">Trip No</label>
              <input 
                type="text" 
                :value="`Trip ${index + 1}`" 
                disabled 
                class="w-full px-3 py-2 border rounded bg-gray-100"
              />
            </div>

            <!-- หมายเลขตั๋ว -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">หมายเลขตั๋ว</label>
              <input 
                v-model="trip.ticketNumber" 
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกหมายเลขตั๋ว"
              />
            </div>

            <!-- ประเภทสินค้า -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า</label>
              <multiselect 
                v-model="trip.productType" 
                :options="productTypeOptions"
                placeholder="เลือกประเภทสินค้า"
                label="name"
                track-by="id"
              />
            </div>

            <!-- เที่ยวที่ -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">เที่ยวที่</label>
              <input 
                v-model="trip.tripSequence" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกเที่ยวที่"
              />
            </div>

            <!-- ความจุ -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ความจุ</label>
              <input 
                v-model="trip.capacity" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกความจุ"
              />
            </div>

            <!-- เส้นทาง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">เส้นทาง</label>
              <multiselect 
                v-model="trip.route" 
                :options="routeOptions"
                placeholder="เลือกเส้นทาง"
                label="name"
                track-by="id"
              />
            </div>

            <!-- ระยะทาง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ระยะทาง (กม.)</label>
              <input 
                v-model="trip.distance" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="ระยะทาง"
              />
            </div>

            <!-- ค่าขนส่ง -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ค่าขนส่ง</label>
              <input 
                v-model="trip.transportFee" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="ค่าขนส่ง"
              />
            </div>

            <!-- ค่าทางด่วน -->
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2">ค่าทางด่วน</label>
              <input 
                v-model="trip.tollFee" 
                type="number" 
                class="w-full px-3 py-2 border rounded"
                placeholder="ค่าทางด่วน"
              />
            </div>

            <!-- Checkbox นอนโหลด -->
            <div>
              <label class="flex items-center text-gray-700 text-sm font-bold mb-2">
                <input 
                  type="checkbox" 
                  v-model="trip.isLoadStatusEnabled"
                  class="mr-2"
                />
                นอนโหลด
              </label>
            </div>

            <!-- สถานะนอนโหลด -->
            <div v-if="trip.isLoadStatusEnabled">
              <label class="block text-gray-700 text-sm font-bold mb-2">สถานะนอนโหลด</label>
              <multiselect 
                v-model="trip.loadStatus" 
                :options="loadStatusOptions"
                placeholder="เลือกสถานะโหลด"
              ></multiselect>
            </div>

            <!-- หมายเหตุ -->
            <div class="col-span-2 md:col-span-3">
              <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ</label>
              <textarea 
                v-model="trip.remarks"
                class="w-full px-3 py-2 border rounded"
                placeholder="กรอกหมายเหตุ"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="flex items-center justify-end p-6 border-t border-solid rounded-b">
        <button
          @click="closeEditModal"
          class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-4"
        >
          ยกเลิก
        </button>
        <button
          @click="confirmSaveEditDetails"
          class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
        >
          บันทึก
        </button>
      </div>
    </div>
  </div>
</div>





      </div>
    </TransportLayout>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import TransportLayout from '@/components/transport/TransportSidebar.vue'
  
  export default {
    name: 'ViewTicket',
    components: {
      TransportLayout,
      Multiselect
    },
    data() {
      return {
        isLoadStatusEnabled: false, // ควบคุมสถานะโหลด
        tickets: [],
        totalTickets: 0,
        currentPage: 1,
        totalPages: 1,
        filterDepartment: null,
        startDateFilter: null,
        endDateFilter: null,
        isLoading: false,
        selectedDriver: null, // ฟิลด์ใหม่สำหรับเลือก พขร.
        departmentOptions: [],
        driverOptions: [], // ตัวเลือก พขร.
        isModalOpen: false, // สำหรับเปิด/ปิด Modal
        selectedTicket: null, // เก็บข้อมูลตั๋วที่เลือก
        isEditModalOpen: false, // เปิด/ปิด Modal

    loadStatusOptions: [
        'เต็ม', 
        'ครึ่งหนึ่ง', 
        'ว่าง'
      ],
   
        editTicketData: {
      tripNo: '',
      driver1Id: null,
      driver2Id: null,
      vehiclePairId: null,
      ticketNumber: '',
      productTypeId: null,
      tripSequence: null,
      capacity: null,
      routeId: null,
      distance: null,
      transportFee: null,
      tollFee: null,
      loadStatus: null,
      startDate: null,
      completionDate: null,
      remarks: ''
    },
    trips: [], // รายการ trips
    employeeOptions: [],
    vehiclePairOptions: [],
    productTypeOptions: [],
    routeOptions: [],
      }
    },

    computed: {
  formattedStartDate: {
    get() {
      return this.formatDateForInput(this.editTicketData.startDate);
    },
    set(newValue) {
      this.editTicketData.startDate = newValue;
    }
  },
  formattedcompletionDate: {
    get() {
      return this.formatDateForInput(this.editTicketData.completionDate);
    },
    set(newValue) {
      this.editTicketData.completionDate = newValue;
    }
  },
},

    methods: {
      calculateOverallDistance() {
  return this.trips.reduce((total, trip) => total + (Number(trip.distance) || 0), 0);
}
,
async fetchInitialData() {
  try {
    // ดึงข้อมูลพร้อมกัน
    const [
      employeesResponse,
      vehiclePairsResponse,
      departmentsResponse,
      workGroupsResponse,
      productTypesResponse,
      routesResponse
    ] = await Promise.allSettled([
      axios.get('http://localhost:3000/api/ts/employees'),
      axios.get('http://localhost:3000/api/ts/vehicle-pairs'),
      axios.get('http://localhost:3000/api/ts/departments'),
      axios.get('http://localhost:3000/api/ts/groups'),
      axios.get('http://localhost:3000/api/ts/product-types'),
      axios.get('http://localhost:3000/api/ts/routes')
    ]);

    // พนักงาน
    if (employeesResponse.status === 'fulfilled') {
      this.employeeOptions = employeesResponse.value.data.map(emp => ({
        id: emp.id,
        fullName: `${emp.prefix || ''} ${emp.firstName || ''} ${emp.lastName || ''}`.trim(),
        employeeCode: emp.employee_code || ''
      }));
    } else {
      console.error('Error fetching employees:', employeesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลพนักงานได้'
      });
    }

    // คู่รถ
    if (vehiclePairsResponse.status === 'fulfilled') {
      this.vehiclePairOptions = vehiclePairsResponse.value.data.map(pair => ({
        id: pair.id,
        departmentId: pair.departmentId || null,
        departmentName: pair.departmentName || '',
        groupId: pair.groupId || null,
        groupName: pair.groupName || '',
        headLicensePlate: pair.headLicensePlate || '',
        headVehicleCode: pair.headVehicleCode || '',
        trailerLicensePlate: pair.trailerLicensePlate || '',
        trailerVehicleCode: pair.trailerVehicleCode || '',
        vehicleCodeLabel: `${pair.headVehicleCode || ''} + ${pair.trailerVehicleCode || ''}`.trim()
      }));
    } else {
      console.error('Error fetching vehicle pairs:', vehiclePairsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลคู่รถได้'
      });
    }

    // หน่วยงาน
    if (departmentsResponse.status === 'fulfilled') {
      this.departmentOptions = departmentsResponse.value.data.map(department => ({
        id: Number(department.id),
        name: department.name
      }));
    } else {
      console.error('Error fetching departments:', departmentsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลหน่วยงานได้'
      });
    }

    // กลุ่มงาน
    if (workGroupsResponse.status === 'fulfilled') {
      this.workGroupOptions = workGroupsResponse.value.data.map(group => ({
        id: group.id,
        name: group.name
      }));
    } else {
      console.error('Error fetching work groups:', workGroupsResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลกลุ่มงานได้'
      });
    }

    // ประเภทสินค้า
    if (productTypesResponse.status === 'fulfilled') {
      this.productTypeOptions = productTypesResponse.value.data.map(option => ({
        id: option.id,
        name: option.name
      }));
    } else {
      console.error('Error fetching product types:', productTypesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลประเภทสินค้าได้'
      });
    }

    // เส้นทาง
    if (routesResponse.status === 'fulfilled') {
      const routes = routesResponse.value.data;
      this.routeOptions = routes.map(route => ({
        id: route.id,
        name: `${route.route_name} (ต้นทาง: ${route.origin || ''}, ปลายทาง: ${route.destination || ''})`,
        distance_km: route.distance_km || 0,
        transport_fee: route.transport_fee || 0
      }));
      this.routeDestinationOptions = routes.map(route => ({
        id: route.id,
        name: route.destination || ''
      }));
    } else {
      console.error('Error fetching routes:', routesResponse.reason);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลเส้นทางได้'
      });
    }

  } catch (error) {
    console.error('Error in fetchInitialData:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถโหลดข้อมูลเริ่มต้นได้ กรุณาลองอีกครั้ง'
    });
  }
  console.log('Department Options:', this.departmentOptions);
console.log('WorkGroup Options:', this.workGroupOptions);
console.log('Employee Options:', this.employeeOptions);
console.log('VehiclePair Options:', this.vehiclePairOptions);

}
,
formatDateForInput(dateString) {
    if (!dateString) return '';
    
    // สร้าง Date object จากสตริงวันที่
    const date = new Date(dateString);
    
    // ตรวจสอบว่า Date object ถูกสร้างอย่างถูกต้อง
    if (isNaN(date.getTime())) {
      console.error('Invalid date:', dateString);
      return '';
    }
    
    // ปรับ timezone เป็น local
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    
    // แปลงเป็นรูปแบบ YYYY-MM-DD สำหรับ input type="date"
    const year = localDate.getFullYear();
    const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const day = localDate.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
},
  
  // เมธอดแปลงกลับเป็นรูปแบบฐานข้อมูล
  convertToDbFormat(inputDate) {
    if (!inputDate) return null;
    
    // ถ้าอยู่ในรูปแบบ YYYY-MM-DD อยู่แล้ว
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) return inputDate;
    
    // กรณีอื่นๆ ให้พยายามแปลง
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      console.error('Invalid date for conversion:', inputDate);
      return null;
    }
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  },
async editTicket(ticketId) {
  try {
    const response = await axios.get(`http://localhost:3000/api/ts/transport-tickets/${ticketId}`);

    const ticket = response.data.ticket?.[0] || response.data.ticket || {};
    const trips = response.data.trips || [];

    // ตั้งค่าข้อมูลตั๋วขนส่ง
    this.editTicketData = {
      // ใช้ Number() เพื่อแปลงค่า ID 
      departmentId: this.departmentOptions.find(dep => 
        Number(dep.id) === Number(ticket.department_id)
      ) || null,
      workGroupId: this.workGroupOptions.find(wg => 
        Number(wg.id) === Number(ticket.work_group_id)
      ) || null,
      startDate: ticket.start_date 
        ? this.formatDateForInput(ticket.start_date) 
        : '',
      completionDate: ticket.completion_date 
        ? this.formatDateForInput(ticket.completion_date) 
        : '',
      driver1Id: this.employeeOptions.find(emp => 
        Number(emp.id) === Number(ticket.driver1_id)
      ) || null,
      driver2Id: this.employeeOptions.find(emp => 
        Number(emp.id) === Number(ticket.driver2_id)
      ) || null,
      vehiclePairId: this.vehiclePairOptions.find(pair => 
        Number(pair.id) === Number(ticket.vehicle_pair_id)
      ) || null,
      remarks: ticket.remarks || '',
    };
    console.log('Mapped editTicketData:', this.editTicketData);
    // ตั้งค่ารายการ Trips
    this.trips = trips.map(trip => ({
      tripNo: trip.trip_no || null,
      ticketNumber: trip.ticket_number || '',
      productType: this.productTypeOptions.find(pt => 
        pt.id === Number(trip.product_type_id)
      ) || null,
      tripSequence: trip.trip_sequence || null,
      capacity: trip.capacity || null,
      route: this.routeOptions.find(rt => 
        rt.id === Number(trip.route_id)
      ) || null,
      distance: trip.distance || 0,
      transportFee: trip.transport_fee || 0,
      tollFee: trip.toll_fee || 0,
      isLoadStatusEnabled: !!trip.load_status,
      loadStatus: trip.load_status || null,
      remarks: trip.remarks || '',
    }));
    console.log('Mapped trips:', this.trips);
    this.isEditModalOpen = true;
  } catch (error) {
    Swal.fire({ 
      icon: 'error', 
      title: 'เกิดข้อผิดพลาด', 
      text: 'ไม่สามารถโหลดข้อมูลตั๋วขนส่งได้' 
    });
  }
}
,
// ยืนยันก่อนบันทึก
async confirmSaveEditDetails() {
  try {
    const updatedData = {
      ...this.editTicketData,
      trips: this.trips.map((trip, index) => ({
        tripNo: index + 1,
        ticketNumber: trip.ticketNumber,
        productTypeId: trip.productType?.id || null,
        tripSequence: trip.tripSequence || index + 1,
        capacity: Number(trip.capacity) || 0,
        routeId: trip.route?.id || null,
        distance: Number(trip.distance) || 0,
        transportFee: Number(trip.transportFee) || 0,
        tollFee: Number(trip.tollFee) || 0,
        loadStatus: trip.isLoadStatusEnabled ? trip.loadStatus : null,
        remarks: trip.remarks || '',
      })),
    };

    await axios.put(`http://localhost:3000/api/ts/transport-tickets/${this.editTicketData.tripNo}`, updatedData);

    Swal.fire({
      icon: 'success',
      title: 'บันทึกสำเร็จ',
      text: 'บันทึกข้อมูลตั๋วขนส่งและรายการ Trips เรียบร้อยแล้ว',
    });

    this.closeEditModal();
    this.fetchTickets(); // โหลดข้อมูลใหม่
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
    });
  }
  },
  validateEditFormData() {
  if (!this.editTicketData.startDate) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณากรอกวันที่เริ่มต้น' });
    return false;
  }

  if (!this.trips.every(trip => trip.ticketNumber && trip.productType?.id && trip.route?.id)) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณากรอกข้อมูลการเดินทางให้ครบถ้วน' });
    return false;
  }

  return true;
},
  async saveEditDetails() {
  if (!this.validateEditFormData()) return;

  try {
    // ตรวจสอบความพร้อมของพนักงานขับรถ
    const checkDriverResponse = await axios.post('http://localhost:3000/api/ts/check-driver-availability', {
      driver1Id: this.editTicketData.driver1Id,
      driver2Id: this.editTicketData.driver2Id,
      startDate: this.editTicketData.startDate,
    });

    // ไม่มีข้อขัดแย้ง: ดำเนินการต่อ
    if (checkDriverResponse.status === 200) {
      return this.performEditSave(); // ดำเนินการบันทึก
    }
  } catch (error) {
    if (error.response?.status === 409) {
      const conflicts = error.response.data.conflicts;

      // สร้างข้อความแจ้งเตือน
      let conflictMessage = 'พนักงานขับรถมีงานในวันดังกล่าว:\n\n';
      if (conflicts.driver1?.length) {
        conflictMessage += `พขร.1 (${this.getEmployeeName(this.editTicketData.driver1Id)}):\n`;
        conflicts.driver1.forEach(job => {
          const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
          conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
        });
        conflictMessage += '\n';
      }
      if (conflicts.driver2?.length) {
        conflictMessage += `พขร.2 (${this.getEmployeeName(this.editTicketData.driver2Id)}):\n`;
        conflicts.driver2.forEach(job => {
          const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
          conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
        });
        conflictMessage += '\n';
      }

      // ยืนยันการดำเนินการต่อ
      const confirmResult = await Swal.fire({
        icon: 'warning',
        title: 'พนักงานขับรถมีงานในวันดังกล่าว',
        html: `<pre style="text-align:left;">${conflictMessage}</pre>`,
        showCancelButton: true,
        confirmButtonText: 'ยืนยันบันทึก',
        cancelButtonText: 'ยกเลิก',
      });

      if (!confirmResult.isConfirmed) {
        return; // ยกเลิกการบันทึก
      }
    } else {
      // ข้อผิดพลาดอื่น ๆ
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
      });
      return;
    }
  }

  // บันทึกข้อมูลที่แก้ไข
  this.performEditSave();
},
getEmployeeName(employeeId) {
  const employee = this.employeeOptions.find(emp => emp.id === employeeId);
  return employee ? employee.fullName : 'ไม่ทราบชื่อ';
},
async performEditSave() {
  try {
    const updatedData = {
      ...this.editTicketData,
      trips: this.trips.map((trip, index) => ({
        tripNo: index + 1,
        ticketNumber: trip.ticketNumber,
        productTypeId: trip.productType?.id || null,
        tripSequence: trip.tripSequence || index + 1,
        capacity: Number(trip.capacity) || 0,
        routeId: trip.route?.id || null,
        distance: Number(trip.distance) || 0,
        transportFee: Number(trip.transportFee) || 0,
        tollFee: Number(trip.tollFee) || 0,
        loadStatus: trip.loadStatus || null,
        remarks: trip.remarks || '',
      }))
    };

    await axios.put(`http://localhost:3000/api/ts/transport-tickets/${this.editTicketData.tripNo}`, updatedData);

    Swal.fire({
      icon: 'success',
      title: 'บันทึกสำเร็จ',
      text: 'บันทึกข้อมูลตั๋วขนส่งและการเดินทางเรียบร้อยแล้ว',
    });

    this.closeEditModal();
    this.fetchTickets();
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: error.response?.data?.error || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
    });
  }
}
,
  // ปิด Modal
  closeEditModal() {
    this.isEditModalOpen = false;
    this.editTicketData = {};
  },

        openTicketModal(ticket) {
    this.selectedTicket = ticket;
    this.isModalOpen = true;
  },
        async fetchTickets() {
      this.isLoading = true;
      try {
        const response = await axios.get('http://localhost:3000/api/ts/transport-tickets', {
          params: {
            page: this.currentPage,
            department: this.filterDepartment?.id,
            startDate: this.startDateFilter,
            endDate: this.endDateFilter,
            driverName: this.selectedDriver?.name // ส่งชื่อ พขร.
          }
        });
        if (response.data && response.data.tickets) {
          this.tickets = response.data.tickets;
          this.totalTickets = response.data.total || 0;
          this.totalPages = response.data.totalPages || 1;
        } else {
          this.tickets = [];
          this.totalTickets = 0;
          this.totalPages = 1;
        }
      } catch (error) {
        this.showErrorToast('เกิดข้อผิดพลาดในการดึงข้อมูล');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDriverOptions() {
      try {
        const response = await axios.get('http://localhost:3000/api/ts/drivers');
        this.driverOptions = response.data.map(driver => ({
          id: driver.id,
          name: `${driver.first_name} ${driver.last_name}`
        }));
      } catch (error) {
        this.showErrorToast('ไม่สามารถโหลดข้อมูล พขร. ได้');
      }
    },
      async fetchDepartmentOptions() {
        try {
          const response = await axios.get('http://localhost:3000/api/ts/departments')
          this.departmentOptions = response.data
        } catch (error) {
          this.showErrorToast('ไม่สามารถโหลดข้อมูลหน่วยงานได้')
        }
      },
      searchTickets() {
        this.currentPage = 1
        this.fetchTickets()
      },
      resetFilters() {
        this.filterDepartment = null
        this.startDateFilter = null
        this.endDateFilter = null
        this.currentPage = 1
        this.fetchTickets()
      },
 
    formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH')
    },
    viewTicketDetails(ticketId) {
      this.$router.push(`/ticket-details/${ticketId}`)
    },

    async deleteTicket(ticketId) {
      try {
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณแน่ใจหรือไม่ว่าต้องการลบตั๋วขนส่งนี้?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })

        if (result.isConfirmed) {
          await axios.delete(`/api/transport-tickets/${ticketId}`)
          this.showSuccessToast('ลบตั๋วขนส่งสำเร็จ')
          this.fetchTickets()
        }
      } catch (error) {
        this.showErrorToast('เกิดข้อผิดพลาดในการลบ')
      }
    },
    async exportTickets() {
      try {
        const response = await axios.get('/api/transport-tickets/export', {
          params: {
            department: this.filterDepartment?.id,
            startDate: this.startDateFilter,
            endDate: this.endDateFilter
          },
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'transport_tickets.csv'
        link.click()
      } catch (error) {
        this.showErrorToast('ไม่สามารถส่งออกข้อมูลได้')
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fetchTickets()
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.fetchTickets()
      }
    },
    showSuccessToast(message) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    },
    showErrorToast(message) {
      Swal.fire({
        icon: 'error',
        title: 'ข้อผิดพลาด',
        text: message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
  },
  created() {
    this.fetchDriverOptions()
    this.fetchTickets()
    // this.fetchDepartmentOptions()
    this.fetchInitialData()
  },
  watch: {
  'editTicketData.departmentId': {
    handler(newVal) {
      console.log('Updated Department ID in editTicketData:', newVal);
    },
    immediate: true,
  },
  'editTicketData.workGroupId': {
    handler(newVal) {
      console.log('Updated WorkGroup ID in editTicketData:', newVal);
    },
    immediate: true,
  },
}

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>