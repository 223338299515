<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col mt-8">
      <div class="py-2">
        <h2 class="text-2xl font-semibold leading-tight">รายละเอียดใบสั่งซื้อ</h2><br>

  <!-- ตราสัญลักษณ์ "ไม่อนุมัติ" -->
  <div v-if="poDetail.status === 'ไม่อนุมัติ'" @click="openNoteModal" class="disapproved-stamp cursor-pointer">
      ไม่อนุมัติ
    </div>

<!-- ตราสัญลักษณ์ "อนุมัติ" -->
<div v-if="showApprovedStamp" class="approved-stamp cursor-pointer">
     รอรับอะไหล่
    </div>

    <!-- ตราสัญลักษณ์ "อนุมัติ" -->
<div v-if="showApprostamp" class="approved-stamp cursor-pointer">
     อนุมัติ
     
    </div>

    <div v-if="poDetail.status === 'รอรับอะไหล่' && fromPRForm && agency === 3 && creatorPosition === 'ธุรการซ่อมบำรุง'">
    <button @click="showConfirmModal = true" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">รับอะไหล่</button>
  </div>


  <div v-if="poDetail.status === 'รอรับอะไหล่' && fromPRForm && agency === 1 && creatorPosition !== 'หัวหน้าซ่อมบำรุงส่วนกลาง'">
  <button @click="showConfirmModal = true" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">รับอะไหล่</button>
</div>

  <!-- <div class="flex justify-end space-x-2" v-if="poDetail.status === 'รับอะไหล่แล้ว' || poDetail.status === 'สำเร็จ' && this.user.agency === 1">
    <button @click="openModal" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">ปริ้นท์</button> -->
    <!-- ปุ่มสำหรับลิงค์ไปยังหน้า DownloadPO.vue -->
    <!-- <router-link 
      v-if="selectedImageUrl" 
      :to="{ 
        name: 'DownloadPO', 
        params: { 
          poId: poDetail.po_id, 
          imageUrl: selectedImageUrl, 
          groupId: selectedGroup ? selectedGroup.group_id : null 
        } 
      }">
      <button class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">ดาวน์โหลดใบสั่งซื้อ</button>
    </router-link> -->
  <!-- </div> -->

  
  <div class="flex justify-end space-x-2"  v-if="shouldShowButton">
    <button @click="openModal" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">ปริ้นท์</button>
    <!-- ปุ่มสำหรับลิงค์ไปยังหน้า DownloadPO.vue -->
    <router-link 
      v-if="selectedImageUrl" 
      :to="{ 
        name: 'DownloadPO', 
        params: { 
          poId: poDetail.po_id, 
          imageUrl: selectedImageUrl, 
          groupId: selectedGroup ? selectedGroup.group_id : null 
        } 
      }">
      <button class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">ดาวน์โหลดใบสั่งซื้อ</button>
    </router-link>
  </div>
  <div class="flex justify-between items-center w-full">
  <!-- ปุ่มย้อนกลับ -->
  <button 
    @click="goBack"
    class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition"
  >
    <i class="fas fa-arrow-left"></i> ย้อนกลับ
  </button>
  
</div>
    <!-- ตราสัญลักษณ์ "เสร็จสิ้น" -->
<div v-if="poDetail.status === 'รับอะไหล่แล้ว'" class="approved-stamp cursor-pointer">
    เสร็จสิ้น
    </div>

    <!-- <div v-if="fromWarehouse && poDetail.status === 'รอรับอะไหล่'">
      <button 
      v-if="poDetail.creator_agency == user.agency" 
      @click="showConfirmModal = true" 
      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      รับอะไหล่
    </button>
  </div> -->
  <!-- <div>
  <p>creator_agency: {{ poDetail.creator_agency }}</p>
  <p>user agency: {{ user.agency }}</p>
</div> -->

  <div v-if=" poDetail.status === 'รอรับอะไหล่'">
    <button 
  v-if="poDetail.creator_agency === user.agency" 
  @click="showConfirmModal = true" 
  class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
  รับอะไหล่
</button>

  </div>




  <!-- Modal สำหรับแสดงหมายเหตุการไม่อนุมัติ -->
  <div v-if="showNoteModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-title">เหตุผลในการไม่อนุมัติ</h3>
                <div class="mt-2 text-lg leading-6 font-semibold bg-gray-50 text-red-500">
                  <p>{{ poDetail.note_po }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeNoteModal">ปิด</button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end space-x-2" v-if="poDetail.status === 'รับเข้าคลังแล้ว' && agency === 1">
    <button @click="openModal" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">ปริ้นท์</button>
    <!-- ปุ่มสำหรับลิงค์ไปยังหน้า DownloadPO.vue -->
    <router-link 
      v-if="selectedImageUrl" 
      :to="{ 
        name: 'DownloadPO', 
        params: { 
          poId: poDetail.po_id, 
          imageUrl: selectedImageUrl, 
          groupId: selectedGroup ? selectedGroup.group_id : null 
        } 
      }">
      <button class="bg-purple-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">ดาวน์โหลดใบสั่งซื้อ</button>
    </router-link>
  </div>
        
     <!-- Modal สำหรับเลือกหน่วยงาน -->
     <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เลือกหน่วยงาน</h3>
                    <div class="mt-2 overflow-x-auto">
                      <table class="min-w-full table-auto">
                        <thead class="bg-gray-100">
                          <tr>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ID</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อหน่วยงาน</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ที่อยู่</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">สถานที่จัดส่ง</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รูปภาพ</th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr v-for="group in userGroups" :key="group.group_id" @click="selectGroup(group)" class="cursor-pointer hover:bg-gray-200">
                            <td class="px-6 py-4 text-sm text-black-500 text-center">{{ group.group_id }}</td>
                            <td class="px-6 py-4 text-sm text-black-500 text-center">{{ group.group_name }}</td>
                            <td class="px-6 py-4 text-sm text-black-500 text-center">{{ group.group_address }}</td>
                            <td class="px-6 py-4 text-sm text-black-500 text-center">{{ group.group_delivery }}</td>
                            <td class="px-6 py-4 text-sm text-black-500 text-center"><img :src="getImageUrl(group.image_url)" alt="Group Image" class="h-12 w-12 object-cover"></td>
                          </tr>
                          <tr v-if="userGroups.length === 0">
                            <td class="px-6 py-4 text-sm text-gray-500 text-center" colspan="4">ไม่พบข้อมูล</td>
                          </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="closeModal">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>



        <button v-if="poDetail.status === 'รอการอนุมัติ' && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' || user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่' && poDetail.status !=='รับอะไหล่แล้ว' && poDetail.status !=='รอการสั่งซื้อ' && poDetail.status !=='ยกเลิกใบสั่งซื้อ' && poDetail.status !=='ปิดใบ PR' && poDetail.status !=='รอรับอะไหล่' && poDetail.status !== 'ไม่อนุมัติ' && user.agency === 1" @click="confirmCancelOrder" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ยกเลิกใบสั่งซื้อ</button>
       <br><br> <button v-if="poDetail.status === 'รอการสั่งซื้อ' && user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ' && user.agency === 1"  @click="placeOrder" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">สั่งซื้อสินค้า</button>
       <div v-if="poDetail.approve_note" class="bg-green-100 text-green-700 p-4 rounded mb-4">
          <strong>หมายเหตุอนุมัติ:</strong> {{ poDetail.approve_note }}
        </div>
      </div><br><br>

      <div class="bg-white shadow mb-4 p-4">
        <div class="mb-4 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div><strong class="font-bold">วันที่ใบสั่งซื้อ:</strong> {{ formatDate(poDetail.po_date) }}</div>
          <div><strong class="font-bold">ชื่อผู้สร้างใบขอซื้อ:</strong> {{ poDetail.creator_name }}</div>
          <div><strong class="font-bold">เลขที่ใบขอสั่งซื้อ:</strong> {{ poDetail.pr_number }}</div>
          <div><strong class="font-bold">เลขที่ใบสั่งซื้อ:</strong> {{ poDetail.po_number }}</div>
          <div><strong class="font-bold">ทะเบียนรถ:</strong> {{ poDetail.licensPlate }}</div>
          <div><strong class="font-bold">เลขคัทซี:</strong> {{ poDetail.vehicleChassisNumber }}</div>
          <div><strong class="font-bold">หน่วยงานรถ:</strong> {{ poDetail.vehicleDepartment }}</div>
          <div><strong class="font-bold">ชื่อผู้จำหน่าย:</strong> {{ poDetail.supplier_name }}</div>
          <div><strong class="font-bold">เลขประจำตัวผู้เสียภาษี:</strong> {{ poDetail.tax_id }}</div>
          <div><strong class="font-bold">ช่องทางติดต่อ:</strong> {{ poDetail.phone }}</div>
          <div><strong class="font-bold">รายละเอียด:</strong> {{ poDetail.details }}</div>
          <div><strong class="font-bold">สถานที่รับ:</strong> {{ poDetail.name }}</div>
          <!-- <div><strong class="font-bold">วันที่จัดส่ง:</strong> {{ formatDate(poDetail.delivery_due_date) }}</div> -->
          <div><strong class="font-bold">ที่อยู่จัดส่ง:</strong> {{ poDetail.addressJa }}</div>
          <div><strong class="font-bold">ชื่อผู้ดำเนินการ:</strong> {{ poDetail.operator_name }}</div>
          <div><strong class="font-bold">ตำแหน่ง:</strong> {{ poDetail.operator_position }}</div>
          <div><strong class="font-bold">จำนวนเครดิต:</strong> {{ poDetail.credit_terms }} <strong> วัน</strong></div>
        </div>
      </div>

    

      <h3 class="text-xl font-bold text-gray-700">รายการสินค้าในใบสั่งซื้อ</h3>
      <div>
    <button v-if ="user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ'" @click="showMappingFormModal = true" class="bg-blue-500 text-white py-2 px-4 rounded">
      แมปรหัสสินค้า
    </button>

    <!-- Modal for MappingForm -->
    <div v-if="showMappingFormModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
        <!-- Mapping Form Component -->
        <MappingForm @close="closeModalMap" />
        <button @click="closeModalMap" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">ปิด</button>
      </div>
    </div>
  </div>
      <p class= "text-left font-semibold bg-gray-100">จำนวนรายการทั้งหมด: {{ itemCount }}</p> <!-- แสดงจำนวนรายการ -->
      <table class="min-w-full table-auto mt-4">
        <thead class="bg-gray-100">
          <tr>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อสินค้า</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวน</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(item, index) in filteredPoItems" :key="index">
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_code }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_name }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_type }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.quantity }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
  <input
    type="number"
     v-model.number="item.unit_price"
      @keydown="preventInvalidInput"
    @blur="updatePrice(item)"
    class="w-full border rounded px-2 py-1"
    step="0.01"
     :disabled="!canEditFields"
  />
</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.total_price }}</td>
          </tr>
          <tr v-if="filteredPoItems.length === 0">
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="7">ไม่พบข้อมูล</td>
          </tr>
        </tbody>
      </table>
      
            <!-- Modal สำหรับเหตุผลในการไม่อนุมัติ -->
    <div v-if="showDisapproveModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการไม่อนุมัติ(ผจก)</h3>
                <div class="mt-2">
                  <textarea v-model="disapproveReason" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการไม่อนุมัติ"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeDisapproveModal">ยกเลิก</button>
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-red-600 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="disapproveOrder">ไม่อนุมัติ</button>
          </div>
        </div>
      </div>
    </div>
     <!-- การคำนวณ -->
<div class="w-full lg:w-full bg-white shadow mb-4 p-4">
  <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    <div>
      <label for="totalAmount" class="block text-sm font-medium text-gray-700">รวมเงินทั้งสิ้น</label>
      <input v-model="poDetail.total_amount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="totalAmount" readonly>
    </div>
    <div>
      <label for="discountPercent" class="block text-sm font-medium text-gray-700">ส่วนลด (%)</label>
      <input 
        v-model="poDetail.discount_percent" 
        @input="validateDiscountPercent" 
        type="number" 
        class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" 
        id="discountPercent" 
        :disabled="!canEditFields">
    </div>
    <div>
      <label for="discountAmount" class="block text-sm font-medium text-gray-700">ส่วนลดเป็นเงิน</label>
      <input 
        v-model="poDetail.discount_amount" 
        @input="validateDiscountAmount" 
        type="number" 
        class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" 
        id="discountAmount" 
        :max="poDetail.total_amount" 
        step="0.01"
        :disabled="!canEditFields">
    </div>
    <div>
      <label for="amountAfterDiscount" class="block text-sm font-medium text-gray-700">ราคาหลังหักส่วนลด</label>
      <input v-model="poDetail.amount_after_discount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50" id="amountAfterDiscount" readonly>
    </div>
    <div>
      <label for="vatPercent" class="block text-sm font-medium text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
      <input 
        v-model="poDetail.vat_percent" 
        @input="calculateAmounts" 
        type="number" 
        class="form-input rounded-md shadow-sm mt-1 block w-full" 
        id="vatPercent" 
        :disabled="!canEditFields">
    </div>
    <div>
      <label for="vatAmount" class="block text-sm font-medium text-gray-700">คิดเป็นจำนวนเงิน</label>
      <input v-model="vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatAmount" readonly>
    </div>
    <div>
      <label for="grand_total" class="block text-sm font-medium text-gray-700">เป็นเงินทั้งสิ้น</label>
      <input v-model="poDetail.grand_total" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="grandTotal" readonly>
    </div>
    </div>
    
    <button v-if="showApproveButton && poDetail.status !== 'รอการสั่งซื้อ' && poDetail.status !== 'รอรับอะไหล่'" @click="openApprovalModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">อนุมัติ</button>
    <button v-if="showApproveButton && poDetail.status !== 'รอการสั่งซื้อ'" @click="openDisapproveModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ไม่อนุมัติ</button>
  </div>
        </div>
      </div>

   
      <div v-if="showConfirmModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <div class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-4/5 overflow-y-auto">
    <h2 class="text-xl font-semibold mb-4">รับเข้าสินค้า</h2>
    <div class="space-y-4 p-4 border rounded-lg shadow">
      <div class="flex items-center border-b py-2">
        <strong class="w-1/3 text-right font-semibold pr-2">วันที่รับเข้า:</strong>
        <input type="datetime-local" v-model="receiptDatetime" class="w-2/3 border p-2 rounded">
      </div>
      <h3 class="mt-4 mb-2 text-lg font-semibold">รายการสินค้า</h3>
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="border-b p-2 text-center">เลือก</th>
            <th class="border-b p-2 text-center">ชื่อสินค้า</th>
            <th class="border-b p-2 text-center">จำนวน</th>
            <th class="border-b p-2 text-center">จำนวนที่รับ</th>
            <th class="border-b p-2 text-center">หมายเหตุ</th>
            <th class="border-b p-2 text-center">แนบรูป</th>
            <th class="border-b p-2 text-center">จำนวนคงเหลือ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in poDetail.items" :key="index">
            <td class="border-b p-2 text-center">
  <input 
    type="checkbox" 
    v-model="selectedItems" 
    :value="index" 
    v-if="item.quantity - item.received_quantity > 0">
</td>
            <td class="border-b p-2">{{ item.product_name }}</td>
            <td class="border-b p-2">{{ item.quantity }}</td>
            <td class="border-b p-2">
              <input 
                v-model.number="receivedQuantities[index]" 
                type="number" 
                class="border p-2 rounded bg-green-100" 
                :min="0" 
                :max="item.quantity - item.received_quantity"
                @keydown="preventDecimal($event)"
                @input="validateReceivedQuantity(index, item.quantity - item.received_quantity)">
            </td>
            <td class="border-b p-2">
              <input v-model="notes[index]" type="text" class="border p-2 rounded" placeholder="กรุณาใส่หมายเหตุ" :class="{'border-red-500': !notes[index] || notes[index].trim() === ''}">
            </td>
            <td class="border-b p-2">
              <span class="text-red-500"> *<input type="file" @change="onFileChange(index, $event)" /></span>
            </td>
            <td class="border-b p-2 bg-red-100">{{ item.quantity - item.received_quantity }}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="flex items-center border-b py-2">
        <strong class="w-1/3 text-right font-semibold pr-2">ชื่อผู้รับเข้า:</strong>
        <span class="w-2/3">{{ user.fullName }}</span>
      </div>
      <div class="mt-4 text-right">
        <button @click="showConfirmModal = false" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
        <button @click="confirmReceipt"
          :disabled="loading"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center">
          <span v-if="loading" class="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full mr-2"></span>
          รับอะไหล่
        </button>
        
        </div>
        </div>
        <div class="flex items-center border-b py-2">
          <strong class="w-1/3 text-right font-semibold pr-2">หมายเหตุการปิดใบรับ:</strong>
          <textarea v-model="closeNote" class="w-2/3 border p-2 rounded" placeholder="กรุณาใส่หมายเหตุการปิดใบรับ"></textarea>
          <button v-if="hasItemsToReceive" @click="closeReceipt" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            ปิดใบรับเข้าสินค้า
          </button>
        </div>
        
         <!-- ประวัติการรับเข้า -->
  <div class="space-y-4 p-4 border rounded-lg shadow mt-4">
      <h3 class="text-lg font-semibold">ประวัติการรับเข้า</h3>
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="border-b p-2 text-center">วันที่รับเข้า</th>
            <th class="border-b p-2 text-center">ชื่อผู้รับเข้า</th>
            <th class="border-b p-2 text-center">หมายเหตุ</th>
            <th class="border-b p-2 text-center">รูปภาพ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) in receiptHistory" :key="index">
            <td class="border-b p-2 text-center">{{ formatDateTime(record.receipt_datetime) }}</td>
            <td class="border-b p-2 text-center">{{ record.received_by_name }}</td>
            <td class="border-b p-2 text-center">{{ record.notes }}</td>
            <td class="border-b p-2 text-center">
          <a :href="changeDomain(record.image_url)" target="_blank">
            <img :src="changeDomain(record.image_url)" class="w-20 h-20 object-cover">
          </a>
        </td>
          </tr>
        </tbody>
      </table>
      <button @click="showConfirmModal = false" class="bg-red-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">ปิด</button>
      
    </div>
    
      </div>

 
  </div>
 





<!--     
    <div class="bg-white shadow mb-4 p-4">
  <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทราคา:</label>
    <select v-model="form.priceType" class="form-select mt-1 block w-full">
      <option value="0">ไม่มี VAT</option>
      <option value="1">รวม VAT</option>
      <option value="2">แยก VAT</option>
    </select>
  </div>
  <div>
  <label for="totalAmount" class="block text-sm font-medium text-gray-700">รวมเงินทั้งสิ้น</label>
  <input v-model="poDetail.total_amount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="totalAmount" readonly>
</div>
<div>
  <label for="discountPercent" class="block text-sm font-medium text-gray-700">ส่วนลด (%)</label>
  <input 
    v-model="poDetail.discount_percent" 
    @input="validateDiscountPercent" 
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" 
    id="discountPercent" 
    :disabled="poDetail.status === 'รอรับอะไหล่'">
</div>
<div>
  <label for="discountAmount" class="block text-sm font-medium text-gray-700">ส่วนลดเป็นเงิน</label>
  <input v-model="poDetail.discount_amount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" id="discountAmount" readonly >

</div>
<div>
  <label for="amountAfterDiscount" class="block text-sm font-medium text-gray-700">ราคาหลังหักส่วนลด</label>
  <input v-model="poDetail.amount_after_discount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50" id="amountAfterDiscount" readonly>
</div>
<div>
  <label for="vatPercent" class="block text-sm font-medium text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
  <input 
    v-model="poDetail.vat_percent" 
    @input="calculateAmounts" 
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full" 
    id="vatPercent" 
    :disabled="poDetail.status === 'รอรับอะไหล่'">
</div>
<div>
  <label for="vatAmount" class="block text-sm font-medium text-gray-700">คิดเป็นจำนวนเงิน</label>
  <input v-model="poDetail.vat_amount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatAmount" readonly>
</div>
<div>
  <label for="grandTotal" class="block text-sm font-medium text-gray-700">เป็นเงินทั้งสิ้น</label>
  <input v-model="poDetail.grand_total" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="grandTotal" readonly>
</div>
</div>
</div> -->
<div class="flex flex-col lg:flex-col">


  <!-- ตารางการเทียบราคา -->
  <div v-if="poDetail.status === 'รอการอนุมัติ'" class="w-full lg:w-full compare-price border shadow p-4 rounded mt-4">
    <h4 class="text-lg font-semibold">เทียบราคาสินค้า</h4>
    <br>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาชื่อสินค้า:</label>
      <input v-model="searchProductName" @input="debouncedFetchComparePrices" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหารหัสสินค้า:</label>
      <input v-model="searchProductCode" @input="debouncedFetchComparePrices" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาประเภทอะไหล่:</label>
      <select v-model="searchPartType" @change="fetchComparePrices" class="form-select mt-1 block w-full">
        <option value="">-- กรุณาเลือกประเภทอะไหล่ --</option>
        <option v-for="partType in partTypes" :key="partType.type_name" :value="partType.type_name">
          {{ partType.type_name }}
        </option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหาประเภทสินค้า:</label>
      <select v-model="searchProductType" @change="fetchComparePrices" class="form-select mt-1 block w-full">
        <option value="">-- กรุณาเลือกประเภทสินค้า --</option>
        <option v-for="productType in productTypes" :key="productType.type_name" :value="productType.type_name">
          {{ productType.type_name }}
        </option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">ค้นหายี่ห้อ:</label>
      <select v-model="searchBrand" @change="fetchComparePrices" class="form-select mt-1 block w-full">
        <option value="">-- กรุณาเลือกยี่ห้อ --</option>
        <option v-for="brand in brands" :key="brand.brand_name" :value="brand.brand_name">
          {{ brand.brand_name }}
        </option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2">คู่ค้า:</label>
      <select v-model="searchSupplierId" @change="fetchComparePrices" class="form-select mt-1 block w-full">
        <option value="">-- กรุณาเลือกคู่ค้า --</option>
        <option v-for="supplier in suppliers" :key="supplier.supplier_id" :value="supplier.supplier_id">
          {{ supplier.company_name }}
        </option>
      </select>
    </div>
    <table class="min-w-full table-auto mt-4">
      <thead class="bg-gray-100">
        <tr>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">วันที่</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทอะไหล่</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ยี่ห้อ</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(item, index) in paginatedComparePrices" :key="index">
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ formatDate(item.po_date) }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_name }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_code }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.part_type }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_type }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.brand }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit_price }}</td>
        </tr>
        <tr v-if="paginatedComparePrices.length === 0">
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="7">ไม่พบข้อมูล</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-4 flex justify-between items-center">
      <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">Next</button>
    </div>
    </div>
    
  </div>


<!-- Modal -->
<!-- <div v-if="showConfirmModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <div class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-4/5 overflow-y-auto">
    <h2 class="text-xl font-semibold mb-4">รับเข้าสินค้า</h2>
    <div class="space-y-4 p-4 border rounded-lg shadow">
      <div class="flex items-center border-b py-2">
        <strong class="w-1/3 text-right font-semibold pr-2">วันที่รับเข้า:</strong>
        <input type="datetime-local" v-model="receiptDatetime" class="w-2/3 border p-2 rounded">
      </div>
      <h3 class="mt-4 mb-2 text-lg font-semibold">รายการสินค้า</h3>
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="border-b p-2 text-center">ชื่อสินค้า</th>
            <th class="border-b p-2 text-center">จำนวน</th>
            <th class="border-b p-2 text-center">หมายเหตุ</th>
            <th class="border-b p-2 text-center">แนบรูป</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in poDetail.items" :key="index">
            <td class="border-b p-2">{{ item.product_name }}</td>
            <td class="border-b p-2">{{ item.quantity }}</td>
            <td class="border-b p-2">
              <input v-model="notes[index]" type="text" class="border p-2 rounded" placeholder="หมายเหตุ">
            </td>
            <td class="border-b p-2">
              <input type="file" :ref="'fileInput' + index" @change="onFileChange(index, $event)" />
            </td>
          </tr>
          <tr v-if="poDetail.items.length === 0">
            <td colspan="4" class="text-center">ไม่มีรายการสินค้า</td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center border-b py-2">
        <strong class="w-1/3 text-right font-semibold pr-2">ชื่อผู้รับเข้า:</strong>
        <span class="w-2/3">{{ user.fullName }}</span>
      </div>
      <div class="mt-4 text-right">
        <button @click="showConfirmModal = false" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
        <button @click="confirmReceipt" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">รับอะไหล่</button>
      </div>
    </div>
  </div>
</div> -->


    <!-- แสดงรายละเอียดใบสั่งซื้อ -->
    <!-- <div v-if="poDetail">
      <p><strong>เลขที่ใบสั่งซื้อ:</strong> {{ poDetail.po_number }}</p>
      <p><strong>วันที่:</strong> {{ formatDate(poDetail.po_date) }}</p>
      <p><strong>สถานะ:</strong> {{ poDetail.status }}</p>
      <p><strong>ผู้สร้าง:</strong> {{ poDetail.creator_name }}</p>
      <p><strong>บริษัทผู้จำหน่าย:</strong> {{ poDetail.supplier_name }}</p>

    </div> -->

    <!-- แสดงรายการสินค้าในใบสั่งซื้อ -->
    <!-- <table v-if="poItems.length">
      <thead>
        <tr>
          <th>รหัสสินค้า</th>
          <th>ชื่อสินค้า</th>
          <th>ประเภท</th>
          <th>จำนวน</th>
          <th>หน่วย</th>
          <th>ราคาต่อหน่วย</th>
          <th>ราคารวม</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in poItems" :key="item.product_code">
          <td>{{ item.product_code }}</td>
          <td>{{ item.product_name }}</td>
          <td>{{ item.product_type }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.unit_price }}</td>
          <td>{{ item.total_price }}</td>
        </tr>
      </tbody>
    </table> -->

  <!-- <div id="order-content" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-white p-6 shadow-lg" v-show="false" v-if="poDetails">
    <div class="text-center mb-4">
      <h2 class="text-2xl font-semibold leading-tight">ใบสั่งซื้อ</h2>
      <div class="mt-1">บริษัท บีอาร์เค อินเตอร์ทรานสปอร์ต จำกัด</div>
      <div class="mt-1">279 หมู่ 6 ตำบลโพนสูง อำเภอบ้านดุง จังหวัดอุดรธานี 65000</div>
    </div>

    <div class="flex justify-between mb-4">
      <div><strong>เลขที่เอกสาร:</strong> {{ poDetails.po_number }}</div>
      <div><strong>วันที่:</strong> {{ poDetails.po_date }}</div>
    </div>

    <div class="mb-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <div><strong>ผู้ขาย :</strong> {{ poDetails.supplier_name }}</div>
        <div><strong>ที่อยู่ :</strong> {{ poDetails.address }}</div>
        <div><strong>โทร :</strong> {{ poDetails.phone }}</div>
      </div>
      <div>
        <div><strong>ผู้จัดทำ :</strong> {{ poDetails.creator_name }}</div>
        <div><strong>วันที่ส่ง :</strong> {{ formatDate(poDetails.delivery_due_date) }}</div>
      </div>
    </div>

    <table class="min-w-full table-auto mb-4">
      <thead class="bg-gray-100">
        <tr>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวน</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(item, index) in filteredItems" :key="index">
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_code }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_name }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.product_type }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.quantity }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.unit_price.toFixed(2) }}</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ item.total_price.toFixed(2) }}</td>
        </tr>
        <tr v-if="filteredItems.length === 0">
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500 text-center" colspan="7">ไม่พบข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="mb-4">
        <label for="totalAmount" class="block text-sm font-medium text-gray-700">รวมเงินทั้งสิ้น</label>
        <input v-model="form.totalAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="totalAmount" readonly>
      </div>
      <div class="mb-4">
        <label for="discountPercent" class="block text-sm font-medium text-gray-700">ส่วนลด (%)</label>
        <input v-model="form.discountPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" id="discountPercent">
      </div>
      <div class="mb-4">
        <label for="amountAfterDiscount" class="block text-sm font-medium text-gray-700">ราคาหลังหักส่วนลด</label>
        <input v-model="form.amountAfterDiscount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50" id="amountAfterDiscount" readonly>
      </div>
      <div class="mb-4">
        <label for="vatPercent" class="block text-sm font-medium text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
        <input v-model="form.vatPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatPercent">
      </div>
      <div class="mb-4">
        <label for="vatAmount" class="block text-sm font-medium text-gray-700">คิดเป็นจำนวนเงิน</label>
        <input v-model="form.vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatAmount" readonly>
      </div>
      <div class="mb-4">
        <label for="grandTotal" class="block text-sm font-medium text-gray-700">เป็นเงินทั้งสิ้น</label>
        <input v-model="form.grandTotal" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="grandTotal" readonly>
      </div>
    </div>
  </div> -->

  <!-- Modal สำหรับเหตุผลในการอนุมัติ -->
<div v-if="showApprovalModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">เหตุผลในการอนุมัติ</h3>
            <div class="mt-2">
              <textarea v-model="approveNote" class="w-full p-2 border rounded" rows="4" placeholder="กรุณาใส่เหตุผลในการอนุมัติ"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" @click="closeApprovalModal">ยกเลิก</button>
        <button type="button" class="w-full inline-flex justify-center rounded-md border border-emerald-600 shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-3 sm:w-auto sm:text-sm" @click="approveOrderWithReason">ยืนยัน</button>
      </div>
    </div>
  </div>
</div>
</template>

  
<script>
import MappingForm from '@/components/Purchase/MappingForm.vue';

import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import _ from 'lodash';

export default {
  components: {
    MappingForm,
  },
  props: {
    poId: {
      type: String,
      required: true
    },
    fromApprovePO: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMappingFormModal: false,
      closeNote: '',
      selectedImageUrl: null,
      showApprovalModal: false,
      approveNote: '',
      showDisapproveModal: false,
      showNoteModal: false,
      receiptHistory: [],
      disapproveReason: '',
      poDetail: {
        items: [], 
        total_amount: 0,
        discount_percent: 0,
        discount_amount: 0, // เพิ่มฟิลด์นี้
        amount_after_discount: 0,
        vat_percent: 0,
        vat_amount: 0,
        grand_total: 0,
        status: '', // เพิ่มสถานะ
        creator_agency: null,
        pr_department: null,
        licensPlate: '',
        vehicleChassisNumber: '',
        vehicleDepartment: '',
      },
     
      agency: null, // เพิ่มตัวแปร agency
      poDetails: null,
      poItems: [],
      form: {
      priceType: '0', // 0: ไม่มี VAT, 1: รวม VAT, 2: แยก VAT
      totalAmount: 0.00,
      discountPercent: 0,
      discount_amount: 0, // เพิ่มฟิลด์นี้
      amountAfterDiscount: 0.00,
      vatPercent: 7.00,  // สมมติว่า VAT 7%
      vatAmount: 0.00,
      grandTotal: 0.00,
      creditTerms: 0
      },
      userGroups: [],
      selectedGroup: null,
      showModal: false,
      showApproveButton: false,
      fromWarehouse: false,
      user: { 
      fullName: '', // เก็บข้อมูลชื่อผู้รับเข้า
      operator_position: null, // เพิ่มตัวแปรนี้เพื่อเก็บ agency ของผู้ใช้
      agency: null,
      position_lo: null   // เพิ่มตัวแปรนี้เพื่อเก็บตำแหน่งของผู้ใช้
      },
      showConfirmModal: false, // เพิ่มสถานะสำหรับ Modal
      receiptDatetime: '', // วันที่รับเข้า
      notes: [],
      selectedItems: [], 
      files: [] , // เก็บข้อมูลไฟล์ที่แนบแต่ละรายการ
      receivedQuantities: [],
      remainingQuantities: [],
      searchProductName: '',
      searchProductCode: '',
      searchSupplierId: '',
      searchPartType: '',
      searchProductType: '',
      searchBrand: '',
      comparePrices: [],
      suppliers: [],
      partTypes: [],
      productTypes: [],
      brands: [],
      currentPage: Number(this.$route.query.currentPage) || 1,
      itemsPerPage: 10,
      loading: false,
      socket: null,
    };
  },
  computed: {
    hasItemsToReceive() {
      return this.poDetail.items.some(item => item.quantity - item.received_quantity > 0);
    },
    vatAmount() {
      const totalAmount = parseFloat(this.poDetail.total_amount);
      const vatPercent = parseFloat(this.poDetail.vat_percent);
      if (!isNaN(totalAmount) && !isNaN(vatPercent)) {
        return (totalAmount * vatPercent) / 100;
      } else {
        return 0; // หรือค่าอื่นๆ ที่เหมาะสม ถ้า totalAmount หรือ vatPercent ไม่ใช่ตัวเลข
      }
    },
    shouldShowButton() {
      console.log('Checking conditions:');
      console.log('poDetail.status:', this.poDetail.status);
      console.log('agency:', this.user.agency);
      return (this.poDetail.status === 'รับอะไหล่แล้ว' || this.poDetail.status === 'สำเร็จ' || this.poDetail.status === 'รอการอนุมัติ' || this.poDetail.status === 'รอรับอะไหล่' ) 
      // && this.user.agency === 3;
  },
    canEditFields() {
      return this.poDetail.status === 'รอการอนุมัติ' && this.$route.query.fromApprove;
    },
    formattedUnitPrice: {
      get() {
        return this.poDetail.items.map(item => {
          return { ...item, unit_price: parseFloat(item.unit_price).toFixed(2) };
        });
      },
      set(newValue) {
        this.poDetail.items = newValue.map(item => {
          return { ...item, unit_price: parseFloat(item.unit_price).toFixed(2) };
        });
      }
    },
    showApprovedStamp() {
      return this.poDetail.status === 'รอรับอะไหล่' && !this.showConfirmModal;
    },
    showApprostamp() {
      return this.poDetail.status === 'รอการสั่งซื้อ' && !this.showConfirmModal;
    },
    filteredPoItems() {
    return this.poDetail.items.filter(item => item.quantity > 0);
  },
    itemCount() {
      return this.filteredPoItems.length;
    },
    paginatedComparePrices() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.comparePrices.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.comparePrices.length / this.itemsPerPage);
    },
  },
  async created() {
  const queryPage = this.$route.query.currentPage;
  if (queryPage) {
    this.currentPage = Number(queryPage);
  }

  // เพิ่มการตรวจสอบ socket ก่อนใช้งาน
  if (this.$socket && this.$socket.on) {
    this.$socket.on('message', async (message) => {
      if (message.type === 'order_approved') {
        this.updateOrderDetail(message.data);
      } else if (message.type === 'receipt_closed' && message.data.po_id === this.$route.params.poId) {
        this.poDetail.status = 'ปิดใบรับเข้า';
        await this.fetchPODetail(this.$route.params.poId);
        Swal.fire('อัปเดต', 'ใบรับเข้าได้รับการอัปเดตโดยผู้ใช้อื่น', 'info');
      } else if (message.type === 'receipt_confirmed' && message.data.po_id === this.$route.params.poId) {
        this.poDetail.status = 'รับอะไหล่แล้ว';
        await this.fetchPODetail(this.$route.params.poId);
        Swal.fire('อัปเดต', 'ใบสั่งซื้อได้รับการอัปเดตโดยผู้ใช้อื่น', 'info');
      } else if (message.type === 'order_disapproved' && message.data.po_id === this.$route.params.poId) {
        this.poDetail.status = 'ไม่อนุมัติ';
        await this.fetchPODetail(this.$route.params.poId);
        Swal.fire('อัปเดต', 'ใบสั่งซื้อได้รับการอัปเดตโดยผู้ใช้อื่น', 'info');
      }
    });
  }

  try {
    await this.fetchUser();
    this.fromPRForm = this.$route.query.fromPRForm === 'true';
    this.fetchSuppliers();
    this.fetchPartTypes();
    this.fetchProductTypes();
    this.fetchBrands();
    this.fetchComparePrices();
    await this.fetchPODetail(this.$route.params.poId);
    await this.fetchPOItems();
    await this.fetchUserGroups();
    await this.fetchReceiptHistory(this.$route.params.poId);
    this.calculateAmounts();
    
    if (this.poDetail.status === 'ไม่อนุมัติ') {
      this.showApproveButton = false;
    }
    this.fromWarehouse = this.$route.query.fromWarehouse || false;
  } catch (error) {
    console.error('Error in created hook:', error);
    // หากต้องการแสดง error ให้ผู้ใช้
    // Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถโหลดข้อมูลได้', 'error');
  }
},
 mounted() {
  
 },
  methods: { 
    goBack() {
      const currentPage = this.$route.query.currentPage || 1;
    if (this.agency === 1) {
      this.$router.push({ name: 'PRForm', query: { activeSection: 'po-list',currentPage: currentPage } });
    } else {
      this.$router.push({ name: 'PRForm', query: { activeSection: 'po-list',currentPage: currentPage } });
    }
  },
    async closeReceipt() {
      if (!this.closeNote.trim()) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่หมายเหตุการปิดใบรับ', 'error');
        return;
      }

      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณต้องการปิดใบรับสินค้านี้หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยันการปิดใบรับสินค้า',
        cancelButtonText: 'ยกเลิก'
      });

      if (result.isConfirmed) {
        this.loading = true;
        const token = localStorage.getItem('userToken');
        if (!token) {
          this.$router.push({ name: 'Login' });
          return;
        }

        const payload = {
          closeNote: this.closeNote
        };

        try {
          const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.$route.params.poId}/close-receipt`, payload, {
            headers: { 
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.status === 200) {
            this.poDetail.status = 'ปิดใบรับเข้า';
            Swal.fire('สำเร็จ!', 'ใบรับเข้าสินค้าถูกปิดแล้ว', 'success');
            this.showConfirmModal = false;

            if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', { type: 'receipt_closed', data: response.data });
} else {
  console.warn('Socket connection is not available or emit method is not defined.');
}

            window.location.reload();
          } else {
            Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถปิดใบรับเข้าได้', 'error');
          }
        } catch (error) {
          console.error('Error closing receipt:', error);
          Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถปิดใบรับเข้าได้', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    changeDomain(url) {
      if (!url) return '';
      return url.replace('https://brknew.synology.me/web/imageProducts/Stock/', 'https://brkmeeting-room.online/Stock/');
    },

    updateOrderDetail(data) {
    if (data.po_id === this.poDetail.po_id) {
      this.poDetail.status = data.status;
      this.poDetail.approver_id = data.approver_id || this.poDetail.approver_id;
      this.poDetail.approve_date = data.approve_date || this.poDetail.approve_date;
      this.poDetail.approveNote = data.approveNote || this.poDetail.approveNote;
      // อัพเดทรายละเอียดอื่นๆ ถ้ามี
    }
  },
    openApprovalModal() {
    this.showApprovalModal = true;
  },
  // ฟังก์ชันสำหรับปิด modal การอนุมัติ
  closeApprovalModal() {
    this.showApprovalModal = false;
  },
  // ฟังก์ชันสำหรับส่งการอนุมัติพร้อมเหตุผล
  async approveOrderWithReason() {
    if (!this.approveNote) {
      Swal.fire('เกิดข้อผิดพลาด', 'กรุณาใส่เหตุผลในการอนุมัติ', 'error');
      return;
    }
    await this.approveOrder();
    this.closeApprovalModal();
  },
    preventDecimal(event) {
      if (event.key === '.' || event.key === 'e') {
        event.preventDefault();
      }
    },
    confirmCancelOrder() {
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "คุณต้องการยกเลิกใบสั่งซื้อหรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่, ยกเลิก!',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        this.cancelOrder();
      }
    });
  },
  async cancelOrder() {
    try {
      console.log(`Attempting to cancel order with ID: ${this.poId}`);
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      const items = this.poDetail.items.map(item => ({
        quantity: item.quantity,
        product_code: item.product_code
      }));

      console.log('Items to update:', items);

      const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.poId}/cancel`, 
      {
        loginId: this.user.login_id,
        items: items
      }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      console.log('Server response:', response);
      if (response.status === 200) {
        Swal.fire(
          'ยกเลิกสำเร็จ!',
          'ใบสั่งซื้อถูกยกเลิกแล้ว.',
          'success'
        );
       if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', { type: 'receipt_closed', data: response.data });
} else {
  console.warn('Socket is not initialized or emit method is not available for receipt_closed.');
}

        this.poDetail.status = 'ยกเลิกใบสั่งซื้อ'; // อัปเดตสถานะใน UI
        console.log('Updated poDetail status:', this.poDetail.status);
      } else {
        throw new Error('Failed to cancel order');
      }
    } catch (error) {
      console.error('Error cancelling order:', error);
      Swal.fire(
        'เกิดข้อผิดพลาด!',
        `ไม่สามารถยกเลิกใบสั่งซื้อได้: ${error.response?.data || error.message}`,
        'error'
      );
    }
  },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
 
    async fetchPartTypes() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/part_types', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.partTypes = response.data;
      } catch (error) {
        console.error('Error fetching part types:', error);
      }
    },
    async fetchReceiptHistory(poId) {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          this.$router.push({ name: 'Login' });
          return;
        }
        const response = await axios.get(`https://brk-group.org/api/purchase_orders/${poId}/receipt-history`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.receiptHistory = response.data;
        console.log("Receipt History:", this.receiptHistory); // เพิ่มการตรวจสอบข้อมูลที่ดึงมา
      } catch (error) {
        console.error('Error fetching receipt history:', error);
      }
    },

    async fetchProductTypes() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/product_types', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.productTypes = response.data;
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    },
    async fetchBrands() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/brandss', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.brands = response.data;
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async fetchComparePrices() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/compare_prices', {
          params: {
            productCode: this.searchProductCode,
            productName: this.searchProductName,
            supplierId: this.searchSupplierId,
            partType: this.searchPartType,
            productType: this.searchProductType,
            brand: this.searchBrand,
            page: this.currentPage,
            limit: this.itemsPerPage
          },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.comparePrices = response.data;
      } catch (error) {
        console.error('Error fetching compare prices:', error);
      }
    },
  debouncedFetchComparePrices: _.debounce(function () {
    this.fetchComparePrices();
  }, 300),
  async fetchSuppliers() {
    try {
      const response = await axios.get('https://brk-group.org/api/suppliers');
      this.suppliers = response.data;
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  },
    validateReceivedQuantity(index, maxQuantity) {
      if (this.receivedQuantities[index] < 0) {
        this.receivedQuantities[index] = 0;
      } else if (this.receivedQuantities[index] > maxQuantity) {
        this.receivedQuantities[index] = maxQuantity;
      }
    },
    validateNote(index) {
      if (!this.notes[index] || this.notes[index].trim() === '') {
        // Handle the empty note case
        this.$set(this.notes, index, 'กรุณาใส่หมายเหตุ'); // Set a default message or handle accordingly
        Swal.fire('เกิดข้อผิดพลาด', 'หมายเหตุห้ามเป็นค่าว่าง', 'error');
      }
    },
  //   validateDiscountPercent() {
  //   if (this.poDetail.discount_percent < 0) {
  //     this.poDetail.discount_percent = 0;
  //   }
  //   if (this.poDetail.discount_percent > 100) {
  //     this.poDetail.discount_percent = 100;
  //   }
  //   this.calculateAmounts();
  // },
  updatePrice(item) {
    const unitPrice = parseFloat(item.unit_price);
    const quantity = parseFloat(item.quantity);

    if (unitPrice < 0) {
      item.unit_price = 0;
      console.error('Unit price cannot be negative');
    } else if (!isNaN(unitPrice) && !isNaN(quantity)) {
      item.total_price = (unitPrice * quantity).toFixed(2);
    } else {
      console.error('Invalid unit price or quantity:', item);
    }

    this.calculateAmounts();
  },
  async confirmReceipt() {
    if (!this.receiptDatetime) {
      Swal.fire('เกิดข้อผิดพลาด', 'กรุณาเลือกวันที่รับเข้า', 'error');
      return;
    }
    if (this.selectedItems.length === 0) {
    Swal.fire('เกิดข้อผิดพลาด', 'กรุณาเลือกสินค้าที่ต้องการรับเข้า', 'error');
    return;
  }
     // ตรวจสอบว่าทุกสินค้าที่เลือกมีการแนบไฟล์แล้ว
     for (let i = 0; i < this.selectedItems.length; i++) {
      const selectedItem = this.selectedItems[i];
      if (!this.files[selectedItem]) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาแนบไฟล์รูปภาพสำหรับสินค้าที่เลือกทั้งหมด', 'error');
        return;
      }
      if (!this.notes[selectedItem] || this.notes[selectedItem].trim() === '') {
            Swal.fire('เกิดข้อผิดพลาด', 'กรุณากรอกหมายเหตุสำหรับสินค้าที่เลือกทั้งหมด', 'error');
            return;
        }
    }

    const result = await Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: "คุณต้องการยืนยันการรับสินค้านี้หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยันการรับเข้า',
      cancelButtonText: 'ยกเลิก'
    });

    if (result.isConfirmed) {
      this.loading = true;
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      const formData = new FormData();
      formData.append('receiptDatetime', this.receiptDatetime);
      formData.append('notes', JSON.stringify(this.notes));
      formData.append('receivedQuantities', JSON.stringify(this.receivedQuantities));
      formData.append('userId', this.user.login_id);
      formData.append('selectedItems', JSON.stringify(this.selectedItems));

      // เพิ่มไฟล์ที่แนบแต่ละรายการ
      this.selectedItems.forEach((itemIndex) => {
        const file = this.files[itemIndex];
        if (file) {
          formData.append('files', file, `file_${itemIndex}`);
        }
      });

      try {
        const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.$route.params.poId}/confirm-receipt`, formData, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          const { status, items } = response.data;
          this.poDetail.status = status;
          this.poDetail.items = items;
          this.showConfirmModal = false;
        
          Swal.fire('สำเร็จ!', 'ใบสั่งซื้อถูกยืนยันการรับเข้าแล้ว', 'success');
          if (this.$socket && typeof this.$socket.emit === 'function') {
          this.$socket.emit('message', { type: 'receipt_confirmed', data: response.data });
        } else {
          console.warn('Socket connection is not available.');
        }

          // บังคับ refresh หน้านี้
        setTimeout(() => {
          window.location.reload();
        }, 800); // ตั้งเวลา delay 2 วินาทีก่อน refresh
        this.fetchPODetail();
        this.fetchPOItems();
        } else {
          Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถยืนยันการรับเข้าได้', 'error');
        }
      } catch (error) {
        console.error('Error confirming receipt:', error);
        Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถยืนยันการรับเข้าได้', 'error');
      } finally {
        this.loading = false;
      }
    }
    },
    openNoteModal() {
      this.showNoteModal = true;
    },
    closeNoteModal() {
      this.showNoteModal = false;
    },
    openDisapproveModal() {
      this.showDisapproveModal = true;
    },
    closeDisapproveModal() {
      this.showDisapproveModal = false;
      this.disapproveReason = '';
    },
    async disapproveOrder() {
    const token = localStorage.getItem('userToken');

    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.poId}/disapprove`, 
      { 
        loginId: this.user.login_id,
        reason: this.disapproveReason,
        items: this.poDetail.items.map(item => ({
          quantity: item.quantity,
          product_code: item.product_code
        })) // ส่งรายการสินค้าด้วย
      }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        this.poDetail.status = 'ไม่อนุมัติ';
        this.poDetail.cancelorder_id = this.user.login_id;
        this.showDisapproveModal = false;
        this.showApproveButton = false;
        Swal.fire(
          'สำเร็จ!',
          'สถานะใบสั่งซื้อถูกอัปเดตเป็นไม่อนุมัติแล้ว',
          'success'
        ).then(() => {
         
          if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', { type: 'order_disapproved', data: response.data });
} else {
  console.warn('Socket is not initialized or emit method is not available for order_disapproved.');
}

          window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
        });
      } else {
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
          'error'
        );
      }
    } catch (error) {
      console.error('Error disapproving purchase order:', error);
      Swal.fire(
        'เกิดข้อผิดพลาด',
        'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
        'error'
      );
      }
    },
    async fetchUser() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }
  try {
    const response = await axios.get('https://brk-group.org/api/user', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    const userData = response.data;
    this.user = {
      login_id: userData.login_id,
      fullName: `${userData.prefixname}${userData.fname} ${userData.lname}`,
      agency: userData.agency,
      position_lo: userData.position_lo
    };
    console.log('Fetched user agency:', this.user.agency); // ตรวจสอบค่า agency ที่ได้มา
    console.log('Fetched user position_lo:', this.user.position_lo); // ตรวจสอบตำแหน่งของผู้ใช้
  } catch (error) {
    console.error('Error fetching user:', error);
    if (error.response && error.response.status === 401) {
      this.$router.push({ name: 'Login' });
    } else {
      alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
    }

      }
    },
    async placeOrder() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }

  const result = await Swal.fire({
    title: 'คุณแน่ใจหรือไม่?',
    text: "คุณต้องการยืนยันการสั่งซื้อนี้หรือไม่",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ยืนยันการสั่งซื้อ',
    cancelButtonText: 'ยกเลิก'
  });

  if (result.isConfirmed) {
    try {
      const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.poId}/confirm`, 
      { 
        loginId: this.user.login_id // ใช้ login_id จาก user object
      }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    if (response.status === 200) {
      this.poDetail.status = 'รอรับอะไหล่';
      this.poDetail.confirmorder_id = this.user.login_id;

      // เรียก API เพื่อเช็คและอัปเดตสถานะใบขอซื้อ
      await this.checkAndUpdatePRStatus();

      Swal.fire(
        'สำเร็จ!',
        'สถานะใบสั่งซื้อถูกอัปเดตเป็นรอรับอะไหล่แล้ว',
        'success'
      );
      // Emit a WebSocket message
      // this.$socket.emit('message', { type: 'order_placed', data: response.data });
      if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', { type: 'order_placed', data: response.data });
} else {
  console.warn('Socket is not initialized or emit method is not available for order_disapproved.');
}

        // Optionally reload or refresh data
        window.location.reload();
    } else {
      Swal.fire(
        'เกิดข้อผิดพลาด',
        'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
        'error'
      );
    }
  } catch (error) {
    console.error('Error placing order:', error);
    Swal.fire(
      'เกิดข้อผิดพลาด',
      'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
      'error'
    );
  }
}
},

async checkAndUpdatePRStatus() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    this.$router.push({ name: 'Login' });
    return;
  }

  try {
    const response = await axios.get(`https://brk-group.org/api/purchase_requisitions/${this.poDetail.pr_id}/remaining_orders`, 
    {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    const remainingOrders = response.data;
    const allZero = remainingOrders.every(order => order.remainingOrder === '0');

    if (allZero) {
      await axios.put(`https://brk-group.org/api/purchase_requisitions/${this.poDetail.pr_id}/status`, 
      {
        status_pr: 'สำเร็จ'
      }, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });
    }
  } catch (error) {
    console.error('Error updating PR status:', error);
  }
},
async approveOrder() {
    const token = localStorage.getItem('userToken');

    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    // ตรวจสอบข้อมูลใน poDetail.items ก่อนส่งไป backend
    for (let item of this.poDetail.items) {
      const unitPrice = parseFloat(item.unit_price);
      const quantity = parseFloat(item.quantity);
      const productId = parseInt(item.product_id);
      if (isNaN(unitPrice) || isNaN(quantity) || isNaN(productId)) {
        console.error('Invalid item data:', item);
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ข้อมูลสินค้าบางรายการไม่ถูกต้อง',
          'error'
        );
        return;
      }
      item.unit_price = unitPrice; // ส่งค่า unit_price เป็นตัวเลข
      item.total_price = (unitPrice * quantity).toFixed(2);
      item.quantity = quantity; // ทำให้แน่ใจว่า quantity เป็นตัวเลข
      item.product_id = productId; // ทำให้แน่ใจว่า product_id เป็นตัวเลข
    }

    const requestData = {
      loginId: this.user.login_id, // ใช้ login_id จาก user object
      items: this.poDetail.items.map(item => ({
        ...item,
        unit_price: parseFloat(item.unit_price),
        total_price: parseFloat(item.total_price)
      })), // ส่งรายการสินค้าไปด้วย
      totalAmount: parseFloat(this.poDetail.total_amount),
      discountPercent: parseFloat(this.poDetail.discount_percent),
      amountAfterDiscount: parseFloat(this.poDetail.amount_after_discount),
      vatPercent: parseFloat(this.poDetail.vat_percent),
      vatAmount: parseFloat(this.poDetail.vat_amount),
      grandTotal: parseFloat(this.poDetail.grand_total), // เปิดใช้งาน grandTotal อีกครั้ง
      approveNote: this.approveNote // เพิ่ม approveNote ใน requestData
    };

    // ตรวจสอบว่ามีค่า NaN ใน requestData หรือไม่
    for (let key in requestData) {
      if (typeof requestData[key] === 'number' && isNaN(requestData[key])) {
        console.error(`Invalid numerical value for ${key}:`, requestData[key]);
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ข้อมูลในฟอร์มไม่ถูกต้อง กรุณาตรวจสอบข้อมูล',
          'error'
        );
        return;
      }
    }

    try {
      const response = await axios.put(`https://brk-group.org/api/purchase_orders/${this.poId}/approve`, 
      requestData, 
      {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        this.poDetail.status = 'รอการสั่งซื้อ';
        this.poDetail.approver_id = this.user.login_id;
        this.poDetail.approve_date = new Date().toLocaleString();
        this.showApproveButton = false;
       
        Swal.fire(
          'สำเร็จ!',
          'สถานะใบสั่งซื้อถูกอัปเดตเป็นรอการสั่งซื้อแล้ว',
          'success'
        ).then(() => {
          if (this.$socket && typeof this.$socket.emit === 'function') {
  this.$socket.emit('message', {
    type: 'order_approved',
    data: response.data
  });
} else {
  console.warn('Socket is not initialized or emit method is not available for order_approved.');
}

            window.location.reload(); // รีเฟรชหน้าเมื่อกด "OK" บนการแจ้งเตือน
          });
      } else {
        Swal.fire(
          'เกิดข้อผิดพลาด',
          'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
          'error'
        );
      }
    } catch (error) {
      console.error('Error approving purchase order:', error);
      Swal.fire(
        'เกิดข้อผิดพลาด',
        'ไม่สามารถอัปเดตสถานะใบสั่งซื้อได้',
        'error'
      );
    } finally {
      this.loading = false;
    }
}
,
    formatDateTime(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getImageUrl(imagePath) {
      if (!imagePath) {
        return ''; // or a placeholder image URL
      }
      const filename = imagePath.split('/').pop();
      return `https://brkmeeting-room.online/Agency/${filename}`;
    },
    openModalMap() {
      this.isModalVisible = true;
    },
    closeModalMap() {
      this.showMappingFormModal = false;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    selectGroup(group) {
      this.selectedImageUrl = this.getImageUrl(group.image_url);
      this.selectedGroup = group;  // Assign selected group
      this.showModal = false;
    },
    checkElementAndExport() {
      const element = document.getElementById('order-content');
      if (element) {
        this.exportOrder(element);
      } else {
        console.error('Element not found');
      }
    },
    exportOrder(element) {
      const doc = new jsPDF('p', 'mm', 'a4');

      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Draw the content image
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl); // Open PDF in a new tab
      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    },
    openConfirmModal() {
      this.receivedQuantities = this.poDetail.items.map(item => item.quantity - item.received_quantity);
      this.files = [];
      this.receiptDatetime = '';
      this.showConfirmModal = true;
          // ตั้งค่า receivedQuantities เป็น array ของ 0 ที่มีขนาดเท่ากับจำนวน items ใน poDetail

      // this.fetchPODetail(); // Fetch data when opening modal
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    async fetchPODetail(poId) {
  try {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }
    const response = await axios.get(`https://brk-group.org/api/purchase_orders/${poId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    this.poDetail = response.data;
    this.calculateAmounts();
 
    this.notes = this.poDetail.items.map(() => ''); // Initialize notes
    // this.receivedQuantities = this.poDetail.items.map(item => item.quantity); // ตั้งค่าจำนวนที่รับเป็นค่าเริ่มต้นของจำนวนที่สั่งซื้อ
    this.receivedQuantities = this.poDetail.items.map(item => item.quantity - item.received_quantity); // ตั้งค่าจำนวนที่รับเป็นจำนวนคงเหลือ
    this.showApproveButton = this.$route.query.fromApprove === 'true';
    this.form.discountPercent = this.poDetail.discount_percent || 0;
    this.form.vatPercent = this.poDetail.vat_percent || 0;
    console.log("PO Detail:", this.poDetail); // เพิ่มการตรวจสอบข้อมูลที่ดึงมา
  } catch (error) {
    console.error('Error fetching purchase order details:', error);
  }
    },
  
    async fetchPOItems() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get(`https://brk-group.org/api/purchase_order_items/${this.poId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.poItems = response.data;
        this.showApproveButton = this.$route.query.fromApprove === 'true';
        this.calculateTotalAmount();
      } catch (error) {
        console.error('Error fetching purchase order items:', error);
      }
    },
    async fetchUserGroups() {
      try {
        const response = await axios.get('https://brk-group.org/api/user-groups');
        this.userGroups = response.data;
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    },
    validateDiscountAmount() {
    let discountAmount = parseFloat(this.poDetail.discount_amount);
    if (isNaN(discountAmount) || discountAmount < 0) {
      discountAmount = 0;
    } else if (discountAmount > parseFloat(this.poDetail.total_amount)) {
      discountAmount = parseFloat(this.poDetail.total_amount);
    }
    this.poDetail.discount_amount = discountAmount.toFixed(2);
    this.poDetail.discount_percent = ((discountAmount / parseFloat(this.poDetail.total_amount)) * 100).toFixed(2);
    this.calculateAmounts();
  },
  validateDiscountPercent() {
    let discountPercent = parseFloat(this.poDetail.discount_percent);
    if (isNaN(discountPercent) || discountPercent < 0) {
      discountPercent = 0;
    } else if (discountPercent > 100) {
      discountPercent = 100;
    }
    this.poDetail.discount_percent = discountPercent.toFixed(2);
    this.calculateAmounts();
  },
    calculateTotalAmount() {
      this.form.totalAmount = this.filteredPoItems.reduce((total, item) => total + parseFloat(item.total_price), 0);
    },
    calculateDiscountPercent() {
    if (this.poDetail.total_amount > 0) {
      const discountAmount = parseFloat(this.poDetail.discount_amount);
      if (!isNaN(discountAmount)) {
        this.poDetail.discount_percent = ((discountAmount / this.poDetail.total_amount) * 100).toFixed(2);
      } else {
        this.poDetail.discount_percent = "0.00";
      }
      this.calculateAmounts();
    }
  },
  calculateAmounts() {
    const totalAmount = this.poDetail.items.reduce((sum, item) => sum + parseFloat(item.total_price || 0), 0);
    const discountPercent = parseFloat(this.poDetail.discount_percent) || 0;
    const vatPercent = parseFloat(this.poDetail.vat_percent) || 0;

    const discountAmount = (totalAmount * discountPercent) / 100;
    const amountAfterDiscount = totalAmount - discountAmount;
    let vatAmount = 0;

    if (this.poDetail.priceType === '1') { // รวม VAT
      vatAmount = amountAfterDiscount * (7 / 107); // คำนวณ VAT ที่รวมในราคาแล้ว
    } else if (this.poDetail.priceType === '2') { // แยก VAT
      vatAmount = (amountAfterDiscount * vatPercent) / 100;
    }

    // const grandTotal = amountAfterDiscount + vatAmount;

    this.poDetail.total_amount = totalAmount.toFixed(2);
    this.poDetail.discount_amount = discountAmount.toFixed(2); // อัปเดตส่วนลดเป็นเงิน
    this.poDetail.amount_after_discount = amountAfterDiscount.toFixed(2);
    this.poDetail.vat_amount = vatAmount.toFixed(2);
    // this.poDetail.grand_total = grandTotal.toFixed(2);

    if (this.poDetail.priceType === '1') {
      this.poDetail.vat_percent = 0; // รวม VAT ไม่สามารถแก้ไข VAT
    } else if (this.poDetail.priceType === '2') {
      this.poDetail.vat_percent = 7; // แยก VAT ค่าเริ่มต้นเป็น 7%
    }
  },
  onFileChange(index, event) {
      const file = event.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (file) {
        if (!allowedTypes.includes(file.type)) {
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไฟล์ที่แนบต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น',
            'error'
          );
          // Clear the file input if invalid file type
          event.target.value = '';
          return;
        }
        // Update file if valid
        this.files[index] = file;
      }
    },
    validateCreditTerms() {
    if (this.form.creditTerms < 0) {
      this.form.creditTerms = 0;
    }
  },
  preventInvalidInput(event) {
    const invalidChars = ['-', '+', 'e', 'E', '.'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  },
  },
  watch: {
  'poDetail.discount_amount': 'validateDiscountAmount',
  'form.priceType': 'calculateAmounts',
  'poDetail.discount_percent': 'calculateAmounts',
  'poDetail.vat_percent': 'calculateAmounts',
  'poDetail.grand_total': 'calculateAmounts',
  'poDetail.items': {
    handler(newItems) {
      newItems.forEach(item => {
        if (item.unit_price < 0) {
          item.unit_price = 0;
          console.error('Unit price cannot be negative');
        }

        const unitPrice = parseFloat(item.unit_price);
        const quantity = parseFloat(item.quantity);

        if (!isNaN(unitPrice) && !isNaN(quantity)) {
          item.total_price = (unitPrice * quantity).toFixed(2);
        } else {
          console.error('Invalid unit price or quantity:', item);
        }
      });
      this.calculateAmounts();
    },
    deep: true,
    },
    
  }
};
</script>



<style scoped>
.disapproved-stamp {
  position: relative;
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1.5em;
  font-weight: bold;
  color: red;
  border: 3px solid red;
  border-radius: 0.25em;
  background: white;
  text-transform: uppercase;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  z-index: 1;
  margin-top: 1em;
}
.approved-stamp {
  position: relative;
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 1.5em;
  font-weight: bold;
  color: green;
  border: 3px solid greenyellow;
  border-radius: 0.25em;
  background: white;
  text-transform: uppercase;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  z-index: 1;
  margin-top: 1em;
}

.table-auto {
  width: 100%;
  border-collapse: collapse;
}

.table-auto th, .table-auto td {
  border: 1px solid #dee2e6;
  padding: 8px;
}

.table-auto thead th {
  background-color: #f8f9fa;
}

.table-auto tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.bg-green-50 {
  background-color: #e6fffa;
}

.bg-yellow-50 {
  background-color: #fffbeb;
}

.bg-orange-50 {
  background-color: #fff7ed;
}

#order-content {
  width: 100%;
  max-width: 800px;
  margin: auto;
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
.compare-price {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .compare-price {
    margin-bottom: 0;
  }
}

.spinner-border {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: white;
}
</style>