<template>
  <TransportLayout>
    <div class="container mx-auto p-4">
      <!-- Header Section with Bulk Delete Button -->
      <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h1 class="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">ตั้งค่าเส้นทาง</h1>
        <div class="w-full sm:w-auto flex space-x-2">
          <div class="relative flex-1 sm:flex-none">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="ค้นหาเส้นทาง..."
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <span class="absolute right-3 top-2.5 text-gray-400">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <button
            v-if="hasSelectedRoutes"
            @click="deleteBulkRoutes"
            class="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg flex items-center space-x-2 transition-colors duration-200"
          >
            <i class="fas fa-trash"></i>
            <span>ลบที่เลือก ({{ selectedRoutes.length }})</span>
          </button>
          <button
            @click="openModal"
            class="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center space-x-2 transition-colors duration-200"
          >
            <i class="fas fa-plus"></i>
            <span>เพิ่มเส้นทาง</span>
          </button>
        </div>
      </div>

      <!-- Routes Table -->
      <div class="bg-white rounded-lg shadow overflow-hidden">
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="w-16 px-6 py-3">
                  <input
                    type="checkbox"
                    :checked="selectedRoutes.length === filteredRoutes.length"
                    @change="toggleAllRoutes"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </th>
                <th class="w-16 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เส้นทาง</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ระยะทาง (กม.)</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เวลาทำงาน</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ไมค์เรท</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ค่าขนส่ง</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ค่าเที่ยว</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงาน</th>
                <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">จัดการ</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(route, index) in paginatedRoutes" :key="route.id" class="hover:bg-gray-50">
                <td class="px-6 py-4">
                  <input
                    type="checkbox"
                    :checked="selectedRoutes.includes(route.id)"
                    @change="toggleRouteSelection(route.id)"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ index + 1 }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ route.route_name }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ route.distance_km }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ formatTime(route.operation_time) }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ formatCurrency(route.mile_rate) }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ formatCurrency(route.transport_fee) }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ formatCurrency(route.trip_cost) }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ route.department_name }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  <button
                    @click="editRoute(route)"
                    class="text-blue-600 hover:text-blue-800 mr-3"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    @click="deleteRoute(route.id)"
                    class="text-red-600 hover:text-red-800"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr v-if="routes.length === 0">
                <td colspan="10" class="px-6 py-4 text-center text-gray-500">
                  ไม่พบข้อมูลเส้นทาง
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-between items-center mt-4">
  <button
    @click="currentPage--"
    :disabled="currentPage === 1"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ก่อนหน้า
  </button>
  <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
  <button
    @click="currentPage++"
    :disabled="currentPage === totalPages"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ถัดไป
  </button>
</div>
      <!-- Modal -->
      <div
        v-if="isModalOpen"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      >
        <div class="bg-white rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
          <!-- Modal Header -->
          <div class="sticky top-0 bg-white p-4 border-b z-10">
            <div class="flex justify-between items-center">
              <h2 class="text-xl font-semibold text-gray-800">
                {{ editingRoute ? 'แก้ไขเส้นทาง' : 'เพิ่มเส้นทางใหม่' }}
              </h2>
              <button
                @click="closeModal"
                class="text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <i class="fas fa-times text-xl"></i>
              </button>
            </div>
          </div>

          <!-- Modal Body -->
          <div class="p-4 sm:p-6">
            <form @submit.prevent="handleSubmit" class="space-y-4 sm:space-y-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <!-- ต้นทาง -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ต้นทาง <span class="text-red-500">*</span>
                  </label>
                  <input
                    v-model="formData.origin"
                    type="text"
                    required
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="เช่น BRK"
                  />
                </div>

                <!-- ปลายทาง -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ปลายทาง <span class="text-red-500">*</span>
                  </label>
                  <input
                    v-model="formData.destination"
                    type="text"
                    required
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="เช่น BPB"
                  />
                </div>

                <!-- ระยะทาง -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ระยะทาง (กม.)
                  </label>
                  <input
                    v-model="formData.distance_km"
                    type="number"
                    step="0.01"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="0.00"
                  />
                </div>

                <!-- เวลาทำงาน -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    เวลาทำงาน
                  </label>
                  <input
                    v-model="formData.operation_time"
                    type="time"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <!-- ไมค์เรท -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ไมค์เรท
                  </label>
                  <input
                    v-model="formData.mile_rate"
                    type="number"
                    step="0.01"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="0.00"
                  />
                </div>

                <!-- ค่าขนส่ง -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ค่าขนส่ง
                  </label>
                  <input
                    v-model="formData.transport_fee"
                    type="number"
                    step="0.01"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="0.00"
                  />
                </div>

                <!-- ค่าเที่ยว -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    ค่าเที่ยว
                  </label>
                  <input
                    v-model="formData.trip_cost"
                    type="number"
                    step="0.01"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="0.00"
                  />
                </div>

                <!-- หน่วยงาน -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    หน่วยงาน
                  </label>
                  <select
                    v-model="formData.department_id"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">เลือกหน่วยงาน</option>
                    <option
                      v-for="dept in departments"
                      :key="dept.id"
                      :value="dept.id"
                    >
                      {{ dept.name }}
                    </option>
                  </select>
                </div>

                <!-- กลุ่มงาน -->
                <div>
                  <label class="block text-sm font-medium text-gray-700 mb-2">
                    กลุ่มงาน
                  </label>
                  <select
                    v-model="formData.group_id"
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">เลือกกลุ่มงาน</option>
                    <option
                      v-for="group in groups"
                      :key="group.id"
                      :value="group.id"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Submit Buttons -->
              <div class="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
                <button
                  type="button"
                  @click="closeModal"
                  class="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                >
                  ยกเลิก
                </button>
                <button
                  type="submit"
                  class="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  {{ editingRoute ? 'บันทึกการแก้ไข' : 'บันทึก' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </TransportLayout>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import TransportLayout from '@/components/transport/TransportSidebar.vue';
import { io } from 'socket.io-client'
export default {
  components: {
    TransportLayout,
  },
  name: 'RouteSettings',
  data() {
    return {
      isModalOpen: false,
      searchQuery: '',
      routes: [],
      departments: [],
      groups: [],
      editingRoute: null,
      userName: localStorage.getItem('userName') || 'test',
      selectedRoutes: [], // Array to store selected route IDs
      socket: null,
      formData: {
        origin: '',
        destination: '',
        distance_km: null,
        operation_time: '',
        mile_rate: null,
        transport_fee: null,
        trip_cost: null,
        department_id: '',
        group_id: ''
      },
      currentPage: 1,
      itemsPerPage: 10
    }
  },
  computed: {
    filteredRoutes() {
      if (!this.searchQuery) return this.routes;
      const query = this.searchQuery.toLowerCase();
      return this.routes.filter(route => 
        route.route_name.toLowerCase().includes(query) ||
        route.department_name?.toLowerCase().includes(query)
      );
    },
    hasSelectedRoutes() {
      return this.selectedRoutes.length > 0;
    },
    paginatedRoutes() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredRoutes.slice(start, start + this.itemsPerPage);
  },
  totalPages() {
    return Math.ceil(this.filteredRoutes.length / this.itemsPerPage);
  },
  },
  async created() {
    await this.fetchData();
    this.initializeWebSocket();
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },

  methods: {
    changePage(page) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
  },
      // Initialize WebSocket connection
      initializeWebSocket() {
      this.socket = io(process.env.VUE_APP_WEBSOCKET_URL);
      
      this.socket.on('routeUpdate', () => {
        this.fetchData(); // Refresh data when update received
      });
      
      this.socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });
    },

    // Emit update event through WebSocket
    emitUpdateEvent() {
      if (this.socket) {
        this.socket.emit('routeUpdate');
      }
    },

    // Handle checkbox selection
    toggleRouteSelection(routeId) {
      const index = this.selectedRoutes.indexOf(routeId);
      if (index === -1) {
        this.selectedRoutes.push(routeId);
      } else {
        this.selectedRoutes.splice(index, 1);
      }
    },

    // Select/Deselect all routes
    toggleAllRoutes() {
      if (this.selectedRoutes.length === this.filteredRoutes.length) {
        this.selectedRoutes = [];
      } else {
        this.selectedRoutes = this.filteredRoutes.map(route => route.id);
      }
    },

  // Fetch all required data
  async fetchData() {
    try {
      const [routesResponse, deptsResponse, groupsResponse] = await Promise.all([
      axios.get('https://brk-group.org/api/ts/routes'),
  axios.get('https://brk-group.org/api/ts/departments'),
  axios.get('https://brk-group.org/api/ts/groups')
      ]);
      
      this.routes = routesResponse.data;
      this.departments = deptsResponse.data;
      this.groups = groupsResponse.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง'
      });
    }
  },

  // Format time for display
  formatTime(time) {
    if (!time) return '-';
    return time.substring(0, 5); // Display only HH:MM
  },

  // Format currency for display
  formatCurrency(value) {
    if (!value) return '0.00';
    return new Intl.NumberFormat('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  },

  // Open modal for creating/editing
  openModal() {
    this.editingRoute = null;
    this.formData = {
      origin: '',
      destination: '',
      distance_km: null,
      operation_time: '',
      mile_rate: null,
      transport_fee: null,
      trip_cost: null,
      department_id: '',
      group_id: ''
    };
    this.isModalOpen = true;
  },

   // Open modal and load data for editing
   async editRoute(route) {
  try {
    const response = await axios.get(`https://brk-group.org/api/ts/routes/${route.id}`);
    
    this.editingRoute = response.data;
    this.formData = {
      origin: response.data.origin || '',
      destination: response.data.destination || '',
      distance_km: response.data.distance_km || null,
      operation_time: response.data.operation_time || '',
      mile_rate: response.data.mile_rate || null,
      transport_fee: response.data.transport_fee || null,
      trip_cost: response.data.trip_cost || null,
      department_id: response.data.department_id || '',
      group_id: response.data.group_id || ''
    };

    this.isModalOpen = true;
  } catch (error) {
    console.error('Error fetching route details:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถโหลดข้อมูลเส้นทางได้'
    });
  }
    },
       // Confirm and handle bulk deletion
       async confirmBulkDelete() {
      if (this.selectedRoutes.length === 0) return;

      try {
        const result = await Swal.fire({
          icon: 'warning',
          title: 'ยืนยันการลบข้อมูล',
          text: `คุณต้องการลบเส้นทางที่เลือกจำนวน ${this.selectedRoutes.length} รายการ ใช่หรือไม่?`,
          showCancelButton: true,
          confirmButtonText: 'ลบ',
          cancelButtonText: 'ยกเลิก',
          confirmButtonColor: '#dc3545'
        });

        if (result.isConfirmed) {
          await this.deleteBulkRoutes();
        }
      } catch (error) {
        console.error('Error in confirmation dialog:', error);
      }
    },

// ลบหลายรายการ
async deleteBulkRoutes() {
    if (this.selectedRoutes.length === 0) return;

    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการลบข้อมูล',
        text: `คุณต้องการลบเส้นทางที่เลือกจำนวน ${this.selectedRoutes.length} รายการ ใช่หรือไม่?`,
        showCancelButton: true,
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#dc3545',
      });

      if (result.isConfirmed) {
        await axios.post('https://brk-group.org/api/ts/routes/bulk-delete', {
          routeIds: this.selectedRoutes,
          username: this.userName,
        });

        await this.fetchData(); // Refresh data
        this.selectedRoutes = []; // Clear selection

        Swal.fire({
          icon: 'success',
          title: 'ลบสำเร็จ',
          text: `ลบเส้นทางจำนวน ${this.selectedRoutes.length} รายการเรียบร้อยแล้ว`,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error deleting routes:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
      });
    }
  },


  // Close modal
  closeModal() {
    this.isModalOpen = false;
    this.editingRoute = null;
    this.formData = {
      origin: '',
      destination: '',
      distance_km: null,
      operation_time: '',
      mile_rate: null,
      transport_fee: null,
      trip_cost: null,
      department_id: '',
      group_id: ''
    };
  },

 // Handle form submission
// Handle form submission
async handleSubmit() {
  try {
    if (!this.formData.origin || !this.formData.destination) {
      Swal.fire({
        icon: 'warning',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        text: 'กรุณากรอกต้นทางและปลายทาง'
      });
      return;
    }

    const payload = {
      ...this.formData,
      created_by: this.$user.username // เข้าถึง Global Property
    };

    let response;
    if (this.editingRoute) {
      response = await axios.put(`https://brk-group.org/api/ts/routes/${this.editingRoute.id}`, payload);
    } else {
      response = await axios.post('https://brk-group.org/api/ts/routes', payload);
    }

    if (response.data) {
      await this.fetchData();
      this.closeModal();

      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
        text: this.editingRoute ? 'แก้ไขเส้นทางเรียบร้อยแล้ว' : 'เพิ่มเส้นทางใหม่เรียบร้อยแล้ว',
        timer: 1500
      });
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง'
    });
  }
}
,



  // Confirm and handle route deletion
  async confirmDelete(route) {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการลบ',
        text: `คุณต้องการลบเส้นทาง ${route.route_name} ใช่หรือไม่?`,
        showCancelButton: true,
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#dc3545'
      });

      if (result.isConfirmed) {
        await this.deleteRoute(route.id);
      }
    } catch (error) {
      console.error('Error in confirmation dialog:', error);
    }
  },

  // ลบทีละรายการ
  async deleteRoute(routeId) {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'ยืนยันการลบ',
        text: 'คุณต้องการลบเส้นทางนี้หรือไม่?',
        showCancelButton: true,
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#dc3545',
      });

      if (result.isConfirmed) {
        await axios.delete(`https://brk-group.org/api/ts/routes/${routeId}`, {
          data: { username: this.userName },
        });

        await this.fetchData(); // Refresh data
        Swal.fire({
          icon: 'success',
          title: 'ลบสำเร็จ',
          text: 'ลบเส้นทางเรียบร้อยแล้ว',
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error deleting route:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
      });
    }
  },

}
};
</script>