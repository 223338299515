<template>
  <div class="container mx-auto p-6 shadow-lg rounded-lg bg-gray-50">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">ดูรายละเอียดคู่ค้า</h2>
    <div v-if="supplier" class="border p-6 bg-white rounded-lg shadow-md">
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ชื่อร้าน/บริษัท:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.company_name" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">เลขประจำตัวผู้เสียภาษี:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.tax_id" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ที่อยู่:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.address" rows="3" readonly></textarea>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ประเภทหน้างาน:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.supplier_type" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ข้อมูลการชำระเงิน:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.payment_info" rows="3" readonly></textarea>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ข้อมูลสนับสนุนหลังการขาย:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.support_info" rows="3" readonly></textarea>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">เบอร์โทร:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.phone" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ชื่อผู้ติดต่อ:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.contact_name" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">เมลร้าน:</label>
        <input type="email" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.contact_email" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ตำแหน่ง:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.position" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ข้อมูลสัญญาหรือข้อตกลง:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.contract_info" rows="3" readonly></textarea>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">เว็บไซต์ร้าน:</label>
        <input type="url" class="w-3/4 text-blue-500 p-2 border rounded" v-model="supplier.website" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">ข้อมูลการจัดส่ง:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.shipping_info" rows="3" readonly></textarea>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">เครดิต:</label>
        <input type="text" class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.credit" readonly>
      </div>
      <div class="flex mb-4">
        <label class="w-1/4 text-lg font-semibold">รายละเอียดสินค้าหรือบริการ:</label>
        <textarea class="w-3/4 text-gray-700 p-2 border rounded" v-model="supplier.detail_product" rows="3" readonly></textarea>
      </div>
    </div>
    <router-link to="/suppliers" class="mt-6 inline-block bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 transition ease-in-out duration-200">
      ย้อนกลับ
    </router-link>
  </div>
</template>
    
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        supplier: null
      };
    },
    async created() {
      try {
        const response = await axios.get(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`);
        this.supplier = response.data;
      } catch (error) {
        console.error(error);
        // แสดงข้อผิดพลาดหรือแจ้งเตือน
      }
    }
  };
  </script>
  