<template>
      <TransportLayout>
    <div class="dashboard">
      <h1>Welcome to the Transport Dashboard</h1>
      <!-- <p>User Position: {{ userPosition }}</p> -->
      <router-link to="/transport/transportdrivers">Drivers</router-link>
      <router-link to="/transport/transportvehicles">Vehicles</router-link>
      <router-link to="/transport/transportroutes">Routes</router-link>
      <router-link to="/transport/transportmaintenance">Maintenance</router-link>
    </div>
  </TransportLayout>
  </template>

  <script>
  import TransportLayout  from '@/components/transport/TransportSidebar.vue';
  export default {
    components: {
      TransportLayout,
    },
    data() {
      return {
        userPosition: 'Transport', // ค่าที่จะเปลี่ยนตามตำแหน่งของผู้ใช้
      };
    },
  };
  </script>
  
  <style>
  .dashboard {
    text-align: center;
    margin-top: 50px;
  }
  @media (max-width: 768px) {
  aside {
    width: 100%; /* ให้ sidebar ใช้ความกว้างเต็มเมื่ออยู่ในแนวตั้ง */
  }
}
  </style>