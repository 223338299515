<template>
  <div class="flex">
    <TransportSidebar v-bind:isOpen="isOpen" @toggleSidebar="toggleSidebar" />
    <main class="flex-grow p-4 transition-all duration-300" :class="{ 'ml-0': !isOpen, 'md:ml-64': isOpen }">
      <h1 class="text-2xl font-bold">Transport Vehicles</h1>
      <p class="mt-4">นี่คือข้อมูลเกี่ยวกับรถขนส่ง...</p>
    </main>
  </div>
</template>

<script>
import TransportSidebar from '@/components/transport/TransportSidebar.vue';

export default {
  name: 'TransportVehicles',
  components: {
    TransportSidebar,
  },
  data() {
    return {
      isOpen: true, // สถานะเปิด/ปิดของ sidebar
    };
  },
  methods: {
  
  },
};
</script>

<style scoped>
/* @media (max-width: 768px) {
  aside {
    width: 100%;
  }
} */
</style>