<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
   <div class="max-w-4xl mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
    <h2 class="text-2xl font-bold mb-6 text-gray-800">เพิ่มข้อมูลรถ</h2>
    <form @submit.prevent="addVehicle" class="space-y-4">
      <!-- แถวสำหรับเบอร์รถและทะเบียนรถ -->
      <div class="grid grid-cols-2 gap-4">
        <div class="form-group flex items-center space-x-3">
          <!-- <CarIcon class="w-6 h-6 text-gray-500" /> -->
          <div class="flex-1">
            <label for="vehicleNumber" class="block text-sm font-medium text-gray-700">เบอร์รถ</label>
            <input type="text" id="vehicleNumber" v-model="vehicle.VehicleNumber" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
          </div>
        </div>
        <div class="form-group flex items-center space-x-3">
          <!-- <IdentificationIcon class="w-6 h-6 text-gray-500" /> -->
          <div class="flex-1">
            <label for="licensePlate" class="block text-sm font-medium text-gray-700">ทะเบียนรถ</label>
            <input type="text" id="licensePlate" v-model="vehicle.LicensePlate" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
      <div class="form-group flex items-center space-x-3">
        <!-- <IdentificationIcon class="w-6 h-6 text-gray-500" /> สมมติฐานไอคอน -->
        <div class="flex-1">
          <label for="province" class="block text-sm font-medium text-gray-700">จังหวัด</label>
          <select id="province" v-model="vehicle.Province" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
          <option value="" disabled>เลือกจังหวัด</option>
          <option v-for="province in provinces" :key="province" :value="province">{{ province }}</option>
    </select></div>
    </div>

      <div class="form-group flex items-center space-x-3">
        <div class="flex-1">
        <label for="brand">ยี่ห้อ</label>
  <select id="brand" v-model="vehicle.Brand" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
    <option v-for="brand in brands" :key="brand" :value="brand">{{ brand }}</option>
  </select>
      </div>
      </div>
    </div>
      <div class="grid grid-cols-2 gap-4">
      <div class="form-group flex items-center space-x-3">
        <!-- <IdentificationIcon class="w-6 h-6 text-gray-500" /> สมมติฐานไอคอน -->
        <div class="flex-1">
        <label for="modelYear">ปีรุ่น</label>
        <input type="number" id="modelYear" v-model="vehicle.ModelYear" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>
      
      <div class="form-group flex items-center space-x-3">
        <!-- <IdentificationIcon class="w-6 h-6 text-gray-500" /> สมมติฐานไอคอน -->
        <div class="flex-1">
        <label for="vehicleAge">อายุรถ</label>
        <input type="number" id="vehicleAge" v-model="vehicle.VehicleAge" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>
      </div>
      
      <div class="form-group flex items-center space-x-3">
        <div class="flex-1">
        <label for="vehicleType">ลักษณะ</label>
  <select id="vehicleType" v-model="vehicle.VehicleType" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
    <option v-for="manner in manners" :key="manner" :value="manner">{{ manner }}</option>
  </select>
      </div>
     
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="form-group">
        <label for="vehicleChassisNumber">เลขคัทซี</label>
        <input type="text" id="vehicleChassisNumber" v-model="vehicle.VehicleChassisNumber" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      <div class="form-group">
        <label for="policyNumber">เลขที่กรมธรรม์</label>
        <input type="text" id="policyNumber" v-model="vehicle.PolicyNumber" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>



      <div class="grid grid-cols-2 gap-4">
      <div class="form-group flex items-center space-x-3">
        <!-- <IdentificationIcon class="w-6 h-6 text-gray-500" /> สมมติฐานไอคอน -->
        <div class="flex-1">
        <label for="issueDate">วันที่ทำ</label>
        <input type="date" id="issueDate" v-model="vehicle.IssueDate" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>
      
      <div class="form-group">
        <label for="expiryDate">วันที่หมดอายุ</label>
        <input type="date" id="expiryDate" v-model="vehicle.ExpiryDate" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>

  
      <div class="form-group">
        <label for="insuredValue">ทุนประกัน</label>
        <input type="number" step="0.01" id="insuredValue" v-model="vehicle.InsuredValue" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      <div class="grid grid-cols-2 gap-4">
      <div class="form-group">
        <label for="NetPremiumPA">เบี้ยสุทธิป.1</label>
        <input type="number" step="0.01" id="NetPremiumPA" v-model="vehicle.NetPremiumPA" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      <div class="form-group">
        <label for="TotalPremiumPA">เบี้ยรวมป.1</label>
        <input type="number" step="0.01" id="TotalPremiumPA" v-model="vehicle.TotalPremiumPA" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
      <div class="form-group">
        <label for="NetPremiumP3">เบี้ยสุทธิป.3</label>
        <input type="number" step="0.01" id="NetPremiumP3" v-model="vehicle.NetPremiumP3" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"> 
      </div>
      <div class="form-group">
        <label for="TotalPremiumP3">เบี้ยรวมป.3</label>
        <input type="number" step="0.01" id="TotalPremiumP3" v-model="vehicle.TotalPremiumP3" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
      <div class="form-group">
        <label for="RYT">รยต</label>
        <input type="number" step="0.01" id="RYT" v-model="vehicle.RYT" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>
      <div class="form-group">
        <label for="TotalPremium">รวมเบี้ย</label>
        <input type="number" step="0.01" id="TotalPremium" v-model="vehicle.TotalPremium" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"> 
      </div>
      </div>
      <!-- ทำซ้ำสำหรับ NetPremiumPA, TotalPremiumPA, ฯลฯ -->
      <div class="grid grid-cols-2 gap-4">
      <div class="form-group">
        <label for="insuranceCompany">บริษัทประกันภัย</label>
        <input type="text" id="insuranceCompany" v-model="vehicle.InsuranceCompany" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
      </div>

      <div class="form-group">
        <label for="department">หน่วยงาน</label>
        <select id="department" v-model="vehicle.Department" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
        <option v-for="department in departments" :key="department" :value="department">{{ department }}</option>
        </select>
    </div>
      </div>
      <div class="form-group">
        <label for="workGroup">กลุ่มงาน</label>
  <select id="workGroup" v-model="vehicle.WorkGroup" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500">
    <option v-for="group in filteredWorkGroups" :key="group" :value="group">{{ group }}</option>
  </select>
</div>

      <div class="form-group">
        <label for="notes">หมายเหตุ</label>
        <textarea id="notes" v-model="vehicle.Notes" class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"></textarea>
      </div>

      <div class="flex justify-center mt-6">
        <button type="submit" class="px-6 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 text-white font-bold py-2 rounded-lg transition ease-in-out duration-150">
          Submit
        </button>
    </div>
    </form>
  </div>
  <br><br> 
</template>

  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import io from 'socket.io-client';
  // import { CarIcon, IdentificationIcon } from '@heroicons/vue/outline';
  export default {
    components: {
    // CarIcon,
    // IdentificationIcon,
  },
  data() {
    return {
      socket: null,
      provinces: ['พิษณุโลก', 'ระยอง', 'สุพรรณบุรี', 'เชียงใหม่', 'เชียงราย'],
      brands: ['VOLVO','ISUZU', 'HEIL', 'ทรัพย์เดชศรี', 'T-BELCO', 'UD TRUCK','HINO','SCANIA','FOCAL','PTP TANK','SHTEE','RCU','ไม่ระบุ'],
      manners: ['ลากจูง','กึ่งพวง','พ่วง','บรรทุกวัตถุอันตราย','บรรทุกของเหลว','พื้นเรียบ','ตู้บรรทุก','รถโดยสาร','รถตู้ส่วนบุคคล','รถ10ล้อ','รถเล็ก (รถกระบะ รถเก๋ง)'],
      insuranceCompanies: ['บริษัทนำสิน'],
      departments: ['บีอาร์เค','เวียงทอง','วงฆ้อง','มั่นคง','เอ็มบีวี','รถจอดสแปร์ส่วนกลาง','รถเล็กส่วนกลาง'],
      workGroups: {
        'บีอาร์เค': ['บึงพระ','GCระยอง', 'IRPCระยอง', 'จ๊อปเปอร์','พื้นเรียบ','ปาล์มดิบ','ส่วนกลาง', 'วิเชียร','สุพรรณบุรี', 'แพนโอเรียล', 'น้ำมันพืชประทุม', 'เคมี เมทานอล', 'กลีเซอรีน', 'ฟู้ดเกรด', 'ภูอ่อม คอนเดนเสท', 'น้ำมันเตา', 'รถหกล้อดีเชล','UAC', 'รถเช่ากระบะ ปดท.สผ', 'รถตู้เช่า Well Service', 'รถขยะ', 'รถตู้เช่าโรงไฟฟ้าลานกระบือ', 'รถเล็กบริษัท'],
        'เวียงทอง': ['บางจาก-B100', 'B100 AI-เอนเนอร์ยี่ / B100 -เซอร์คูล่า','จ๊อปเปอร์', 'บางจากเอทานอล', 'น้ำมันพืชปทุม', 'อัพเวนเจอร์', 'รถเล็กบริษัท','จ๊อปเปอร์','รถสิบล้อจ๊อปเปอร์', 'วิเชียร', 'สุพรรณ',' ภูฮ่อม', 'รถจอดสแปรส่วนกลาง'],
        'วงฆ้อง': ['วิเชียร', 'UAC', 'สุพรรณ', 'แพนโอเรียน', 'น้ำมันพืชปทุม', 'จ็อปเปอร์', 'พื้นเรียบ', 'รถจอดสแปรส่วนกลาง'],
        'มั่นคง': ['หกล้อ/สิบล้อวงฆ้อง','รถเล็กบริษัท'],
        'เอ็มบีวี': ['งานพื้นเรียบ'],
        'รถจอดสแปร์ส่วนกลาง': ['รถจอดส่วนกลาง'],
        'รถเล็กส่วนกลาง': ['รถเล็ก', 'รถเล็ก บ.ธนะดี ออโต้ ทรัค'],},
      vehicle: {
        VehicleNumber: '',
        LicensePlate: '',
        Province: '',
        Brand: '',
        ModelYear: null, // ใช้ null สำหรับค่าเริ่มต้นของตัวเลข
        VehicleAge: null,
        VehicleType: '',
        IssueDate: '',
        ExpiryDate: '',
        InsuredValue: null,
        NetPremiumPA: null,
        TotalPremiumPA: null,
        NetPremiumP3: null,
        TotalPremiumP3: null,
        RYT: null,
        TotalPremium: null,
        InsuranceCompany: '',
        Department: '',
        WorkGroup: '',
        Notes: '',
        VehicleChassisNumber: '',
        PolicyNumber: '',
      },
    };
  },
  created() {
    this.socket = io('https://brk-group.org');
    this.socket.on('connect', () => {
      console.log('Connected to server');
    });
  },
  beforeUnmount() { // เปลี่ยนจาก beforeDestroy เป็น beforeUnmount
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  computed: {
    filteredWorkGroups() {
      if (this.vehicle.Department) {
        return this.workGroups[this.vehicle.Department] || [];
      }
      return [];
    },
  },
  methods: {
    async addVehicle() {
  try {
    const formattedVehicleData = {
      ...this.vehicle,
      // Convert the date and time format from datetime-local to the format compatible with your database
      IssueDate: this.vehicle.IssueDate.replace('T', ' '),
      ExpiryDate: this.vehicle.ExpiryDate.replace('T', ' '),
    };
    await axios.post('https://brk-group.org/vehicles', formattedVehicleData);
    // await axios.post('https://brk-group.org/vehicles', formattedVehicleData);
    this.$router.push('/vehicles'); // Navigate back to the vehicle list page
    Swal.fire({
      icon: 'success',
      title: 'สำเร็จ!',
      text: 'เพิ่มข้อมูลรถสำเร็จแล้ว!',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ตกลง'
    });
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'ผิดพลาด!',
      text: 'เกิดข้อผิดพลาดในการเพิ่มข้อมูลรถยนต์',
      confirmButtonColor: '#d33',
      confirmButtonText: 'ตกลง'
    });
  }
    },
  },
};
// methods: {
//     async addVehicle() {
//       try {
//         // ส่งข้อมูลไปยัง API
//         await axios.post('https://brk-group.org/vehicles', this.vehicle);
//         this.$router.push('/vehicles'); // นำทางกลับไปยังหน้ารายการรถยนต์
//         alert('Vehicle added successfully');
//       } catch (error) {
//         console.error(error);
//         alert('Failed to add the vehicle');
//       }
//     },
//   },
// };
</script>
  
  <style scoped>
  .add-vehicle {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .add-vehicle .form-group {
    margin-bottom: 1rem;
  }
  
  .add-vehicle label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .add-vehicle input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .add-vehicle button {
    width: 100%;
    padding: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-vehicle button:hover {
    background-color: #0056b3;
  }
  </style>
  

  