<!-- src/components/Purchase/DownloadPO.vue -->
<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col mt-8">
      <div class="py-2">
    <!-- <h1>ใบสั่งซื้อ</h1> -->
    <button @click="downloadPO" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
  ดาวน์โหลดใบสั่งซื้อ
</button>
  </div>
</div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DownloadPO',
  data() {
    return {
      poId: null,
      imageUrl: null,
      groupId: null,
      groupDetails: {}
    };
  },
  created() {
    this.poId = this.$route.params.poId;
    this.imageUrl = this.$route.params.imageUrl;
    this.groupId = this.$route.params.groupId;
    console.log("Received PO ID: ", this.poId); // ตรวจสอบว่าค่า poId ถูกส่งมาอย่างถูกต้องหรือไม่
    console.log("Received Image URL: ", this.imageUrl); // ตรวจสอบว่าค่า imageUrl ถูกส่งมาอย่างถูกต้องหรือไม่
    console.log("Received Group ID: ", this.groupId); // ตรวจสอบว่าค่า groupId ถูกส่งมาอย่างถูกต้องหรือไม่
  },
  methods: {
    async downloadPO() {
      const { poId, imageUrl, groupId } = this;
      if (!poId) {
        console.error('poId is undefined');
        return;
      }
      try {
        const response = await axios.post(`https://brk-group.org/generate-po/${poId}`, { imageUrl: imageUrl, groupId: groupId }, {
          // const response = await axios.post(`http://localhost:3000/generate-po/${poId}`, { imageUrl: imageUrl, groupId: groupId }, {
          responseType: 'blob'
        });
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
          const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } else {
          console.error('Content-Disposition header is missing');
        }
      } catch (error) {
        console.error('Error downloading the PO:', error);
      }
    },
     // ฟังก์ชันแปลง URL ของรูปภาพเป็น Base64
  async getBase64FromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  }
};
</script>
