<template>
   <div class="container mx-auto p-6">
    <h1 class="text-3xl font-bold mb-6 text-gray-800">รายการใบสั่งซื้อด่วน</h1>
    <table class="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
      <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th class="px-6 py-3 text-center">เลขที่เอกสาร</th>
          <th class="px-6 py-3 text-center">ทะเบียนรถ</th>
          <th class="px-6 py-3 text-center">วันที่สร้าง</th>
          <th class="px-6 py-3 text-center">จำนวนรายการที่สั่งซื้อ</th>
          <th class="px-6 py-3 text-center">ร้านคู่ค้า</th>
          <th class="px-6 py-3 text-center">สถานะ</th>
          <th class="px-6 py-3 text-center">Action</th>
        </tr>
      </thead>
      <tbody class="text-gray-700 text-sm font-light">
        <tr v-for="order in paginatedOrders" :key="order.po_id" class="border-b border-gray-200 hover:bg-gray-100">
          <td class="px-6 py-4 text-center">{{ order.po_number }}</td>
          <td class="px-6 py-4 text-center">{{ order.LicensePlate }}</td>
          <td class="px-6 py-4 text-center">{{ formatDate(order.creation_date) }}</td>
          <td class="px-6 py-4 text-center">{{ order.items_count }}</td>
          <td class="px-6 py-4 text-center">{{ order.supplier_name }}</td>
          <td class="px-6 py-4 text-center" :class="{'bg-green-100 text-green-800': order.status === 'อนุมัติ', 'bg-red-100 text-red-800': order.status === 'ไม่อนุมัติ'}">
            {{ order.status }}
          </td>
          <td class="px-6 py-4 text-center">
            <button @click="viewDetails(order.po_id)" class="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition duration-200">
      <i class="fas fa-eye"></i> <!-- Font Awesome eye icon -->
    </button>
          </td>
        </tr>
        <tr v-if="purchaseOrders.length === 0">
          <td class="px-6 py-4 text-center" colspan="7">ไม่พบข้อมูล</td>
        </tr>
      </tbody>
    </table>
    
    <!-- Pagination Buttons -->
    <div class="flex justify-center mt-4">
      <button
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400 transition duration-200"
        :disabled="currentPage === 1"
        @click="currentPage--"
      >
        ย้อนกลับ
      </button>
      <button
        v-for="page in totalPages"
        :key="page"
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400 transition duration-200"
        :class="{ 'bg-blue-500 text-white': page === currentPage }"
        @click="currentPage = page"
      >
        {{ page }}
      </button>
      <button
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded hover:bg-gray-400 transition duration-200"
        :disabled="currentPage === totalPages"
        @click="currentPage++"
      >
        ถัดไป
      </button>
    </div>
  </div>
  </template>
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        purchaseOrders: [],
        currentPage: 1,
        
        itemsPerPage: 10
      };
    },
    computed: {
  paginatedOrders() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.purchaseOrders.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.purchaseOrders.length / this.itemsPerPage);
  }
},
    methods: {
        async fetchPurchaseOrders() {
      try {
        const response = await axios.get('https://brk-group.org/api/urgent_purchase_orders', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        this.purchaseOrders = response.data;
      } catch (error) {
        console.error('Error fetching purchase orders:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch purchase orders. Please try again later.'
        });
      }
    },
    viewDetails(poId) {
      this.$router.push({ name: 'POFormEdit', params: { id: poId }, query: { fromPOList: 'true' } });
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchUserData() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        this.agency = response.data.agency;
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    }
  },
  created() {
    this.fetchPurchaseOrders();
    this.fetchUserData();
    const poId = this.$route.params.id;
    if (poId) {
      this.fetchPOData(poId);

    }
  }
  };
  </script>
  
  <style scoped>
  /* เพิ่มสไตล์ที่คุณต้องการที่นี่ */
  </style>
  