<template>
  <div class="home-container">
    <home-menu :menus="filteredMenus"></home-menu>
  </div>
</template>

<script>
import HomeMenu from '@/components/Purchase/HomeMenu.vue';
import axios from 'axios';

export default {
  components: {
    HomeMenu
  },
  data() {
  return {
    menus: [
      {
        title: 'รายการคู่ค้า',
        icon: 'document-text',  // เพิ่มไอคอน
        route: '/suppliers',
        class: 'supplier-menu bg-stone-200'
      },
      {
        title: 'รายการใบ (PR) และ (PO)',
        icon: 'archive',  // เพิ่มไอคอน
        route: '/purchase-recives',
        class: 'pr-menu bg-amber-200'
      },
      {
        title: 'สร้างใบขอซื้อ (PRE)',
        icon: 'DocumentAddIcon',  // เพิ่มไอคอน
        route: '/purchase-requisition',
        class: 'pre-menu bg-rose-200'
      },
      {
        title: 'ประเภทคู่ค้า',
        icon: 'identification',
        route: '/suppliermanage',
        class: 'supplier-manage-menu bg-cyan-200'
      },
      {
        title: 'สินค้า',
        icon: 'shopping-cart',
        route: '/products',
        class: 'product-menu bg-emerald-200'
      },
      {
        title: 'ประเภทสินค้า',
        icon: 'tags',
        route: '/product-types',
        class: 'product-type-menu bg-lime-200'
      },
      {
        title: 'ประวัติซ่อม',
        icon: 'clipboard-list',
        route: '/repair-history',
        class: 'repair-history-menu bg-purple-200'
      },
      {
        title: 'รายงานการซื้อ',
        icon: 'chart-2',
        route: '/purchase-report',
        class: 'report-menu bg-indigo-200'
      },
      {
        title: 'รายงานใบขอซื้อ',
        icon: 'chart-1',
        route: '/purchase-request',
        class: 'request-menu bg-orange-200'
      }
    ],
      user: {}
    }
  },
  
  methods: {
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        this.agency = response.data.agency;
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    }
  },
  computed: {
    filteredMenus() {
      let updatedMenus = [...this.menus];
    
      // สำหรับประธานกรรมการ
       // กรณีผู้ใช้ที่เป็น Boss หรือ ประธานกรรมการ
    if (this.user.position_lo === 'ประธานกรรมการ' || this.user.position === 'Boss') {
      return [
        { 
          title: 'รายงานใบขอซื้อ', 
          route: '/purchase-request', 
          class: 'request-menu bg-orange-200',
          icon: 'chart-1' 
        },
        { 
          title: 'รายงานการซื้อ', 
          route: '/purchase-report', 
          class: 'report-menu bg-indigo-200',
          icon: 'chart-2'
        }
      ];
    }
         // สำหรับผู้จัดการฝ่ายจัดซื้ออะไหล่
         if (this.user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่') {
        updatedMenus.push({ 
          title: 'อนุมัติ', 
          route: '/approve-po', 
          class: 'approve-po-menu bg-yellow-200',
          icon: 'check-circle' // เปลี่ยนเป็นชื่อไอคอนที่ใช้ใน getIconComponent
        });
        updatedMenus.push({ 
          title: 'รายการใบPOด่วน', 
          route: '/po-list', 
          class: 'po-list-menu bg-teal-200',
          icon: 'list-alt' // เปลี่ยนเป็นชื่อไอคอนที่ใช้ใน getIconComponent
        });
      }

      // สำหรับเจ้าหน้าที่ธุรการจัดซื้อ
      if (this.user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ') {
  updatedMenus.push(
    { 
      title: 'รายการใบPOด่วน', 
      route: '/po-list', 
      class: 'po-list-menu',
      icon: 'list-alt' // เปลี่ยนเป็นชื่อไอคอนที่ใช้ใน getIconComponent
    },
    { 
      title: 'ฟอร์ม Mapping', // เพิ่มเมนูสำหรับฟอร์ม Mapping
      route: '/mapping-form', 
      class: 'mapping-form-menu bg-pink-200',
      icon: 'edit' // เปลี่ยนเป็นไอคอนที่เหมาะสม
    }
  );
}
return updatedMenus;
    }
  },
  async created() {
    await this.fetchUser();
  }
};
</script>

<style scoped>
.home-container {
  padding: 20px;
}
</style>
