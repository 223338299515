<template>
    <div class="container mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4 text-center">แมปรหัสสินค้ากับร้านค้า</h2>
      <form @submit.prevent="submitForm" class="max-w-lg mx-auto bg-white p-6 rounded shadow">
        <!-- Product ID Field with Modal Trigger -->
        <div class="mb-4">
          <label for="product_id" class="block text-gray-700">รหัสสินค้า (ภายในระบบ)</label>
          <input
          v-model="selectedProductCode" 
            type="text"
            id="product_id"
            class="mt-1 block w-full border border-gray-300 rounded p-2"
            placeholder="กดเพื่อเลือกรหัสสินค้า"
            readonly
            @click="showProductModal = true"
          />
        </div>
  
      <!-- Supplier ID Field with Modal Trigger -->
      <div class="mb-4">
  <label for="supplier_id" class="block text-gray-700">ชื่อผู้จัดจำหน่าย</label>
  <input
    v-model="selectedSupplierName" 
    type="text"
    id="supplier_name"
    class="mt-1 block w-full border border-gray-300 rounded p-2"
    placeholder="กดเพื่อเลือกรหัสผู้จัดจำหน่าย"
    readonly
    @click="showSupplierModal = true"
  />
</div>
  
        <!-- Supplier Product Code Field -->
        <div class="mb-4">
          <label for="supplier_product_code" class="block text-gray-700">รหัสสินค้าจากร้านค้า</label>
          <input
            v-model="form.supplier_product_code"
            type="text"
            id="supplier_product_code"
            class="mt-1 block w-full border border-gray-300 rounded p-2"
            placeholder="กรุณากรอกรหัสสินค้าจากร้านค้า"
            required
          />
        </div>
  
        <div class="flex justify-between mt-4">
        <button type="submit" class="bg-blue-500 text-white py-2 px-4 rounded">บันทึกข้อมูล</button>
        <router-link to="/mapping-list" class="bg-gray-500 text-white py-2 px-4 rounded">
          ดูรายการที่แมป
        </router-link>
      </div>
      </form>
  
      <!-- Modal for Product Selection -->
      <div v-if="showProductModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
        <div class="bg-white p-6 rounded shadow-lg w-full max-w-2xl">
          <h3 class="text-xl font-bold mb-4">เลือกสินค้า</h3>
          <!-- Search Input -->
          <input
            v-model="searchTerm"
            type="text"
            class="w-full mb-4 p-2 border border-gray-300 rounded"
            placeholder="ค้นหารหัสสินค้าหรือชื่อสินค้า"
            @input="fetchProducts"
          />
          <!-- Product List -->
          <div class="overflow-y-auto max-h-80">
            <table class="w-full text-left border-collapse">
              <thead>
                <tr>
                    <th class="p-2 border-b">รูปภาพ</th>
                  <th class="p-2 border-b">รหัสสินค้า</th>
                  <th class="p-2 border-b">ชื่อสินค้า</th>
                  <th class="p-2 border-b">ประเภท</th>
                  <th class="p-2 border-b">เลือก</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product.product_id">
                    <td class="p-2 border-b">
          <!-- แปลง URL ของรูปภาพ -->
          <img 
            v-if="product.image_url" 
            :src="product.image_url.replace('https://brknew.synology.me/web/imageProducts/', 'https://brkmeeting-room.online/')" 
            alt="Product Image" 
            class="w-16 h-16 object-cover" />
          <span v-else>ไม่มีรูปภาพ</span> <!-- แสดงข้อความเมื่อไม่มีรูปภาพ -->
        </td>
                  <td class="p-2 border-b">{{ product.product_code }}</td>
                  <td class="p-2 border-b">{{ product.product_name }}</td>
                  <td class="p-2 border-b">{{ product.part_type }}</td>
                  <td class="p-2 border-b">
                    <button @click="selectProduct(product)" class="bg-blue-500 text-white px-2 py-1 rounded">
                      เลือก
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button @click="showProductModal = false" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">
            ปิด
          </button>
        </div>
      </div>
    </div>



        <!-- Modal for Supplier Selection -->
        <div v-if="showSupplierModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white p-6 rounded shadow-lg w-full max-w-2xl">
        <h3 class="text-xl font-bold mb-4">เลือกร้านค้า</h3>
        <!-- Search Input -->
        <input
          v-model="supplierSearchTerm"
          type="text"
          class="w-full mb-4 p-2 border border-gray-300 rounded"
          placeholder="ค้นหาชื่อผู้จัดจำหน่าย"
          @input="fetchSuppliers"
        />
        <!-- Supplier List -->
        <div class="overflow-y-auto max-h-80">
          <table class="w-full text-left border-collapse">
            <thead>
              <tr>
                <th class="p-2 border-b">ชื่อผู้จัดจำหน่าย</th>
                <th class="p-2 border-b">เบอร์โทรศัพท์</th>
                <th class="p-2 border-b">เลือก</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="supplier in suppliers" :key="supplier.supplier_id">
                <td class="p-2 border-b">{{ supplier.company_name }}</td>
                <td class="p-2 border-b">{{ supplier.phone }}</td>
                <td class="p-2 border-b">
                  <button @click="selectSupplier(supplier)" class="bg-blue-500 text-white px-2 py-1 rounded">
                    เลือก
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button @click="showSupplierModal = false" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">
          ปิด
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        form: {
          product_id: '',
          supplier_id: '',
          supplier_product_code: ''
        },
        showProductModal: false, // สำหรับแสดง/ซ่อน modal
        products: [], // เก็บข้อมูลสินค้า
        searchTerm: '', // เก็บคำค้นหา
        page: 1, // หน้าปัจจุบัน
        limit: 10, // จำนวนสินค้าต่อหน้า
        totalPages: 1, // จำนวนหน้าทั้งหมด
        showSupplierModal: false, // สำหรับแสดง/ซ่อน modal ของผู้จัดจำหน่าย
      suppliers: [], // เก็บข้อมูลผู้จัดจำหน่าย
      supplierSearchTerm: '', // คำค้นหาผู้จัดจำหน่าย
      selectedSupplierName: '', // ใช้เพื่อแสดงชื่อผู้จัดจำหน่ายในฟิลด์
      selectedProductCode: '',
      };
    },
    methods: {


        async submitForm() {
      try {
        const response = await fetch('https://brk-group.org/api/product-supplier-mapping', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.form)
        });

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'สำเร็จ',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'ตกลง'
          });
          this.form.product_id = '';
          this.form.supplier_id = '';
          this.form.supplier_product_code = '';
          this.selectedProductCode = '';
          this.selectedSupplierName = '';
        } else {
          Swal.fire({
            icon: 'error',
            title: 'ข้อผิดพลาด',
            text: 'ไม่สามารถบันทึกข้อมูลได้',
            confirmButtonText: 'ตกลง'
          });
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'ข้อผิดพลาด',
          text: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์',
          confirmButtonText: 'ตกลง'
        });
      }
    },


      async fetchProducts() {
        // เรียก API เพื่อดึงข้อมูลสินค้า พร้อมค้นหาและ pagination
        try {
          const response = await fetch(
            `https://brk-group.org/api/products?searchQuery=${this.searchTerm}&page=${this.page}&limit=${this.limit}`
          );
          const data = await response.json();
          console.log('API Data:', data); // ตรวจสอบข้อมูลที่ได้รับจาก API
          this.products = data.products;
          this.totalPages = data.totalPages;
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      },
      selectProduct(product) {
      this.form.product_id = product.product_id; // บันทึก product_id
      this.selectedProductCode = product.product_code; // แสดง product_code ในฟิลด์
      this.showProductModal = false;
    },
      async fetchSuppliers() {
      // เรียก API เพื่อดึงข้อมูลผู้จัดจำหน่าย
      try {
        const response = await fetch(
          `https://brk-group.org/api/suppliers?search=${this.supplierSearchTerm}`
        );
        const data = await response.json();
        this.suppliers = data;
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    },
    selectSupplier(supplier) {
      // เมื่อเลือกผู้จัดจำหน่ายจาก modal จะตั้งค่า supplier_id และปิด modal
      this.form.supplier_id = supplier.supplier_id;
      this.selectedSupplierName = supplier.company_name; // แสดงชื่อร้าน
      this.showSupplierModal = false;
    }
  
    },
    created() {
      // ดึงข้อมูลสินค้าทั้งหมดเมื่อสร้าง component
      this.fetchProducts();
      this.fetchSuppliers();
    }
  };
  </script>
  
  <style scoped>
  /* Custom Styles */
  </style>
  