<template>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">รายการพนักงาน</h1>
      <!-- ช่องค้นหา -->
<div class="mb-4">
  <input 
    v-model="searchQuery"
    type="text"
    placeholder="ค้นหาจากรหัสพนักงานหรือชื่อ"
    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
  />
</div>

      <!-- แสดงข้อมูลพนักงานในรูปแบบตาราง -->
      <table class="min-w-full bg-white">
  <thead>
    <tr>
      <th class="py-2">รหัสพนักงาน</th>
      <th class="py-2">ชื่อ-นามสกุล</th>
      <th class="py-2">ตำแหน่ง</th>
      <th class="py-2">แผนก</th>
      <th class="py-2">เบอร์โทรศัพท์</th>
      <th class="py-2">การดำเนินการ</th> <!-- เพิ่มคอลัมน์สำหรับปุ่มการดำเนินการ -->
    </tr>
  </thead>
  <tbody>
    <tr v-for="employee in paginatedEmployees" :key="employee.employee_code">
      <td class="py-2">{{ employee.employee_code }}</td>
      <td class="py-2">{{ employee.prefix }} {{ employee.first_name }} {{ employee.last_name }}</td>
      <td class="py-2">{{ employee.position_th }}</td>
      <td class="py-2">{{ employee.department }}</td>
      <td class="py-2">{{ employee.phone_number }}</td>
      <td class="py-2">
        <!-- ปุ่มแก้ไข -->
        <button 
          class="bg-blue-500 text-white px-4 py-1 rounded mr-2"
          @click="editEmployee(employee)"
        >
          แก้ไข
        </button>
        <!-- ปุ่มลบ -->
        <button 
          class="bg-red-500 text-white px-4 py-1 rounded"
          @click="deleteEmployee(employee.employee_code)"
        >
          ลบ
        </button>
      </td>
    </tr>
  </tbody>
</table>
  
    <!-- การแบ่งหน้า -->
<div class="mt-4 flex justify-center">
  <!-- ปุ่ม หน้าแรก -->
  <button 
    v-if="currentPage > 3" 
    @click="goToPage(1)" 
    class="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
  >
    หน้าแรก
  </button>

  <!-- ปุ่ม ก่อนหน้า -->
  <button 
    :disabled="currentPage === 1" 
    @click="currentPage--" 
    class="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
  >
    ก่อนหน้า
  </button>

  <!-- ปุ่มหมายเลขหน้า -->
  <button 
    v-for="page in pageNumbers" 
    :key="page" 
    @click="goToPage(page)" 
    :class="{'bg-blue-500 text-white': currentPage === page, 'bg-gray-300 text-gray-700': currentPage !== page}"
    class="px-4 py-2 rounded mr-2"
  >
    {{ page }}
  </button>

  <!-- ปุ่ม ถัดไป -->
  <button 
    :disabled="currentPage === totalPages" 
    @click="currentPage++" 
    class="bg-gray-300 text-gray-700 px-4 py-2 rounded"
  >
    ถัดไป
  </button>

  <!-- ปุ่ม หน้าท้าย -->
  <button 
    v-if="currentPage < totalPages - 2" 
    @click="goToPage(totalPages)" 
    class="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2"
  >
    หน้าท้าย
  </button>
</div>

    </div>
      <!-- Modal สำหรับแก้ไขข้อมูลพนักงาน -->
      <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 class="text-xl font-bold mb-4">แก้ไขข้อมูลพนักงาน</h2>

        <!-- ฟอร์มแก้ไขข้อมูลพนักงาน -->
        <div class="mb-4">
          <label class="block text-gray-700">รหัสพนักงาน</label>
          <input v-model="selectedEmployee.employee_code" type="text" class="w-full border px-3 py-2 rounded" disabled />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">ชื่อ-นามสกุล</label>
          <input v-model="selectedEmployee.first_name" type="text" class="w-full border px-3 py-2 rounded" />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">ตำแหน่ง</label>
          <input v-model="selectedEmployee.position_th" type="text" class="w-full border px-3 py-2 rounded" />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">แผนก</label>
          <input v-model="selectedEmployee.department" type="text" class="w-full border px-3 py-2 rounded" />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700">เบอร์โทรศัพท์</label>
          <input v-model="selectedEmployee.phone_number" type="text" class="w-full border px-3 py-2 rounded" />
        </div>

        <!-- ปุ่มสำหรับบันทึกหรือยกเลิก -->
        <div class="flex justify-end">
          <button @click="showModal = false" class="bg-gray-500 text-white px-4 py-2 rounded mr-2">ยกเลิก</button>
          <button @click="saveEmployee" class="bg-blue-500 text-white px-4 py-2 rounded">บันทึก</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
  
    export default {
      data() {
        return {
          employees: [], // เก็บข้อมูลพนักงาน
          user: {},// เก็บข้อมูลผู้ใช้
          searchQuery: '', // ข้อความสำหรับการค้นหา
    currentPage: 1, // หน้าปัจจุบัน
    itemsPerPage: 10, // จำนวนรายการต่อหน้า
    selectedEmployee: null, // พนักงานที่เลือกเพื่อแก้ไข
    showModal: false, // สถานะการแสดง modal
        };
      },
 
        computed: {
  filteredEmployees() {
    // กรองข้อมูลพนักงานจากการค้นหา
    return this.employees.filter(employee => {
      const fullName = `${employee.prefix} ${employee.first_name} ${employee.last_name}`.toLowerCase();
      return (
        employee.employee_code.includes(this.searchQuery) ||
        fullName.includes(this.searchQuery.toLowerCase())
      );
    });
  },
  paginatedEmployees() {
    // แบ่งหน้ารายการพนักงาน
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredEmployees.slice(start, end);
  },
  totalPages() {
    // จำนวนหน้าทั้งหมด
    return Math.ceil(this.filteredEmployees.length / this.itemsPerPage);
  },
  pageNumbers() {
    const pages = [];
    const startPage = Math.max(1, this.currentPage - 2);
    const endPage = Math.min(this.totalPages, this.currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }
},
      methods: {
        goToPage(page) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  },
        async fetchEmployees() {
          const token = localStorage.getItem('userToken');
          if (!token) {
            this.$router.push({ name: 'Login' });
            return;
          }
          try {
            const response = await axios.get('http://localhost:3000/api/employees', {
              headers: { 'Authorization': `Bearer ${token}` }
            });
            this.employees = response.data;
          } catch (error) {
            console.error(error);
            alert('เกิดข้อผิดพลาดในการดึงข้อมูลพนักงาน');
          }
        },
        async fetchUser() {
          const token = localStorage.getItem('userToken');
          console.log('Token:', token);
          if (!token) {
            this.$router.push({ name: 'Login' });
            return;
          }
          try {
            const response = await axios.get('http://localhost:3000/api/user', {
              headers: { 'Authorization': `Bearer ${token}` }
            });
            const userData = response.data;
            console.log('User Data:', userData);
            this.user = {
              login_id: userData.login_id,
              fullName: `${userData.prefixname} ${userData.fname} ${userData.lname}`,
              agency: userData.agency,
              group_id: userData.group_id,
              position_lo: userData.position_lo
            };
            // ดึงข้อมูลพนักงานหลังจากดึงข้อมูลผู้ใช้สำเร็จ
            this.fetchEmployees();
          } catch (error) {
            console.error('Error fetching user:', error);
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'Login' });
            } else {
              alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
            }
          }
        },
        editEmployee(employee) {
      this.selectedEmployee = { ...employee }; // ทำสำเนาของพนักงานที่เลือก
      this.showModal = true; // แสดง modal
    },
    // ฟังก์ชันสำหรับบันทึกข้อมูลพนักงาน
    // ฟังก์ชันสำหรับบันทึกข้อมูลพนักงาน
  async saveEmployee() {
    try {
      // ส่งข้อมูลที่แก้ไขไปยัง API ด้วยคำขอ PUT
      const response = await axios.put(`http://localhost:3000/api/employees/${this.selectedEmployee.employee_code}`, this.selectedEmployee);
      
      console.log('Employee updated:', response.data); // ตรวจสอบข้อมูลที่ได้รับจาก API
      
      // อัปเดตข้อมูลใน frontend ถ้าจำเป็น
      const index = this.employees.findIndex(emp => emp.employee_code === this.selectedEmployee.employee_code);
      if (index !== -1) {
        this.employees.splice(index, 1, this.selectedEmployee); // แทนที่พนักงานที่แก้ไขในรายการ
      }

      // ปิด modal หลังบันทึกเสร็จ
      this.showModal = false;
    } catch (error) {
      console.error('Error updating employee:', error); // จัดการข้อผิดพลาด
    }
  },
    // ฟังก์ชันสำหรับลบพนักงาน
    async deleteEmployee(employeeCode) {
      try {
        // ตัวอย่างการลบพนักงานโดยใช้ API
        await axios.delete(`http://localhost:3000/api/employees/${employeeCode}`);
        alert('ลบข้อมูลสำเร็จ');
        // ลบข้อมูลออกจากหน้าเว็บทันที
        this.employees = this.employees.filter(emp => emp.employee_code !== employeeCode);
      } catch (error) {
        console.error('Error deleting employee:', error);
        alert('เกิดข้อผิดพลาดในการลบข้อมูล');
      }
    },
      },
      created() {
        this.fetchUser(); // ดึงข้อมูลผู้ใช้เมื่อหน้าโหลด
      },
      mounted() {
   

  }
    };
  </script>
  
  <style scoped>
    table {
      border-collapse: collapse;
      width: 100%;
    }
  
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }
  
    th {
      background-color: #f2f2f2;
    }
  </style>
  