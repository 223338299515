<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-semibold mb-6">จัดการประเภทสินค้า</h1>
    <div class="mb-4">
      
      <input v-model="searchQuery" @input="fetchProductTypes" placeholder="ค้นหาจากชื่อประเภทสินค้า..." class="p-2 border rounded">
      <button @click="showModal(null)" class="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">เพิ่มประเภทสินค้า</button>
      
    </div>
    
    <div class="flex justify-end">
      <span class="inline-flex rounded-md shadow-sm">
        จำนวนประเภทสินค้า: <span class="text-sm font-semibold ml-2">{{ productTypes.length }}</span>
      </span>
    </div>
<br>
    <!-- <div class="flex justify-between items-center mt-8 mb-4">
    <div class="mb-4 flex flex-wrap items-center justify-between gap-4"> -->
      <!-- <router-link to ="/products" class="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">รายการสินค้า</router-link>
      <router-link to ="/product-types" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">ประเภทสินค้า</router-link>
      <router-link to ="/brandsProducts" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">ยี่ห้อสินค้า</router-link>
      <router-link to ="/vehicleTypes" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">ประเภทรถ</router-link>
      <router-link to ="/partTypes" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ประเภทอะไหล่</router-link> -->
    <!-- </div>
    </div> -->
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click="changePage(currentPage - 1)">ย้อนกลับ</a>
        </li>
        <li class="page-item" v-for="page in totalPages" :class="{ active: currentPage === page }" :key="page">
          <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click="changePage(currentPage + 1)">ถัดไป</a>
        </li>
      </ul>
    </nav><br>
    
    <table class="w-full table-auto">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">รหัสประเภท</th>
          <th class="px-4 py-2">ชื่อประเภท</th>
          <th class="px-4 py-2">รายละเอียด</th>
          <th class="px-4 py-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="paginatedData.length">
          <tr v-for="type in paginatedData" :key="type.product_type_id">
            <td class="border px-4 py-2">{{ type.product_code_name }}</td>
            <td class="border px-4 py-2">{{ type.type_name }}</td>
            <td class="border px-4 py-2">{{ type.description }}</td>
            <td class="border px-4 py-2">
              <button @click="showModal(type)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded">แก้ไข</button>
              <button @click="attemptDeleteProductType(type)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">ลบ</button>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="4" class="text-center py-4">ไม่พบรายการ</td>
          </tr>
        </template>
      </tbody>
    </table>
   
     <!-- Add :saved="handleSaveSuccess" to AddEditProductTypeModal component -->
    <AddEditProductTypeModal :visible="isModalVisible" :productType="currentProductType" @close="closeModal" @saved="handleSaveSuccess"/>

    <ConfirmDeleteModal :visible="isConfirmModalVisible" :products="productsInType" :productTypeName="currentProductType?.type_name" @confirmDelete="deleteProductType" @close="closeConfirmModal"/>
  </div><br><br>
</template>

<script>
import axios from 'axios';
import AddEditProductTypeModal from './AddEditProductTypeModal.vue';
import ConfirmDeleteModal from './ConfirmDeleteModal.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    AddEditProductTypeModal,
    ConfirmDeleteModal
  },
  data() {
    return {
      searchQuery: '',
      productTypes: [],
      isModalVisible: false,
      currentProductType: null,
      isConfirmModalVisible: false,
      productsInType: [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.productTypes.length / this.pageSize);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.productTypes.slice(start, end);
    },
  },
  methods: {
    fetchProductTypes() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      axios.get(`https://brk-group.org/api/product-types?search=${this.searchQuery}`)
        .then(response => {
          this.productTypes = response.data;
          this.currentPage = 1; // กลับไปที่หน้าแรก
        })
        .catch(error => console.error("Error fetching product types:", error));
    }, 50);
  },
  showModal(type = null) {
  this.currentProductType = type ? { ...type } : { product_code_name: '',type_name: '', description: '',  };
  this.isModalVisible = true;
},

    closeModal() {
      this.isModalVisible = false;
      this.fetchProductTypes();
    },
    handleSubmit(productType) {
      const method = productType.product_type_id ? 'put' : 'post';
      const url = productType.product_type_id ? `https://brk-group.org/api/product-types/${productType.product_type_id}` : 'https://brk-group.org/api/product-types';
      axios[method](url, productType)
        .then(() => {
          this.closeModal();
          this.fetchProductTypes();
        })
        .catch(error => {
          console.error("Error saving product type:", error);
          // alert("Failed to save product type.");
        });
    },
    closeConfirmModal() {
      this.isConfirmModalVisible = false;
      this.productsInType = [];
    },
// ใน methods:
attemptDeleteProductType(type) {
  axios.get(`https://brk-group.org/api/products?productTypeId=${type.product_type_id}`)
    .then(response => {
      if (response.data.length > 0) {
        // ถ้ามีสินค้าในประเภทนี้, แสดง ConfirmDeleteModal กับรายการสินค้า
        this.productsInType = response.data;
        this.currentProductType = type;
        this.isConfirmModalVisible = true;
      } else {
        // ถ้าไม่มีสินค้า, แสดงข้อความยืนยันการลบด้วย SweetAlert2
        Swal.fire({
          title: `คุณต้องการลบประเภทสินค้า: "${type.type_name}" ใช่หรือไม่?`,
          text: "คุณจะไม่สามารถย้อนกลับการกระทำนี้ได้!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ยืนยันการลบ!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteProductType(type.product_type_id);
          }
        });
      }
    })
    .catch(error => {
      console.error("Error fetching products for the type:", error);
      Swal.fire('ผิดพลาด', 'เกิดข้อผิดพลาดในการดึงข้อมูลสินค้าตามประเภท', 'error');
    });
},

deleteProductType(productTypeId) {
  axios.delete(`https://brk-group.org/api/product-types/${productTypeId}`)
    .then(() => {
      Swal.fire('ลบแล้ว!', 'ประเภทสินค้าของคุณถูกลบแล้ว.', 'success');
      this.fetchProductTypes();
      this.closeConfirmModal();
    })
    .catch(error => {
      console.error("Error deleting product type:", error);
      Swal.fire('เกิดข้อผิดพลาด', 'กรุณาลบสินค้าที่อยู่ในประเภทที่ต้องการลบให้หมด', 'error');
    });
},
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
    },
    handleSaveSuccess() {
    this.fetchProductTypes(); // Refresh the product types list
    this.closeModal();
  },
  },
  
  mounted() {
    this.fetchProductTypes();
  }
};
</script>
<style scoped>
/* Add your styles here */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}
.page-item.disabled .page-link, .page-item.active .page-link {
  pointer-events: none;
}
.page-link {
  padding: 5px 10px;
  margin: 0 2px;
  border: 1px solid #dee2e6;
  color: #007bff;
  cursor: pointer;
}
.page-item.active .page-link {
  background-color: #007bff;
  color: white;
}
</style>
