<template>
    <div class="container mx-auto p-4">
      <form @submit.prevent="submitForm" class="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-2xl font-bold mb-4">ลงทะเบียนพนักงาน</h1>
  
        <!-- รหัสพนักงาน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">รหัสพนักงาน</label>
          <input v-model="employee.employee_code" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" required />
        </div>
  
     <!-- คำนำหน้า -->
<div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">คำนำหน้า</label>
  <select v-model="employee.prefix" class="block appearance-none w-full bg-white border border-gray-400 px-4 py-2 pr-8 rounded">
    <option value="นาย">นาย</option>
    <option value="นาง">นาง</option>
    <option value="นางสาว">นางสาว</option>
  </select>
</div>

<!-- ชื่อ -->
<div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">ชื่อ</label>
  <input v-model="employee.first_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" required />
</div>

<!-- นามสกุล -->
<div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">นามสกุล</label>
  <input v-model="employee.last_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" required />
</div>
  
        <!-- ชื่อเล่น -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ชื่อเล่น</label>
          <input v-model="employee.nickname" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  
        <!-- วันเริ่มทดลองงาน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">วันเริ่มทดลองงาน</label>
          <input v-model="employee.trial_start_date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="date" />
        </div>
  
        <!-- วันเริ่มบรรจุ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">วันเริ่มบรรจุ</label>
          <input v-model="employee.confirmed_start_date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="date" />
        </div>
  
        <!-- วันสิ้นสุดทดลองงาน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">วันสิ้นสุดทดลองงาน</label>
          <input v-model="employee.trial_end_date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="date" />
        </div>
  
        <!-- ตำแหน่ง (ภาษาไทย) -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ตำแหน่ง (ภาษาไทย)</label>
          <input v-model="employee.position_th" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  
        <!-- ตำแหน่ง (ภาษาอังกฤษ) -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ตำแหน่ง (ภาษาอังกฤษ)</label>
          <input v-model="employee.position_eng" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  
        <!-- เบอร์โทรศัพท์ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">เบอร์โทรศัพท์</label>
          <input v-model="employee.phone_number" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  
        <!-- แผนก -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">แผนก</label>
          <input v-model="employee.department" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  
        <!-- หน่วยงาน -->
<div class="mb-4">
  <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
  <select v-model="employee.work_group" class="block appearance-none w-full bg-white border border-gray-400 px-4 py-2 pr-8 rounded">
    <option value="HQ">HQ</option>
    <option value="BRK">BRK</option>
    <option value="TND">TND</option>
    <option value="MK">MK</option>
    <option value="VK">VK</option>
    <option value="VTL">VTL</option>
  </select>
</div>

        <!-- เงินเดือน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">เงินเดือน (ทดลองงาน)</label>
          <input v-model="employee.trial_salary" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="number" />
        </div>
  
        
        <!-- เงินเดือน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">เงินเดือน</label>
          <input v-model="employee.confirmed_salary" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="number" />
        </div>

        <!-- หมายเหตุ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ</label>
          <textarea v-model="employee.note" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" rows="3"></textarea>
        </div>
  
        <!-- ฟิลด์เพิ่มเติม เช่น วันเกิด, เพศ, ที่อยู่ปัจจุบัน -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">วันเกิด</label>
          <input v-model="employee.birth_date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="date" />
        </div>
  
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">เพศ</label>
          <select v-model="employee.gender" class="block appearance-none w-full bg-white border border-gray-400 px-4 py-2 pr-8 rounded">
            <option value="ชาย">ชาย</option>
            <option value="หญิง">หญิง</option>
          </select>
        </div>
  
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ที่อยู่ปัจจุบัน</label>
          <input v-model="employee.current_address" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" />
        </div>
  


        <!-- ปุ่มบันทึก -->
        <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          บันทึกข้อมูล
        </button>
      </form>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
  data() {
    return {
      employee: {
        employee_code: '',
        prefix: '',
        first_name: '',
        last_name: '',
        nickname: '',
        trial_start_date: '',
        confirmed_start_date: '',
        trial_end_date: '',
        position_th: '',
        position_eng: '',
        department: '',
        phone_number: '',
        work_group: '',
        trial_salary: null,
        confirmed_salary: null,
        note: '',
        birth_date: '',
        gender: '',
        current_address: ''
      }
    };
  },
  methods: {
      validateForm() {
        if (!this.employee.employee_code) {
          Swal.fire('เกิดข้อผิดพลาด', 'กรุณากรอกรหัสพนักงาน', 'error');
          return false;
        }
        // ตรวจสอบฟิลด์อื่นๆ ตามที่คุณต้องการ
        return true; // ถ้าข้อมูลถูกต้อง
      },

      // ฟังก์ชันสำหรับส่งฟอร์ม
      submitForm() {
        if (this.validateForm()) {
          axios.post('http://localhost:3000/employees', this.employee)
            .then(() => {
              Swal.fire('สำเร็จ', 'เพิ่มพนักงานเรียบร้อย', 'success');
              
              // เคลียร์ข้อมูลฟอร์มหลังบันทึกสำเร็จ
              this.clearForm();
            })
            .catch((error) => {
              console.error(error);
              Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มพนักงานได้', 'error');
            });
        } else {
          Swal.fire('เกิดข้อผิดพลาด', 'กรุณากรอกข้อมูลให้ถูกต้อง', 'error');
        }
      },

      // ฟังก์ชันเคลียร์ข้อมูลฟอร์ม
      clearForm() {
        this.employee = {
          employee_code: '',
          prefix: '',
          first_name: '',
          last_name: '',
          nickname: '',
          trial_start_date: '',
          confirmed_start_date: '',
          trial_end_date: '',
          position_th: '',
          position_eng: '',
          department: '',
          phone_number: '',
          work_group: '',
          trial_salary: null,
          note: '',
          birth_date: '',
          gender: '',
          current_address: ''
        };
      }
    }
};
</script>
  
  <style>

  </style>
  