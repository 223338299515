<template>
  <div class="flex">
    <!-- Sidebar -->
    <div class="w-64 bg-white shadow-md">
      <nav class="mt-10">
        <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('profile')">แก้ไขโปรไฟล์</a>
        <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('users')">รายชื่อผู้ใช้งาน</a>
        <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('positions')">บทบาท/การตั้งค่าการเข้าถึง</a>
        <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('groups')">การจัดการกลุ่มผู้ใช้</a>
      </nav>
    </div>

    <!-- Content Area -->
    <div class="flex-1 p-10">
      <!-- Profile Section -->
      <div v-if="activeSection === 'profile'">
        <!-- Profile Editing Form -->
        <form @submit.prevent="updateUser" enctype="multipart/form-data" class="space-y-4">
          <!-- Form fields for updating user -->
          <!-- ... similar to the form fields shown in the user's request ... -->
        </form>
      </div>

<!-- Users Section -->
<div v-if="activeSection === 'users'">
  <button @click="showAddUserForm" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">เพิ่มผู้ใช้งาน</button>
  <div v-if="loading" class="loading">Loading users...</div>
  <div v-else class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
    <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
      <thead>
        <tr class="text-center">
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">
            <input type="checkbox" class="form-checkbox" />
          </th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">รูปภาพ</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ชื่อ - นามสกุล</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ตำแหน่ง</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">หน่วยงาน</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">แผนก</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">เบอร์ติดต่อ</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">บทบาท</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ตัวเลือก</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td><input type="checkbox" class="form-checkbox" :value="user.id" /></td>
          <td><img :src="user.image_url" alt="User Image" class="w-10 h-10 rounded-full cursor-pointer" @click="showImage(user.image_url)" /></td>
          <td>{{ user.fullname }}</td>
          <td>{{ user.position_lo }}</td>
          <td>{{ user.agency }}</td>
          <td>{{ user.department }}</td>
          <td>{{ user.phone_number }}</td>
          <td>{{ user.position }}</td> <!-- แสดงชื่อบทบาท -->
          <td>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" @click="editUser(user)">Edit</button>
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" @click="deleteUser(user.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- Image Modal -->
<div v-if="showImageModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-start justify-center pt-10" @click.self="showImageModal = false">
  <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl mx-4 md:mx-0">
    <div class="px-6 py-4">
      <h3 class="text-lg font-semibold text-center mb-4">รูปภาพผู้ใช้</h3>
      <img :src="imageToShow" alt="Full Size Image" class="w-full h-auto rounded" />
      <div class="flex justify-end pt-4">
        <button @click="showImageModal = false" class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ปิด</button>
      </div>
      <br><br><br>
    </div>
  </div>
</div>  

      <!-- Groups Section -->
      <div v-if="activeSection === 'groups'">
        <button @click="showAddGroupForm" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">เพิ่มกลุ่มงาน</button>
        <div v-if="loadingGroups" class="loading">Loading groups...</div>
        <div v-else class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
          <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
        <thead>
          <tr class="text-center">
            <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">รหัสหน่วยงาน</th>
            <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ชื่อหน่วยงาน</th>
            <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ชื่อบริษัท</th>
            <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ที่อยู่</th>
            <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ตัวเลือก</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groups" :key="group.group_id">
            <td>{{ group.group_id }}</td>
            <td>{{ group.group_name }}</td>
            <td>{{ group.group_details }}</td>
            <td>{{ group.group_address }}</td>
            <td>
              <button class="text-blue-500 hover:underline" @click="editGroup(group)">แก้ไข</button>
              <button class="text-red-500 hover:underline" @click="deleteGroup(group.group_id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
        </div>
      </div>
  <!-- Modal Section -->
  <div v-if="showAddGroupModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div class="bg-white p-5 rounded-lg shadow-lg">
      <h2 class="text-xl font-bold mb-4">{{ isEditing ? 'แก้ไขหน่วยงาน' : 'เพิ่มหน่วยงาน' }}</h2>
      <input v-model="currentGroup.name" class="border p-2 w-full mb-4" placeholder="ชื่อหน่วยงาน" />
      <input v-model="currentGroup.details" class="border p-2 w-full mb-4" placeholder="ชื่อหน่วยงาน" />
      <input type="file" @change="handleFileGroup" class="border p-2 w-full mb-4" />
      <textarea v-model="currentGroup.address" class="border p-2 w-full mb-4" placeholder="ที่อยู่หน่วยงาน"></textarea>
      <img v-if="imagePreview" :src="imagePreview" class="mb-4 max-w-full h-auto max-h-48"/>
      <button @click="saveGroup" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">บันทึก</button>
      <button @click="closeAddGroupModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
    </div>
  </div>
<!-- Positions Section -->
<div v-if="activeSection === 'positions'">
  <div v-if="loadingPositions" class="loading">Loading positions...</div>
  <div v-else class="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
    <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
      <thead>
        <tr class="text-center">
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">ตำแหน่ง</th>
          <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">การจัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="position in positions" :key="position.position_id">
          <td>{{ position.position_name }}</td>
          <td>
            <!-- ลบการใช้งานฟังก์ชัน configurePosition -->
            <button class="text-blue-500 hover:underline" @click="editPosition">ตั้งค่า</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


    </div>
  </div>

 <!-- Add User Modal -->

  <!-- Add User Modal -->
  <div v-if="showAddUserModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-start justify-center pt-10">
    <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl mx-4 md:mx-0">
      <div class="px-6 py-4">
        <h3 class="text-lg font-semibold text-center mb-4">เพิ่มผู้ใช้งาน</h3>
        <form @submit.prevent="createUser" class="space-y-4">
          <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="prefixname">คำนำหน้า</label>
              <select id="prefixname" v-model="newUser.prefixname" class="w-full p-2 border rounded">
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
              </select>
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="fname">ชื่อ</label>
              <input type="text" id="fname" v-model="newUser.fname" class="w-full p-2 border rounded" required>
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="lname">นามสกุล</label>
              <input type="text" id="lname" v-model="newUser.lname" class="w-full p-2 border rounded" required>
            </div>
          </div>
          <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="department">แผนก</label>
              <input type="text" id="department" v-model="newUser.department" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="position_lo">ตำแหน่ง</label>
              <input type="text" id="position_lo" v-model="newUser.position_lo" class="w-full p-2 border rounded">
            </div>
          </div>
          <div class="flex flex-wrap -mx-2">
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">Username</label>
              <input type="text" id="username" v-model="newUser.username" class="w-full p-2 border rounded" required>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="password_hash">Password</label>
              <input type="password" id="password_hash" v-model="newUser.password_hash" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="position_id">บทบาท</label>
              <select id="position_id" v-model="newUser.position" class="w-full p-2 border rounded">
                <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
              </select>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="phone_number">เบอร์ติดต่อ</label>
              <input type="text" id="phone_number" v-model="newUser.phone_number" class="w-full p-2 border rounded">
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="agency">หน่วยงาน</label>
              <select id="agency" v-model="newUser.agency" class="w-full p-2 border rounded">
                <option v-for="agency in agencies" :key="agency.id" :value="agency.id">{{ agency.name }}</option>
              </select>
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="email">อีเมล์</label>
              <input type="email" id="email" v-model="newUser.email" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="image">รูปภาพ</label>
              <input type="file" id="image" @change="handleFileChange" class="w-full p-2 border rounded">
            </div>
          </div>
          <div class="text-center">
            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">บันทึก</button>
            <button type="button" @click="showAddUserModal = false" class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Edit User Modal -->
  <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-start justify-center pt-10" @click.self="showModal = false">
    <div class="bg-white rounded-lg shadow-lg w-full max-w-2xl mx-4 md:mx-0">
      <div class="px-6 py-4">
        <h3 class="text-lg font-semibold text-center mb-4">แก้ไขโปรไฟล์</h3>
        <form @submit.prevent="updateUser" class="space-y-4">
          <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="prefixname">คำนำหน้า</label>
              <select id="prefixname" v-model="currentUser.prefixname" class="w-full p-2 border rounded">
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
              </select>
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="fname">ชื่อ</label>
              <input type="text" id="fname" v-model="currentUser.fname" class="w-full p-2 border rounded" required>
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="lname">นามสกุล</label>
              <input type="text" id="lname" v-model="currentUser.lname" class="w-full p-2 border rounded" required>
            </div>
          </div>
          <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="department">แผนก</label>
              <input type="text" id="department" v-model="currentUser.department" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="position_lo">ตำแหน่ง</label>
              <input type="text" id="position_lo" v-model="currentUser.position_lo" class="w-full p-2 border rounded">
            </div>
          </div>
          <div class="flex flex-wrap -mx-2">
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">Username</label>
              <input type="text" id="username" v-model="currentUser.username" class="w-full p-2 border rounded" required>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="password_hash">Password</label>
              <input type="password" id="password_hash" v-model="currentUser.password_hash" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="position_id">บทบาท</label>
              <select id="position_id" v-model="currentUser.position" class="w-full p-2 border rounded">
                <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
              </select>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="phone_number">เบอร์ติดต่อ</label>
              <input type="text" id="phone_number" v-model="currentUser.phone_number" class="w-full p-2 border rounded">
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="agency">หน่วยงาน</label>
              <select id="agency" v-model="currentUser.agency" class="w-full p-2 border rounded">
                <option v-for="agency in agencies" :key="agency.id" :value="agency.id">{{ agency.name }}</option>
              </select>
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="email">อีเมล์</label>
              <input type="email" id="email" v-model="currentUser.email" class="w-full p-2 border rounded">
            </div>
            <div class="w-full md:w-1/2 px-2 mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="image">รูปภาพ</label>
              <input type="file" id="image" @change="handleFileUpload" class="w-full p-2 border rounded">
            </div>

               <div class="w-full md:w-1/2 px-2 mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="signature">ลายเซ็น</label>
          <input type="file" id="signature" @change="handleSignatureUpload" accept=".jpg,.png" class="w-full p-2 border rounded">
        </div>

          </div>
          <div class="text-center">
            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">บันทึก</button>
            <button type="button" @click="showModal = false" class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      showAddUserModal: false,
      showAddGroupModal: false,
      signatureFile: null, // สำหรับเก็บไฟล์ลายเซ็น
      isEditing: false,
      showEditGroupModal: false,
      showImageModal: false, // สำหรับแสดง modal รูปภาพ
      imageToShow: null, // สำหรับเก็บ URL ของรูปภาพที่จะแสดง
      currentUser: {
        prefixname: '',
        fname: '',
        lname: '',
        department: '',
        position_lo: '',
        username: '',
        password_hash: '',
        position: '',
        phone_number: '',
        agency: '',
        email: '',
        image: null // สำหรับเก็บไฟล์รูปภาพ
      },
      newUser: {
        prefixname: '',
        fname: '',
        lname: '',
        department: '',
        position_lo: '',
        username: '',
        password_hash: '',
        position: '',
        phone_number: '',
        agency: '',
        email: '',
        image: null // สำหรับเก็บไฟล์รูปภาพ
      },
      currentGroup: {
        group_id: '',
        name: '',
        address: '', // เพิ่มฟิลด์ address
        details:'',
      },
      newGroup: {
        name: ''
      },
      selectedFile: null,
      roles: [], // บทบาททั้งหมดที่มี
      agencies: [], // หน่วยงานทั้งหมดที่มี
      groups: [], // กลุ่มผู้ใช้ทั้งหมดที่มี
      positions: [], // ตำแหน่งทั้งหมดที่มี
      activeSection: 'profile', // ค่าเริ่มต้นของ section ที่แสดง
      imagePreview: null, // สำหรับเก็บ URL ของรูปภาพพรีวิว
      loading: false, // สำหรับเก็บสถานะการโหลดข้อมูลผู้ใช้
      loadingGroups: false, // สำหรับเก็บสถานะการโหลดข้อมูลกลุ่มผู้ใช้
      loadingPositions: false, // สำหรับเก็บสถานะการโหลดข้อมูลตำแหน่ง
      users: [] // เก็บรายชื่อผู้ใช้
    };
  },
  methods: {
    async fetchRolesAndAgencies() {
      try {
        const rolesResponse = await fetch('https://brk-group.org/api/roles');
        this.roles = await rolesResponse.json();
        
        const agenciesResponse = await fetch('https://brk-group.org/api/agencies');
        this.agencies = await agenciesResponse.json();
      } catch (error) {
        console.error('Error fetching roles and agencies:', error);
      }
    },
    async fetchPositions() {
      try {
        this.loadingPositions = true;
        const response = await fetch('https://brk-group.org/api/positions');
        this.positions = await response.json();
      } catch (error) {
        console.error('Error fetching positions:', error);
      } finally {
        this.loadingPositions = false;
      }
    },
    async fetchGroups() {
      try {
        this.loadingGroups = true;
        const response = await fetch('https://brk-group.org/api/groups');
        this.groups = await response.json();
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        this.loadingGroups = false;
      }
    },
    getRoleName(roleId) {
      const role = this.roles.find(r => r.id === roleId);
      return role ? role.name : 'Unknown';
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.currentUser.image = file;
      this.imagePreview = URL.createObjectURL(file);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.newUser.image = file;
      this.imagePreview = URL.createObjectURL(file);
    },
    handleFileGroup(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.imagePreview = URL.createObjectURL(file);
    },
    handleSignatureUpload(event) {
    const file = event.target.files[0];
    if (file) {
      this.signatureFile = file;
    }
  },
    async createUser() {
      // ตรวจสอบข้อมูลที่จำเป็นก่อนส่งคำขอ
      const requiredFields = ['username', 'password_hash', 'position', 'prefixname', 'fname', 'lname', 'department', 'position_lo', 'agency', 'email', 'phone_number'];
      for (const field of requiredFields) {
        if (!this.newUser[field]) {
          alert(`กรุณากรอกข้อมูล ${field}`);
          return;
        }
      }

      try {
        const formData = new FormData();
        formData.append('username', this.newUser.username);
        formData.append('password_hash', this.newUser.password_hash);
        formData.append('position_id', this.newUser.position);
        formData.append('prefixname', this.newUser.prefixname);
        formData.append('fname', this.newUser.fname);
        formData.append('lname', this.newUser.lname);
        formData.append('department', this.newUser.department);
        formData.append('position_lo', this.newUser.position_lo);
        formData.append('agency', this.newUser.agency);
        formData.append('email', this.newUser.email);
        formData.append('phone_number', this.newUser.phone_number);
        if (this.newUser.image) {
          formData.append('image', this.newUser.image);
        }

        const response = await fetch('https://brk-group.org/api/users', { // แก้ไข URL ตรงนี้
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to create user');
        }

        const data = await response.json();
        alert(data.message);
        this.showAddUserModal = false;
        this.fetchUsers();
      } catch (error) {
        console.error('Error creating user:', error);
        alert(`เกิดข้อผิดพลาดในการเพิ่มผู้ใช้: ${error.message}`);
      }
    },
    async updateUser() {
      // แปลงค่า position ให้เป็น id ก่อนส่งคำขอ
      const selectedRole = this.roles.find(role => role.id === this.currentUser.position);
      if (!selectedRole) {
        alert('กรุณาเลือกบทบาทที่ถูกต้อง');
        return;
      }

      try {
        const formData = new FormData();
        formData.append('username', this.currentUser.username);
        formData.append('password_hash', this.currentUser.password_hash);
        formData.append('position_id', selectedRole.id); // ใช้ id แทนตำแหน่ง
        formData.append('prefixname', this.currentUser.prefixname);
        formData.append('fname', this.currentUser.fname);
        formData.append('lname', this.currentUser.lname);
        formData.append('department', this.currentUser.department);
        formData.append('position_lo', this.currentUser.position_lo);
        formData.append('agency', this.currentUser.agency);
        formData.append('email', this.currentUser.email);
        formData.append('phone_number', this.currentUser.phone_number);
        if (this.currentUser.image) {
          formData.append('image', this.currentUser.image);
        }
 // เพิ่มการอัปโหลดไฟล์ลายเซ็น
      if (this.signatureFile) {
        formData.append('signature', this.signatureFile);
      }

        const response = await fetch('https://brk-group.org/api/users', {
          method: 'PUT',
          body: formData
        });

        if (!response.ok) {
          throw new Error('Failed to update user');
        }

        const data = await response.json();
        alert(data.message);
        this.showModal = false;
        this.fetchUsers();
      } catch (error) {
        console.error('Error updating user:', error);
        alert('เกิดข้อผิดพลาดในการอัพเดทข้อมูลผู้ใช้');
      }
    },
    async saveGroup() {
      // ตรวจสอบข้อมูลที่จำเป็นก่อนส่งคำขอ
      if (!this.currentGroup.name) {
        alert('กรุณากรอกชื่อกลุ่มผู้ใช้');
        return;
      }

      const formData = new FormData();
      formData.append('name', this.currentGroup.name);
      formData.append('group_address', this.currentGroup.address);
      formData.append('group_details', this.currentGroup.details);
      if (this.selectedFile) {
        formData.append('image', this.selectedFile);
      }

      try {
        const url = this.isEditing 
          ? `https://brk-group.org/api/groups/${this.currentGroup.id}`
          : 'https://brk-group.org/api/groups';

        const method = this.isEditing ? 'PUT' : 'POST';

        const response = await fetch(url, {
          method: method,
          body: formData
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save group');
        }

        const data = await response.json();
        alert(data.message);
        this.showGroupModal = false;
        this.fetchGroups();
        this.closeAddGroupModal();
      } catch (error) {
        console.error('Error saving group:', error);
        alert(`เกิดข้อผิดพลาดในการบันทึกกลุ่มผู้ใช้: ${error.message}`);
      }
    },
    editGroup(group) {
      this.currentGroup = {
        id: group.group_id,
        name: group.group_name,
        address: group.group_address, // โหลดค่า address
        details: group.group_details
      };
      this.selectedFile = null;
      this.imagePreview = group.image_url || null;
      this.isEditing = true;
      this.showAddGroupModal = true;
    },
    async deleteGroup(groupId) {
      if (!confirm('คุณแน่ใจว่าต้องการลบกลุ่มผู้ใช้นี้?')) {
        return;
      }

      try {
        const response = await fetch(`https://brk-group.org/api/groups/${groupId}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete group');
        }

        alert('กลุ่มผู้ใช้ถูกลบสำเร็จ');
        this.fetchGroups();
        this.closeAddGroupModal();
      } catch (error) {
        console.error('Error deleting group:', error);
        alert(`เกิดข้อผิดพลาดในการลบกลุ่มผู้ใช้: ${error.message}`);
      }
    },
    async fetchUsers() {
      try {
        this.loading = true;
        const response = await fetch('https://brk-group.org/api/users');
        this.users = await response.json();
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;
      }
    },
    editUser(user) {
      this.currentUser = { ...user };
      this.showModal = true;
    },
    editPosition(position) {
    // เพิ่มฟังก์ชัน editPosition ถ้ามีการใช้งาน
    console.log('Edit Position:', position);
  },
  showImage(imageUrl) {
      this.imageToShow = imageUrl;
      this.showImageModal = true;
    },
    setActiveSection(section) {
      this.activeSection = section;
      if (section === 'positions') {
        this.fetchPositions(); // ดึงข้อมูลตำแหน่งเมื่อเลือก section positions
      }
    },
    showAddUserForm() {
      this.newUser = {
        prefixname: '',
        fname: '',
        lname: '',
        department: '',
        position_lo: '',
        username: '',
        password_hash: '',
        position: '',
        phone_number: '',
        agency: '',
        email: '',
        image: null
      };
      this.imagePreview = null;
      this.showAddUserModal = true;
    },
    showAddGroupForm() {
      this.currentGroup = {
        id: null,
        group_id: '',
        name: '',
        address: '',
        details: '',
      };
      this.selectedFile = null;
      this.isEditing = false;
      this.showAddGroupModal = true;
    },
    
    closeAddGroupModal() {
      this.newGroup = {
        id: null,
        name: '',
        address: '', // รีเซ็ตฟิลด์ address
        details: '',
      };
      this.showAddGroupModal = false;
    },
 
  },
  created() {
    this.fetchRolesAndAgencies(); // ดึงข้อมูลบทบาทและหน่วยงานเมื่อตัว component ถูกสร้างขึ้น
    this.fetchUsers(); // ดึงรายชื่อผู้ใช้เมื่อตัว component ถูกสร้างขึ้น
    this.fetchGroups(); // ดึงข้อมูลกลุ่มผู้ใช้เมื่อตัว component ถูกสร้างขึ้น
  }
};
</script>

<style scoped>
/* CSS ที่ใช้สำหรับตกแต่ง modal */
.loading {
  text-align: center;
  margin: 20px 0;
}

.table-auto {
  width: 100%;
}

.w-10 {
  width: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}

.rounded-full {
  border-radius: 9999px;
}
</style>
