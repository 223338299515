<template>
  <nav class="p-4 w-full">
    <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <li v-for="menu in menus" :key="menu.title" class="flex items-center justify-center" :class="menu.class">
        <router-link :to="menu.route" class="w-full">
          <div class="flex flex-col items-center p-4 border rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-red">
            <component :is="getIconComponent(menu.icon)" class="h-8 w-8 mb-2 text-gray-700"></component>
            <span class="text-center font-semibold text-gray-800">{{ menu.title }}</span>
          </div>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {
  DocumentTextIcon,
  ArchiveIcon,
  ShoppingCartIcon,
  TagIcon,
  CubeIcon,
  ChartBarIcon,
  ClipboardListIcon,
  UserGroupIcon,
  PaperClipIcon,
  IdentificationIcon,
  ClipboardCheckIcon,
  ClockIcon,
  PresentationChartLineIcon,
  PresentationChartBarIcon,
  ChartPieIcon,
  DocumentAddIcon,
  CheckCircleIcon,
  ClipboardCopyIcon
} from '@heroicons/vue/outline';

export default {
  
  props: {
    menus: {
      type: Array,
      required: true,
      
    },
    
  },
  
  components: {
    DocumentTextIcon,
    ArchiveIcon,
    ShoppingCartIcon,
    TagIcon,
    CubeIcon,
    ChartBarIcon,
    ClipboardListIcon,
    UserGroupIcon,
    PaperClipIcon,
    IdentificationIcon,
    ClipboardCheckIcon,
    ClockIcon,
    PresentationChartLineIcon,
    PresentationChartBarIcon,
    ChartPieIcon,
    DocumentAddIcon,
    CheckCircleIcon,
    ClipboardCopyIcon
  },
  methods: {
    getIconComponent(iconName) {
      const iconComponents = {
        'document-text': DocumentTextIcon,
        'archive': ArchiveIcon,
        'shopping-cart': ShoppingCartIcon,
        'DocumentAddIcon' : DocumentAddIcon,
        'tags': TagIcon,
        'box': CubeIcon,
        'chart-bar': ChartBarIcon,
        'clipboard-list': ClipboardListIcon,
        'user-group': UserGroupIcon, // คู่ค้า
        'paper-clip': PaperClipIcon, // ใบขอซื้อ
        'identification': IdentificationIcon, // ประเภทคู่ค้า
        'clipboard-check': ClipboardCheckIcon, // ประวัติซ่อม
        'clock': ClockIcon, // รายการใบPOด่วน
        'chart-1': PresentationChartBarIcon,
        'chart-2': ChartPieIcon,
        'check-circle': CheckCircleIcon,
        'list-alt': ClipboardCopyIcon
      };
      return iconComponents[iconName] || DocumentTextIcon; // Default icon
    }
  }
};
</script>

<!-- 
<style scoped>
nav {
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-align: left;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.75;
}

/* ปรับแต่งสีของแต่ละเมนู */
.supplier-menu {
  background-color: #ffcccc;
}
.pr-menu {
  background-color: #ccffcc;
}
.pre-menu {
  background-color: #ccccff;
}
.supplier-manage-menu {
  background-color: #ffcc99;
}
.product-menu {
  background-color: #ff99cc;
}
.product-type-menu {
  background-color: #99ccff;
}
.repair-history-menu {
  background-color: #cc99ff;
}
.approve-po-menu {
  background-color: #ffff99;
}
.po-list-menu {
  background-color: #99ff99;
}
</style> -->
