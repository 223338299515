<template>
  <TransportLayout>
    <div class="container mx-auto px-4 py-6">
      <h2 class="text-2xl font-bold mb-6 text-gray-800">ตั้งค่าประเภทสินค้า</h2>

      <div class="mb-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center">
        <div class="w-full md:flex-grow">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="ค้นหาประเภทสินค้า..."
            class="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
          />
        </div>
        <div>
          <button 
            @click="deleteSelectedProductTypes" 
            :disabled="isLoading || selectedProductTypes.length === 0"
            class="w-full md:w-auto bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
          >
            <i class="fas fa-trash-alt mr-2" v-if="selectedProductTypes.length > 0"></i> ลบประเภทสินค้าที่เลือก
          </button>
        </div>
        <div>
          <button 
            @click="showAddModal" 
            :disabled="isLoading"
            class="w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
          >
            <i class="fas fa-plus mr-2"></i> เพิ่มประเภทสินค้า
          </button>
        </div>
      </div>

      <!-- Loading Spinner -->
      <div v-if="isLoading" class="flex justify-center items-center py-6">
        <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
      </div>

      <!-- Product Types Table -->
      <div v-else-if="productTypes.length" class="overflow-x-auto rounded-lg shadow-md">
        <table class="w-full bg-white">
          <thead class="bg-gray-100">
  <tr>
    <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
      <input type="checkbox" @change="toggleSelectAll" :checked="isAllSelected" />
    </th>
    <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
    <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อประเภทสินค้า</th>
    <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">การดำเนินการ</th>
  </tr>
</thead>
          <tbody class="divide-y divide-gray-200">
            <tr 
              v-for="(productType, index) in paginatedProductTypes" 
              :key="productType.id" 
              class="hover:bg-gray-50 transition duration-150"
            >
            <td class="px-4 py-4 whitespace-nowrap">
                <input type="checkbox" 
                       v-model="selectedProductTypes" 
                       :value="productType.id" />
              </td>
            <td class="px-4 py-4 whitespace-nowrap">{{ index + 1 }}</td>
              <td class="px-4 py-4 whitespace-nowrap">{{ productType.name }}</td>
              <td class="px-4 py-4 whitespace-nowrap text-center">
                <div class="flex justify-center space-x-2">
                  <button 
                    @click="editProductType(productType)" 
                    :disabled="isLoading"
                    class="text-blue-500 hover:text-blue-600 transition duration-300 transform hover:scale-110 disabled:opacity-50"
                  >
                  <i class="fas fa-edit"></i> 
                  <!-- ✏️ แก้ไข  -->
                  </button>
                  <button 
                    @click="confirmDeleteProductType(productType.id)" 
                    :disabled="isLoading"
                    class="text-red-500 hover:text-red-600 transition duration-300 transform hover:scale-110 disabled:opacity-50"
                  >
                  <i class="fas fa-trash"></i>
                    <!-- 🗑️ ลบ -->
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>



      
      <!-- No Data State -->
      <div v-else class="text-center py-6 bg-gray-100 rounded-lg">
        <p class="text-gray-600">ไม่มีข้อมูลประเภทสินค้า</p>
      </div>
      <div class="flex justify-between items-center mt-4">
  <button
    @click="currentPage--"
    :disabled="currentPage === 1"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ก่อนหน้า
  </button>
  <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
  <button
    @click="currentPage++"
    :disabled="currentPage === totalPages"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ถัดไป
  </button>
</div>
      <!-- Modal สำหรับเพิ่มหรือแก้ไขประเภทสินค้า -->
      <div 
        v-if="isModalVisible" 
        class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      >
        <div class="relative w-auto max-w-3xl mx-auto my-6">
          <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
            <div class="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
              <h3 class="text-xl font-semibold">
                {{ isEditing ? 'แก้ไขประเภทสินค้า' : 'เพิ่มประเภทสินค้า' }}
              </h3>
              <button 
                @click="closeModal" 
                class="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
              >
                <span class="block w-6 h-6 text-2xl text-black opacity-5">×</span>
              </button>
            </div>
            <div class="relative flex-auto p-6">
              <form @submit.prevent="submitProductType">
                <div class="grid grid-cols-1 gap-4">
                  <div>
                    <label class="block mb-2 text-sm font-bold text-gray-700">ชื่อประเภทสินค้า</label>
                    <input 
                      type="text" 
                      v-model="productType.name" 
                      class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                      required 
                      placeholder="กรอกชื่อประเภทสินค้า"
                      :disabled="isLoading"
                    />
                  </div>
                </div>
                <div class="flex justify-end mt-6 space-x-4">
                  <button 
                    type="button" 
                    @click="closeModal" 
                    :disabled="isLoading"
                    class="px-6 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition duration-300 disabled:opacity-50"
                  >
                    ยกเลิก
                  </button>
                  <button 
                    type="submit" 
                    :disabled="isLoading"
                    class="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50"
                  >
                    {{ isLoading ? 'กำลังบันทึก...' : 'บันทึก' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Overlay for modal -->
      <div 
        v-if="isModalVisible" 
        class="fixed inset-0 z-40 bg-black opacity-25"
      ></div>
    </div>
  </TransportLayout>
</template>

<script>
import TransportLayout from '@/components/transport/TransportSidebar.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  components: {
    TransportLayout,
  },
  data() {
    return {
      productTypes: [],
      productType: {
        name: '',
      },
      searchQuery: '',
      isModalVisible: false,
      isEditing: false,
      editingProductTypeId: null,
      isLoading: false,
      selectedProductTypes: [],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    filteredProductTypes() {
      return this.productTypes.filter(productType =>
        productType.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedProductTypes() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredProductTypes.slice(start, start + this.itemsPerPage);
  },
    totalPages() {
    return Math.ceil(this.filteredProductTypes.length / this.itemsPerPage);
  },
    isAllSelected() {
      return this.selectedProductTypes.length === this.filteredProductTypes.length;
    },
  },
  methods: {
    changePage(page) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
  },
    async fetchProductTypes() {
      this.isLoading = true;
      try {
        const response = await axios.get('https://brk-group.org/api/ts/product-types');
        this.productTypes = response.data;
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการโหลดข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },
    showAddModal() {
      this.resetForm();
      this.isModalVisible = true;
      this.isEditing = false;
    },
    editProductType(productType) {
      this.productType = { 
        name: productType.name 
      };
      this.isModalVisible = true;
      this.isEditing = true;
      this.editingProductTypeId = productType.id;
    },
    closeModal() {
      this.isModalVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.productType = { name: '' };
      this.editingProductTypeId = null;
    },
    async submitProductType() {
      this.isLoading = true;
      try {
        if (this.isEditing) {
          // Update existing product type
          await axios.put(
            `https://brk-group.org/api/ts/product-types/${this.editingProductTypeId}`, 
            this.productType
          );
          this.showSuccessAlert('แก้ไขประเภทสินค้าสำเร็จ');
        } else {
          // Create new product type
          await axios.post(
            'https://brk-group.org/api/ts/product-types', 
            this.productType
          );
          this.showSuccessAlert('เพิ่มประเภทสินค้าสำเร็จ');
        }
        
        // Refresh the list
        await this.fetchProductTypes();
        this.closeModal();
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการบันทึกข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },
    async confirmDeleteProductType(id) {
      try {
        // Show confirmation dialog
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณต้องการลบประเภทสินค้านี้หรือไม่?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ลบเลย',
          cancelButtonText: 'ยกเลิก'
        });

        // If confirmed, proceed with deletion
        if (result.isConfirmed) {
          this.isLoading = true;
          await axios.delete(`https://brk-group.org/api/ts/product-types/${id}`);
          
          // Refresh product types list
          await this.fetchProductTypes();
          
          // Show success message
          this.showSuccessAlert('ลบประเภทสินค้าสำเร็จ');
        }
      } catch (error) {
        // Handle specific error scenarios
        if (error.response && error.response.status === 400) {
          // This catches the case where the product type is in use
          this.showErrorAlert(
            'ไม่สามารถลบได้', 
            'ประเภทสินค้านี้กำลังถูกใช้งานอยู่'
          );
        } else {
          this.showErrorAlert('เกิดข้อผิดพลาดในการลบข้อมูล', error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSelectedProductTypes() {
      try {
        const result = await Swal.fire({
          title: 'ยืนยันการลบ',
          text: 'คุณต้องการลบประเภทสินค้าที่เลือกหรือไม่?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ลบเลย',
          cancelButtonText: 'ยกเลิก'
        });

        if (result.isConfirmed) {
          this.isLoading = true;
          await axios.delete('https://brk-group.org/api/ts/product-types', {
            data: { ids: this.selectedProductTypes }
          });
          await this.fetchProductTypes();
          this.showSuccessAlert('ลบประเภทสินค้าสำเร็จ');
          this.selectedProductTypes = [];
        }
      } catch (error) {
        this.showErrorAlert('เกิดข้อผิดพลาดในการลบข้อมูล', error);
      } finally {
        this.isLoading = false;
      }
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedProductTypes = [];
      } else {
        this.selectedProductTypes = this.filteredProductTypes.map(productType => productType.id);
      }
    },
    // Utility methods for alerts
    showSuccessAlert(message) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: message,
        timer: 2000,
        showConfirmButton: false
      });
    },
    showErrorAlert(title, error) {
      // Extract error message
      const errorMessage = error.response?.data?.error || 
                           error.message || 
                           'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ';

      Swal.fire({
        icon: 'error',
        title: title,
        text: errorMessage,
        confirmButtonText: 'ตกลง'
      });

      // Log the full error for debugging
      console.error(title, error);
    }
  },
  mounted() {
    this.fetchProductTypes();
  },
};
</script>

<style scoped>
/* Additional custom styles can be added here if needed */
</style>