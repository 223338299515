<template>
  <div class="max-w-4xl mx-auto px-4 py-8 bg-gray-50 shadow-lg rounded-lg">
    <h2 class="text-3xl font-bold text-gray-800 mb-8 text-center">แก้ไขรายละเอียดคู่ค้า</h2>
    
    <div v-if="supplier" class="bg-white p-6 rounded-lg shadow-sm">
      <form @submit.prevent="updateSupplier" class="space-y-8">
        
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <!-- Company Name -->
          <div>
            <label for="company_name" class="block text-sm font-semibold text-gray-700 mb-1">ชื่อบริษัท</label>
            <input type="text" id="company_name" v-model="supplier.company_name" required class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Tax ID -->
          <div>
            <label for="tax_id" class="block text-sm font-semibold text-gray-700 mb-1">หมายเลขภาษีอากร</label>
            <input type="text" id="tax_id" v-model="supplier.tax_id" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Address -->
          <div class="lg:col-span-2">
            <label for="address" class="block text-sm font-semibold text-gray-700 mb-1">ที่อยู่</label>
            <textarea id="address" v-model="supplier.address" rows="3" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"></textarea>
          </div>

          <!-- Phone -->
          <div>
            <label for="phone" class="block text-sm font-semibold text-gray-700 mb-1">โทรศัพท์</label>
            <input type="text" id="phone" v-model="supplier.phone" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Contact Name -->
          <div>
            <label for="contact_name" class="block text-sm font-semibold text-gray-700 mb-1">ชื่อผู้ติดต่อ</label>
            <input type="text" id="contact_name" v-model="supplier.contact_name" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Contact Email -->
          <div>
            <label for="contact_email" class="block text-sm font-semibold text-gray-700 mb-1">อีเมลผู้ติดต่อ</label>
            <input type="email" id="contact_email" v-model="supplier.contact_email" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Website -->
          <div>
            <label for="website" class="block text-sm font-semibold text-gray-700 mb-1">เว็บไซต์</label>
            <input type="text" id="website" v-model="supplier.website" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Payment Info -->
          <div class="lg:col-span-2">
            <label for="payment_info" class="block text-sm font-semibold text-gray-700 mb-1">ข้อมูลการชำระเงิน</label>
            <textarea id="payment_info" v-model="supplier.payment_info" rows="3" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"></textarea>
          </div>

          <!-- Support Info -->
          <div class="lg:col-span-2">
            <label for="support_info" class="block text-sm font-semibold text-gray-700 mb-1">ข้อมูลการสนับสนุน</label>
            <textarea id="support_info" v-model="supplier.support_info" rows="3" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"></textarea>
          </div>

          <!-- Supplier Type -->
          <div>
            <label for="type_id" class="block text-sm font-semibold text-gray-700 mb-1">ประเภทคู่ค้า</label>
            <select id="type_id" v-model="supplier.type_id" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent">
              <option v-for="type in supplierTypes" :key="type.type_id" :value="type.type_id">
                {{ type.type_name }}
              </option>
            </select>
          </div>

          <!-- Position -->
          <div>
            <label for="position" class="block text-sm font-semibold text-gray-700 mb-1">ตำแหน่ง</label>
            <textarea id="position" v-model="supplier.position" rows="3" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"></textarea>
          </div>

          <!-- Contract Info -->
          <div class="lg:col-span-2">
            <label for="contract_info" class="block text-sm font-semibold text-gray-700 mb-1">ข้อมูลสัญญาหรือข้อตกลง</label>
            <textarea id="contract_info" v-model="supplier.contract_info" rows="3" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"></textarea>
          </div>

          <!-- Shipping Info -->
          <div>
            <label for="shipping_info" class="block text-sm font-semibold text-gray-700 mb-1">ข้อมูลการจัดส่ง</label>
            <input type="text" id="shipping_info" v-model="supplier.shipping_info" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Credit -->
          <div>
            <label for="credit" class="block text-sm font-semibold text-gray-700 mb-1">เครดิต (วัน)</label>
            <input type="text" id="credit" v-model="supplier.credit" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>

          <!-- Product/Service Details -->
          <div>
            <label for="detail_product" class="block text-sm font-semibold text-gray-700 mb-1">รายละเอียดสินค้าหรือบริการ</label>
            <input type="text" id="detail_product" v-model="supplier.detail_product" class="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
          </div>
        </div>

        <div class="flex justify-end space-x-4">
          <button type="submit" class="px-6 py-2 font-semibold text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-sm transition duration-300">
            อัปเดต
          </button>
          <router-link to="/suppliers" class="px-6 py-2 font-semibold text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-md shadow-sm transition duration-300">
            ยกเลิก
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      supplier: {
      company_name: '',
        tax_id: '',
        address: '',
        phone: '',
        contact_name: '',
        contact_email: '',
        website: '',
        payment_info: '',
        support_info: '',
        position: '',
        contract_info: '',
        shipping_info: '',
        credit: '',
        detail_product: '',

      },
      supplierTypes: [], // Initialize supplier as null, to be filled once data is fetched
    };
  },
  async created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
    }
    await this.fetchSupplierDetails();
    await this.fetchSupplierTypes();
  },
  methods: {
    async fetchSupplierDetails() {
      try {
        const response = await axios.get(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`);
        this.supplier = response.data;
      } catch (error) {
        console.error(error);
        // Handle error or show notification
      }
    },
    async fetchSupplierTypes() {
      try {
        const response = await axios.get('https://brk-group.org/api/supplier-types');
        this.supplierTypes = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateSupplier() {
  try {
    await axios.put(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`, this.supplier);
    Swal.fire({
      icon: 'success',
      title: 'สำเร็จ',
      text: 'ข้อมูลคู่ค้าได้รับการอัปเดตเรียบร้อยแล้ว',
    }).then(() => {
      this.$router.push({ name: 'suppliers' });
    });
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'ผิดพลาด',
      text: 'เกิดข้อผิดพลาดในการอัปเดตข้อมูลคู่ค้า',
    });
  }
}

  }
};

//ของเก่า
// import axios from 'axios';

// export default {
//   data() {
//     return {
//       supplier: {
//         company_name: '',
//         tax_id: '',
//         address: '',
//         phone: '',
//         contact_name: '',
//         contact_email: '',
//         website: ''
//       }
//     };
//   },
//   async created() {
//     await this.fetchSupplierDetails();
//   },
//   methods: {
//     async fetchSupplierDetails() {
//       try {
//         const response = await axios.get(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`);
//         this.supplier = response.data;
//       } catch (error) {
//         console.error(error);
//         // Handle error or show notification
//       }
//     },
//     async updateSupplier() {
//       try {
//         await axios.put(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`, this.supplier);
//         alert('Supplier updated successfully');
//         this.$router.push({ name: 'suppliers' });
//       } catch (error) {
//         console.error(error);
//         alert('An error occurred while updating the supplier.');
//       }
//     }
//   }
// };
</script>
<style scoped>
@media (max-width: 640px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.container {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}
input, textarea, select {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;
}
input:focus, textarea:focus, select:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
</style>