<template>
    <div class="container mx-auto mt-10">
        <div class="bg-blue-600 p-4 text-white">
          <h3 class="text-lg font-semibold mb-0">รายงานสถานะใบขอซื้อ</h3>
        </div>
      <!-- แผนภูมิ -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">สถานะใบขอซื้อ</h4>
          <div ref="statusChart" style="height: 400px;"></div>
          <p v-if="!statusChartData">Loading...</p>
        </div>
        <div class="bg-white shadow-md rounded-lg p-6">
          <h4 class="text-lg font-semibold mb-4">ยอดรวมตามผู้ขอซื้อ</h4>
          <div ref="creatorChart" style="height: 400px;"></div>
          <p v-if="!creatorChartData">Loading...</p>
        </div>
      </div>
  
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
  <!-- การ์ดสรุปข้อมูล -->
  <div class="bg-blue-500 text-white p-6 rounded-lg shadow-lg flex items-center justify-between">
    <component :is="statusIcons['ใบขอซื้อทั้งหมด']" class="h-8 w-8 flex-shrink-0 text-white opacity-90" />
    <div class="flex flex-col items-end">
      <h4 class="text-base font-medium text-white opacity-90">ใบขอซื้อทั้งหมด</h4>
      <p class="text-3xl font-bold">{{ requisitions.length }}</p>
    </div>
  </div>
  <div class="bg-indigo-500 text-white p-6 rounded-lg shadow-lg flex items-center justify-between">
    <component :is="statusIcons['ผู้ขอซื้อทั้งหมด']" class="h-8 w-8 flex-shrink-0 text-white opacity-90" />
    <div class="flex flex-col items-end">
      <h4 class="text-base font-medium text-white opacity-90">ผู้ขอซื้อทั้งหมด</h4>
      <p class="text-3xl font-bold">{{ creatorsCount }}</p>
    </div>
  </div>

  <!-- การ์ดแสดงสถานะแต่ละประเภทตามลำดับที่กำหนด -->
  <div 
    v-for="item in statusCounts" 
    :key="item.status" 
    :class="`${statusColors[item.status] || 'bg-gray-500'} text-white p-6 rounded-lg shadow-lg flex items-center justify-between transition transform hover:scale-105`"
  >
    <component :is="statusIcons[item.status]" class="h-8 w-8 flex-shrink-0 text-white opacity-90" />
    <div class="flex flex-col items-end">
      <h4 class="text-base font-medium text-white opacity-90">{{ item.status }}</h4>
      <p class="text-3xl font-bold">{{ item.count }}</p>
    </div>
  </div>


      </div>
  
      <!-- ตารางข้อมูลใบขอซื้อ -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
       
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div>
              <input 
                type="text" 
                v-model="search" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="ค้นหาจากเลขที่ใบสั่งซื้อหรือชื่อผู้สร้าง" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateFrom" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="From date" 
              />
            </div>
            <div>
              <input 
                type="date" 
                v-model="dateTo" 
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="To date" 
              />
            </div>
            <div>
                <select 
      v-model="selectedStatus" 
      class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option value="">ทุกสถานะ</option>
      <option v-for="status in statusOptions" :key="status.status_pr" :value="status.status_pr">
        {{ status.status_pr }}
      </option>
    </select>
</div>
            <div class="text-right">
              <button 
                class="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                @click="fetchData"
              >
                ค้นหา
              </button>
            </div>
          </div>
  
          <div class="overflow-x-auto">
            <table class="min-w-full bg-white border border-gray-300 rounded-lg">
              <thead>
                <tr class="bg-gray-800 text-white">
                  <th class="py-2 px-4 border-b border-gray-200">เลขที่ใบขอซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">วันที่สร้างใบขอซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">ผู้สร้าง</th>
                  <!-- <th class="py-2 px-4 border-b border-gray-200">หน่วยงาน</th> -->
                  <th class="py-2 px-4 border-b border-gray-200">สถานที่รับ</th>
                  <th class="py-2 px-4 border-b border-gray-200">สถานะใบขอซื้อ</th>
                  <th class="py-2 px-4 border-b border-gray-200">รายละเอียด</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="requisition in paginatedRequisitions" :key="requisition.pr_id" class="hover:bg-gray-100 odd:bg-gray-50">
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.purchase_request_number }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ formatDate(requisition.request_date) }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.creator_name }}</td>
                  <!-- <td class="py-2 px-4 border-b border-gray-200">{{ requisition.department_name }}</td> -->
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.warehouse_name || 'N/A' }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.status_pr }}</td>
                  <td class="py-2 px-4 border-b border-gray-200">{{ requisition.details }}</td>
                </tr>
                <tr v-if="requisitions.length === 0">
                  <td colspan="8" class="py-4 px-4 text-center text-gray-500">No purchase requests found.</td>
                </tr>
              </tbody>
            </table>
          </div>

        <!-- การแบ่งหน้า -->
        <div class="flex justify-center mt-4">
          <button
            v-for="page in totalPages"
            :key="page"
            @click="changePage(page)"
            :class="['px-4 py-2 mx-1', { 'bg-blue-600 text-white': page === currentPage, 'bg-gray-200': page !== currentPage }]"
          >
            {{ page }}
          </button>
        </div>


        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as echarts from 'echarts';
  import { ClipboardListIcon,IdentificationIcon,CheckCircleIcon, ExclamationCircleIcon, XCircleIcon, ShoppingBagIcon, ClockIcon } from '@heroicons/vue/solid';
  export default {
    data() {
      return {
        requisitions: [],
        search: '',
        dateFrom: '',
        dateTo: '',
        selectedStatus: '',
        statusOptions: [], // ตัวแปรเก็บสถานะที่ดึงจาก API
        statusCounts: [
      // { status: 'ใบขอซื้อทั้งหมด', count: 0 },
      // { status: 'ผู้ขอซื้อทั้งหมด', count: 0 },
      { status: 'รอดำเนินการ', count: 0 },
      { status: 'รอผจก.หน่วยงานตรวจสอบ', count: 0 },
      { status: 'รอส่วนกลางตรวจสอบ', count: 0 },
      { status: 'รอเจ้าหน้าที่ธุรการจัดซื้อ', count: 0 },
      { status: 'อยู่ระหว่างการสั่งซื้อ', count: 0 },
      { status: 'สั่งซื้อครบแล้ว', count: 0 },
      { status: 'ยกเลิกใบ PR', count: 0 }
    ],
    statusColors: {
      // 'ใบขอซื้อทั้งหมด': 'bg-blue-500',
      // 'ผู้ขอซื้อทั้งหมด': 'bg-indigo-500',
      'รอดำเนินการ': 'bg-yellow-500',
      'รอผจก.หน่วยงานตรวจสอบ': 'bg-pink-500',
      'รอส่วนกลางตรวจสอบ': 'bg-orange-500',
      'รอเจ้าหน้าที่ธุรการจัดซื้อ': 'bg-purple-500',
      'อยู่ระหว่างการสั่งซื้อ': 'bg-teal-500',
      'สั่งซื้อครบแล้ว': 'bg-green-500',
      'ยกเลิกใบ PR': 'bg-gray-500'
    },
    statusIcons: {
      'ใบขอซื้อทั้งหมด': ClipboardListIcon,
      'ผู้ขอซื้อทั้งหมด': IdentificationIcon,
      'รอดำเนินการ': ClockIcon,
      'รอผจก.หน่วยงานตรวจสอบ': ClockIcon,
      'รอส่วนกลางตรวจสอบ': ExclamationCircleIcon,
      'รอเจ้าหน้าที่ธุรการจัดซื้อ': ExclamationCircleIcon,
      'อยู่ระหว่างการสั่งซื้อ': ShoppingBagIcon,
      'สั่งซื้อครบแล้ว': CheckCircleIcon,
      'ยกเลิกใบ PR': XCircleIcon
    },
        creatorsCount: 0,
        creatorChartData: null,
        statusChartData: null,
          // ตัวแปรใหม่สำหรับแบ่งหน้า
      currentPage: 1,
      itemsPerPage: 10, // กำหนดให้แสดงหน้าละ 10 รายการ
      };
    },
    computed: {
      sortedRequisitions() {
        return [...this.requisitions].sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
      },
       // คำนวณรายการที่จะแสดงในแต่ละหน้า
    paginatedRequisitions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredRequisitions.slice(start, end);
    },
    // คำนวณจำนวนหน้าจากจำนวนรายการที่กรองแล้ว
    totalPages() {
      return Math.ceil(this.filteredRequisitions.length / this.itemsPerPage);
    },
    filteredRequisitions() {
    const searchLower = this.search.toLowerCase(); // แปลง search เป็นตัวพิมพ์เล็ก

    return this.requisitions.filter(requisition => {
      const prNumberLower = requisition.purchase_request_number.toLowerCase(); // แปลง purchase_request_number เป็นตัวพิมพ์เล็ก
      const creatorNameLower = requisition.creator_name.toLowerCase(); // แปลง creator_name เป็นตัวพิมพ์เล็ก

      return (
        (!this.selectedStatus || requisition.status_pr === this.selectedStatus) &&
        (!this.search || prNumberLower.includes(searchLower) || creatorNameLower.includes(searchLower)) &&
        (!this.dateFrom || new Date(requisition.request_date) >= new Date(this.dateFrom)) &&
        (!this.dateTo || new Date(requisition.request_date) <= new Date(this.dateTo))
      );
    }).sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
  }
    },
    created() {
      this.fetchData();
      this.fetchStatusOptions();
    },
    methods: {
      async fetchStatusOptions() {
  try {
    const response = await axios.get('https://brk-group.org/api/status-options');
    this.statusOptions = response.data;

    // คำนวณจำนวนของแต่ละสถานะตามลำดับใน statusCounts
    this.statusCounts = this.statusCounts.map(item => ({
      ...item,
      count: this.requisitions.filter(requisition => requisition.status_pr === item.status).length
    }));
  } catch (error) {
    console.error('Error fetching status options:', error);
  }
},
      async fetchData() {
        const params = {
          search: this.search,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        };
        try {
          const response = await axios.get('https://brk-group.org/api/purchase-requests/search', { params });
          this.requisitions = Array.isArray(response.data) ? response.data : [];
  
          this.totalAmount = this.requisitions.reduce((total, requisition) => total + requisition.total_amount, 0);
          this.creatorsCount = new Set(this.requisitions.map(requisition => requisition.creator_name)).size;
  
          await this.fetchCreatorChartData(params);
          await this.fetchStatusChartData(params);
        } catch (error) {
          console.error('Error fetching data:', error);
          this.requisitions = [];
        }
      },
       // เปลี่ยนหน้าปัจจุบัน
    changePage(page) {
      this.currentPage = page;
    },
      async fetchCreatorChartData(params = {}) {
    try {
      const response = await axios.get('https://brk-group.org/api/purchase-requests/total-by-creator', { params });
      const creatorData = response.data;

      this.creatorChartData = {
        labels: creatorData.map(data => data.creator_name),
        datasets: [
          {
            label: 'Number of Purchase Requests by Creator',
            data: creatorData.map(data => data.total_amount),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',  // สีของแท่งกราฟ
            borderColor: 'rgba(75, 192, 192, 1)',        // สีขอบของแท่งกราฟ
            borderWidth: 1
          }
        ]
      };
      this.renderCreatorChart();
    } catch (error) {
      console.error('Error fetching creator chart data:', error);
    }
  },
      async fetchStatusChartData(params = {}) {
        try {
          const response = await axios.get('https://brk-group.org/api/purchase-requests/status-distribution', { params });
          if (response.data && response.data.length) {
            this.statusChartData = {
              labels: response.data.map(item => item.status_pr),
              datasets: [
                {
                  label: 'จำนวนสถานะ',
                  data: response.data.map(item => item.count),
                },
              ],
            };
            this.renderStatusChart();
          } else {
            console.error('No data found for status chart');
          }
        } catch (error) {
          console.error('Error fetching status chart data:', error);
        }
      },
      renderCreatorChart() {
  const chartDom = this.$refs.creatorChart;
  const myChart = echarts.init(chartDom);
  
  // กำหนดสีสำหรับแต่ละแท่งในกราฟ
  const colors = ['#5470C6', '#91CC75', '#EE6666', '#73C0DE', '#FAC858', '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC'];
  
  const option = {
    title: {
      text: 'Number of Purchase Requests by Creator',
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: '{b0}: {c0}'
    },
    xAxis: {
      type: 'category',
      data: this.creatorChartData.labels || []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: this.creatorChartData.datasets[0].data.map((value, index) => ({
          value: value,
          itemStyle: {
            color: colors[index % colors.length] // กำหนดสีจาก array ของ colors
          }
        })),
        type: 'bar',
        borderColor: this.creatorChartData.datasets[0].borderColor,
        borderWidth: this.creatorChartData.datasets[0].borderWidth
      }
    ]
  };
  
  myChart.setOption(option);
  },
      renderStatusChart() {
        const chartDom = this.$refs.statusChart;
        const myChart = echarts.init(chartDom);
        const option = {
          title: {
            text: 'Order Status Distribution',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          series: [
            {
              name: 'สถานะ',
              type: 'pie',
              radius: '50%',
              data: this.statusChartData.labels.map((label, index) => ({
                value: this.statusChartData.datasets[0].data[index],
                name: label,
              })),
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      formatAmount(amount) {
        return amount !== null && amount !== undefined ? parseFloat(amount).toFixed(2) : '0.00';
      },
      formatDate(date) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      },
    },
    mounted() {
      this.fetchData();
      this.fetchStatusOptions();
    },
    watch: {
    search() {
      this.currentPage = 1; // รีเซ็ตไปยังหน้าแรกเมื่อมีการค้นหา
    },
    dateFrom() {
      this.currentPage = 1; // รีเซ็ตไปยังหน้าแรกเมื่อเปลี่ยนวันที่เริ่มต้น
    },
    dateTo() {
      this.currentPage = 1; // รีเซ็ตไปยังหน้าแรกเมื่อเปลี่ยนวันที่สิ้นสุด
    },
    selectedStatus() {
      this.currentPage = 1; // รีเซ็ตไปยังหน้าแรกเมื่อเปลี่ยนสถานะที่เลือก
    }
  },

  };
  </script>
  
  <style scoped>
  .card {
    border-radius: 0.5rem;
  }
  
  .card-header {
    border-bottom: 2px solid #007bff;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .input-group-text {
    background-color: #f8f9fa;
  }
  
  .btn {
    margin-left: 5px;
  }
  
  .badge {
    font-size: 90%;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }

  select {
  color: #000000; /* ตั้งค่าสีของฟอนต์ให้เป็นสีดำ */
  font-family: 'Arial', sans-serif; /* เปลี่ยนฟอนต์ให้รองรับภาษาไทย */
  font-size: 16px; /* ปรับขนาดฟอนต์ */
}

option {
  color: #000000; /* สีของฟอนต์ในตัวเลือก */
  background-color: #ffffff; /* สีพื้นหลังของตัวเลือก */
}
  </style>
