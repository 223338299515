<template>
    <div class="container mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4 text-center">รายการแมปสินค้ากับร้านค้า</h2>
  
      <!-- ส่วนการค้นหา -->
      <div class="mb-4 flex justify-between">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="ค้นหาชื่อผู้จัดจำหน่าย"
          class="border border-gray-300 rounded p-2 w-1/2"
          @input="fetchMappings"
        />
        <button
          @click="clearSearch"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          ค้นหาทุกร้านค้า
        </button>
        <!-- ปุ่มสำหรับเปิด Modal เลือกสินค้าตามรหัสสินค้า -->
        <button
          @click="showProductSearchModal = true"
          class="bg-green-500 text-white px-4 py-2 rounded"
        >
          ค้นหารหัสสินค้า
        </button>
      </div>
  
      <!-- ตารางแสดงรายการแมป -->
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-300">
          <thead>
            <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th class="py-3 px-6 text-center">ชื่อสินค้า</th>
              <th class="py-3 px-6 text-center">ชื่อผู้จัดจำหน่าย</th>
              <th class="py-3 px-6 text-center">รหัสสินค้าของร้าน</th>
              <th class="py-3 px-6 text-center">การดำเนินการ</th>
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm font-light">
            <tr
              v-for="mapping in filteredMappings"
              :key="mapping.mapping_id"
              class="border-b border-gray-200 hover:bg-gray-100"
            >
              <td class="py-3 px-6 text-center whitespace-nowrap">{{ mapping.product_name }}</td>
              <td class="py-3 px-6 text-center whitespace-nowrap">{{ mapping.company_name }}</td>
              <td class="py-3 px-6 text-center whitespace-nowrap">{{ mapping.supplier_product_code }}</td>
              <td class="py-3 px-6 text-center whitespace-nowrap">
                <button
                  @click="viewMappingForProduct(mapping.product_id)"
                  class="bg-blue-500 text-white px-4 py-1 rounded"
                >
                  ดูการแมปร้านค้า
                </button>
                <button
            @click="cancelMapping(mapping.mapping_id)"
            class="bg-red-500 text-white px-4 py-1 rounded"
          >
            ยกเลิกการแมป
          </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  <!-- ปุ่มการแบ่งหน้า -->
  <div class="flex justify-center items-center mt-4">
    <button
      @click="prevPage"
      :disabled="page === 1"
      class="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
    >
      ก่อนหน้า
    </button>
    <span>หน้า {{ page }} จาก {{ pageCount }}</span>
    <button
      @click="nextPage"
      :disabled="page === pageCount"
      class="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2"
    >
      ถัดไป
    </button>
  </div>

      <!-- Modal สำหรับค้นหาสินค้า -->
      <div v-if="showProductSearchModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
        <div class="bg-white p-6 rounded shadow-lg w-full max-w-2xl">
          <h3 class="text-xl font-bold mb-4">เลือกรหัสสินค้า</h3>
          <input
            v-model="productSearchTerm"
            type="text"
            placeholder="ค้นหาชื่อหรือรหัสสินค้า"
            class="w-full mb-4 p-2 border border-gray-300 rounded"
            @input="fetchProducts"
          />
          <div class="overflow-y-auto max-h-80">
            <table class="min-w-full">
              <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th class="py-3 px-6 text-left">รหัสสินค้า</th>
                  <th class="py-3 px-6 text-left">ชื่อสินค้า</th>
                  <th class="py-3 px-6 text-left">เลือก</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product.product_id" class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="py-3 px-6 text-left">{{ product.product_code }}</td>
                  <td class="py-3 px-6 text-left">{{ product.product_name }}</td>
                  <td class="py-3 px-6 text-left">
                    <button @click="selectProduct(product)" class="bg-green-500 text-white px-4 py-1 rounded">
                      เลือก
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-4 flex justify-between items-center">
  <button
    :disabled="page === 1"
    @click="changePage(page - 1)"
    class="bg-blue-500 text-white px-4 py-2 rounded"
  >
    หน้าก่อนหน้า
  </button>

  <span>หน้า {{ page }} จาก {{ totalPages }}</span>

  <button
    :disabled="page === totalPages"
    @click="changePage(page + 1)"
    class="bg-blue-500 text-white px-4 py-2 rounded"
  >
    หน้าถัดไป
  </button>
</div>
          <button @click="showProductSearchModal = false" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">
            ปิด
          </button>
        </div>
      </div>
  
      <!-- Modal สำหรับแสดงร้านค้าที่แมปกับสินค้า -->
      <div v-if="showProductModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
        <div class="bg-white p-6 rounded shadow-lg w-full max-w-2xl">
          <h3 class="text-xl font-bold mb-4">ร้านค้าที่แมปรหัสสินค้าชิ้นนี้</h3>
          <ul>
            <li v-for="mapping in productMappings" :key="mapping.mapping_id">
              {{ mapping.company_name }} - {{ mapping.supplier_product_code }}
            </li>
          </ul>
          <button @click="closeProductModal" class="mt-4 bg-red-500 text-white px-4 py-2 rounded">
            ปิด
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        mappings: [], // รายการแมปสินค้ากับร้านค้า
        productMappings: [], // รายการร้านค้าที่แมปกับสินค้าชิ้นนี้
        searchTerm: '', // ค้นหาผู้จัดจำหน่าย
        productSearchTerm: '', // ค้นหารหัสสินค้า
        products: [], // รายการสินค้าที่ค้นหา
        showProductModal: false, // Modal สำหรับแสดงรายการแมป
        showProductSearchModal: false, // Modal สำหรับค้นหาสินค้า
        page: 1, // หน้าปัจจุบัน
        limit: 10, // จำนวนสินค้าต่อหน้า
        totalPages: 1, // จำนวนหน้าทั้งหมด
        itemsPerPage: 10, 
       
    };
    },
    computed: {
      // กรองรายการตามคำค้นหา
      filteredMappings() {
        if (this.searchTerm) {
          return this.mappings.filter(mapping =>
            mapping.company_name.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
        return this.mappings;
      },
      pageCount() {
      return Math.ceil(this.filteredMappings.length / this.itemsPerPage);
    },
    paginatedMappings() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredMappings.slice(start, end);
    },
    },
    methods: {
        async cancelMapping(mappingId) {
      // ใช้ SweetAlert2 เพื่อยืนยันก่อนการยกเลิกการแมป
      const result = await Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณต้องการยกเลิกการแมปนี้หรือไม่?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่, ยกเลิกมัน!',
        cancelButtonText: 'ยกเลิก',
      });

      if (result.isConfirmed) {
        try {
          // เรียก API เพื่อลบการแมป
          await axios.delete(`https://brk-group.org/api/mappings/${mappingId}`);
          
          // ลบรายการออกจาก UI
          this.filteredMappings = this.filteredMappings.filter(
            (mapping) => mapping.mapping_id !== mappingId
          );

          // แสดงการแจ้งเตือนความสำเร็จ
          Swal.fire(
            'ยกเลิกสำเร็จ!',
            'การแมปของคุณได้ถูกยกเลิกแล้ว',
            'success'
          );
          this.fetchMappings();
        } catch (error) {
          // แสดงการแจ้งเตือนเมื่อเกิดข้อผิดพลาด
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไม่สามารถยกเลิกการแมปได้',
            'error'
          );
        }
    }
},
      // ดึงข้อมูลการแมปสินค้ากับร้านค้า
      async fetchMappings() {
        try {
          let url = 'https://brk-group.org/api/product-supplier-mapping';
          if (this.searchTerm) {
            url += `?supplier=${encodeURIComponent(this.searchTerm)}`;
          }
          const response = await fetch(url);
          const data = await response.json();
          this.mappings = data;
        } catch (error) {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูล:', error);
        }
      },
      // เคลียร์การค้นหาและดึงข้อมูลใหม่
      clearSearch() {
        this.searchTerm = '';
        this.fetchMappings();
        this.productSearchTerm = '';
      },
      // แสดงรายการร้านค้าที่แมปกับสินค้าชิ้นนี้
      async viewMappingForProduct(productId) {
        try {
          const response = await fetch(`https://brk-group.org/api/product-mappings/${productId}`);
          const data = await response.json();
          this.productMappings = data;
          this.showProductModal = true;
        } catch (error) {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูล:', error);
        }
      },
      // ดึงข้อมูลสินค้าตามคำค้นหา
      async fetchProducts() {
        // เรียก API เพื่อดึงข้อมูลสินค้า พร้อมค้นหาและ pagination
        try {
          const response = await fetch(
            `https://brk-group.org/api/products?searchQuery=${this.productSearchTerm}&page=${this.page}&limit=${this.limit}`
          );
          const data = await response.json();
          this.products = data.products;
          this.totalPages = data.totalPages;
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      },
      changePage(page) {
    if (page >= 1 && page <= this.totalPages) {
      this.page = page;  // กำหนดหน้าใหม่
      this.fetchProducts();  // ดึงข้อมูลใหม่สำหรับหน้านั้น
    }
  },
      // เลือกสินค้าเพื่อแสดงรายการร้านค้าที่แมปไว้
      selectProduct(product) {
        this.viewMappingForProduct(product.product_id);
        this.showProductSearchModal = false;
      },
      // ปิด Modal
      closeProductModal() {
        this.showProductModal = false;
      },
      nextPage() {
      if (this.page < this.pageCount) {
        this.page++;
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    },
    async created() {
      this.fetchMappings();
      this.fetchProducts();
    }
  };
  </script>
  
  <style scoped>
  /* ใช้ TailwindCSS สำหรับการจัดสไตล์ */
  </style>
  