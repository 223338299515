// // import { createApp } from 'vue'
// // import App from './App.vue'

// // createApp(App).mount('#app')
// import { createApp } from 'vue';
// import App from './App.vue';
// import router from './router';
// // import socket from './services/socket'; // เพิ่มการนำเข้า socket.js
// import { io } from 'socket.io-client'; // นำเข้า socket.io-client
// import '@fortawesome/fontawesome-free/css/all.css';

// // ใน main.js
// import './assets/tailwind.css';
// const socket = io('https://brk-group.org'); 
// const app = createApp(App);

// app.config.globalProperties.$socket = socket;

// app.use(router);

// app.mount('#app');
// const resizeObserverErr = 'ResizeObserver loop limit exceeded';
// const resizeObserverErrRegex = new RegExp(resizeObserverErr);
// window.addEventListener('error', (event) => {
//     if (resizeObserverErrRegex.test(event.message)) {
//         event.stopImmediatePropagation();
//     }
// });


import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { io } from 'socket.io-client';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/tailwind.css';

// เชื่อมต่อ Socket.IO
const socket = io('https://brk-group.org', {
  transports: ['websocket'], // ใช้ WebSocket เป็นหลัก
  reconnectionAttempts: 5, // จำนวนครั้งที่พยายามเชื่อมต่อใหม่
  timeout: 10000 // ระยะเวลา timeout (ms)
});

// Debug WebSocket Connection
socket.on('connect', () => {
  console.log('WebSocket connected');
});

socket.on('disconnect', (reason) => {
  console.error('WebSocket disconnected:', reason);
});

socket.on('connect_error', (error) => {
  console.error('WebSocket connection error:', error);
});

const app = createApp(App);

// เพิ่ม Global Properties
// app.config.globalProperties.$user = {
//     username: 'admin', // ตัวอย่างค่าผู้ใช้
//     role: 'editor'
//   };
  // เพิ่ม Global Properties
app.config.globalProperties.$user = {
    username: '', // เริ่มต้นเป็นค่าว่าง
    role: 'editor',
    setUsername(newUsername) {
        this.username = newUsername; // ฟังก์ชันเพื่อเปลี่ยนค่า username
    }
};

// ดึงค่าจาก localStorage และตั้งค่าให้กับ username
const userToken = localStorage.getItem('userToken');
app.config.globalProperties.$user.username = userToken ? localStorage.getItem('userName') : '';
// ใช้ Router
app.use(router);

// Mount Vue App
app.mount('#app');

// ป้องกัน ResizeObserver Error
const resizeObserverErr = 'ResizeObserver loop limit exceeded';
const resizeObserverErrRegex = new RegExp(resizeObserverErr);
window.addEventListener('error', (event) => {
  if (resizeObserverErrRegex.test(event.message)) {
    event.stopImmediatePropagation();
  }
});
