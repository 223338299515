
<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
  <title>test</title>
    <div class="home">
     
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'HomeView',
    data() {
      return {
        inventory: [],
      };
    },
    async created() {
      const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }
      // this.fetchInventory();
      this.fetchUser();
    },
    methods: {
       async fetchUser() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        // console.log(this.user); // ตรวจสอบข้อมูลผู้ใช้
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
      // async fetchInventory() {
      //   try {
      //     // const response = await axios.get('httpห://brk-group.org/inventory');
      //     const response = await axios.get('https://brk-group.org/inventory');
          
      //     this.inventory = response.data;
      //   } catch (error) {
      //     console.error("There was an error fetching the inventory data:", error);
      //     this.inventory = [];
      //   }
      // }
    }
    
  };
  
  </script>
  
  <style scoped>
  h1 {
    color: #333;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
    padding: 10px;
    background-color: #f0f0f0;
  }
  </style>
  