<template>
      <TransportLayout>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">บันทึกข้อมูลตั๋วขนส่ง</h1>
      <!-- เพิ่มส่วนหน้าฟอร์มสำหรับหน่วยงานและกลุ่มงาน -->
<div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
      <multiselect 
        v-model="department" 
        :options="departmentOptions"
        :show-labels="false"
        placeholder="เลือกหน่วยงาน"
        label="name"
        track-by="id"
      ></multiselect>
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">กลุ่มงาน</label>
      <multiselect 
        v-model="workGroup" 
        :options="workGroupOptions"
        :show-labels="false"
        placeholder="เลือกกลุ่มงาน"
        label="name"
        track-by="id"
      ></multiselect>
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">วันที่เริ่มงาน</label>
      <input 
        type="date" 
        v-model="startDate"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
      />
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">วันที่จบงาน</label>
      <input 
        type="date" 
        v-model="completionDate"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
      />
    </div>
  </div>
</div>

<!-- เพิ่มการแสดงจำนวน Trip และรวมระยะทาง -->
<div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">จำนวน Trip</label>
      <input 
        type="text" 
        :value="trips.length"
        disabled 
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100"
      />
    </div>
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">รวมระยะทาง (กม.)</label>
      <input 
        type="text" 
        :value="totalDistance.toFixed(2)"
        disabled 
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100"
      />
    </div>
  </div>
</div>
<!-- ส่วนที่ 1: ข้อมูล พขร. และรถ -->
<div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
  <h2 class="text-xl font-semibold mb-4">ข้อมูลพนักงานขับรถ</h2>

  <!-- พขร. คนที่ 1 -->


  <div class="grid grid-cols-3 gap-4 mb-4">
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 1</label>
    <multiselect 
      v-model="driver1" 
      :options="employeeOptions"
      :show-labels="false"
      placeholder="เลือกพนักงานขับรถ"
      label="fullName"
      track-by="id"
    ></multiselect>
  </div>
  <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">พนักงานขับรถคนที่ 2</label>
      <multiselect 
        v-model="driver2" 
        :options="employeeOptions"
        :show-labels="false"
        placeholder="เลือกพนักงานขับรถ"
        label="fullName"
        track-by="id"
      ></multiselect>
    </div>
  <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">คู่รถ</label>
  <multiselect 
    v-model="selectedVehiclePair" 
    :options="vehiclePairOptions"
    :show-labels="false"
    placeholder="เลือกคู่รถ"
    label="vehicleCodeLabel"
    track-by="id"
  ></multiselect>
  <button 
      class="bg-blue-500 text-white font-bold py-1 px-4 rounded hover:bg-blue-600" 
      @click="showVehiclePairDetails"
      :disabled="!selectedVehiclePair"
    >
    <i class="fas fa-search text-white text-xs"></i>
    </button>
</div>

  

 
</div>

<transition name="fade">
  <div 
    v-if="isModalVisible" 
    class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
  >
    <div class="bg-white w-1/3 rounded-lg shadow-lg p-6 relative">
      <h2 class="text-xl font-bold mb-4">รายละเอียดคู่รถ</h2>
      <div class="mb-4">
  <table class="min-w-full table-auto border-collapse border border-gray-300">
    <thead class="bg-gray-100">
      <tr>
        <th class="border border-gray-300 px-4 py-2 text-left font-semibold text-gray-700">รายการ</th>
        <th class="border border-gray-300 px-4 py-2 text-left font-semibold text-gray-700">รายละเอียด</th>
      </tr>
    </thead>
    <tbody>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">ทะเบียนรถหัว</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.headLicensePlate }}</td>
      </tr>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">รหัสรถหัว</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.headVehicleCode }}</td>
      </tr>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">ทะเบียนรถหาง</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.trailerLicensePlate }}</td>
      </tr>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">รหัสรถหาง</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.trailerVehicleCode }}</td>
      </tr>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">กลุ่ม</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.groupName }}</td>
      </tr>
      <tr class="hover:bg-gray-50">
        <td class="border border-gray-300 px-4 py-2">หน่วยงาน</td>
        <td class="border border-gray-300 px-4 py-2">{{ selectedVehiclePair?.departmentName }}</td>
      </tr>
    </tbody>
  </table>
</div>
      <div class="flex justify-end">
        <button 
          class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600" 
          @click="isModalVisible = false"
        >
          ปิด
        </button>
      </div>
    </div>
  </div>
</transition>

  <!-- พขร. คนที่ 2 -->
  <div class="grid grid-cols-3 gap-4">
    

  </div>
  </div>

  <!-- เพิ่มทะเบียนหาง พขร. คนที่ 2 อยู่ในแถวเดียวกับ รถหัว -->




  
      <!-- ส่วนที่ 2: เส้นทาง -->
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-semibold">รายการเที่ยวการขนส่ง</h2>
          <button 
            @click="openTripModal" 
            :disabled="trips.length >= 6"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            + เพิ่ม Trip
          </button>
        </div>
  
        <!-- แสดงรายการ Trip -->
        <div class="grid grid-cols-1 gap-4">
          
          <div 
            v-for="(trip, index) in trips" 
            :key="index" 
            class="border p-4 rounded relative"
          >
            <!-- <span class="absolute top-2 right-2 font-bold">Trip {{ trip.tripNo }}</span> -->
            <span class="absolute top-2 right-2 font-bold">Trip {{ index + 1 }}</span>
            <!-- ปุ่มลบ Trip -->
  <button 
    @click="removeTrip(index)" 
    class="absolute top-2 right-16 bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
  >
    ลบ
  </button>
<br><br>
            <div class="grid grid-cols-3 gap-4">
              

              <!-- <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">Trip </label>
    <input 
      v-model="trip.trip" 
      class="w-full px-3 py-2 border rounded"
      placeholder="กรอกเลขที่ตั๋ว"
    />
  </div> -->

              <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">เลขที่ตั๋ว</label>
    <input 
      v-model="trip.ticketNumber" 
      class="w-full px-3 py-2 border rounded"
      placeholder="กรอกเลขที่ตั๋ว"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า</label>
    <multiselect 
      v-model="trip.productType" 
      :options="productTypeOptions"
      :show-labels="false"
      placeholder="เลือกประเภทสินค้า"
      label="name"
      track-by="id"
    ></multiselect>
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">เที่ยวที่</label>
    <input 
      v-model="trip.tripSequence" 
      type="number" 
      class="w-full px-3 py-2 border rounded"
      placeholder="กรอกเที่ยวที่"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ความจุ</label>
    <input 
      v-model="trip.capacity" 
      type="number" 
      class="w-full px-3 py-2 border rounded"
      placeholder="กรอกความจุ"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">เส้นทาง</label>
    <multiselect 
      v-model="trip.route" 
      :options="routeOptions"
      placeholder="เลือกเส้นทาง"
      label="name"
      track-by="id"
    ></multiselect>
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ระยะทาง (กม.)</label>
    <input 
      v-model="trip.distance" 
      type="number" 
      readonly 
      class="w-full px-3 py-2 border rounded"
      placeholder="ระยะทาง"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ค่าขนส่ง</label>
    <input 
      v-model="trip.transportFee" 
      type="number" 
      readonly 
      class="w-full px-3 py-2 border rounded"
      placeholder="ค่าขนส่ง"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">ค่าทางด่วน</label>
    <input 
      v-model="trip.tollFee" 
      type="number" 
      class="w-full px-3 py-2 border rounded"
      placeholder="ค่าทางด่วน"
    />
  </div>

  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">นอนโหลด</label>
    <multiselect 
      v-model="trip.loadStatus" 
      :options="loadStatusOptions"
      placeholder="เลือกสถานะโหลด"
    ></multiselect>
  </div>

  <div class="col-span-2">
    <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ</label>
    <textarea 
      v-model="trip.remarks"
      class="w-full px-3 py-2 border rounded"
      placeholder="กรอกหมายเหตุ"
    ></textarea>
  </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- ปุ่มบันทึก -->
      <div class="text-center">
        <button 
          @click="saveTransportTicket"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          บันทึกข้อมูล
        </button>
      </div>
  
      <!-- Modal สำหรับเพิ่ม Trip -->
      <div 
        v-if="showTripModal" 
        class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-black bg-opacity-50"
      >
      <div class="relative w-full max-w-lg sm:max-w-xl md:max-w-2xl mx-auto my-6">
          <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg">
            <div class="flex items-start justify-between p-5 border-b border-solid rounded-t">
              <h3 class="text-xl font-semibold">เพิ่ม Trip</h3>
              <button 
                @click="closeTripModal"
                class="text-gray-400 hover:text-gray-600 text-2xl"
              >
                &times;
              </button>
            </div>
            
            <div class="relative p-6 flex-auto max-h-[60vh] overflow-y-auto">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">Trip No</label>
                  <input 
                    type="text" 
                    :value="`Trip ${trips.length + 1}`" 
                    disabled 
                    class="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
                
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">เลขที่ตั๋ว</label>
                  <input 
                    v-model="newTrip.ticketNumber" 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="กรอกเลขที่ตั๋ว"
                  />
                </div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า</label>
                  <multiselect 
                    v-model="newTrip.productType" 
                    :options="productTypeOptions"
                    :show-labels="false"
                    placeholder="เลือกประเภทสินค้า"
                    label="name"
                    track-by="id"
                  ></multiselect>
                </div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">เที่ยวที่</label>
                  <input 
                    v-model="newTrip.tripSequence" 
                    type="number" 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="กรอกเที่ยวที่"
                  />
                </div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">ความจุ</label>
                  <input 
                    v-model="newTrip.capacity" 
                    type="number" 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="กรอกความจุ"
                  />
                </div>
  
                <div>
  <label class="block text-gray-700 text-sm font-bold mb-2">เส้นทาง</label>
  <div class="grid grid-cols-1 gap-2">
    <multiselect 
      v-model="newTrip.route" 
      :options="routeOptions"
      placeholder="เลือกเส้นทาง"
      label="name"
      track-by="id"
    ></multiselect>
  </div>
</div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">ระยะทาง (กม.)</label>
                  <input 
                    v-model="newTrip.distance" 
                    type="number" 
                    readonly 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="ระยะทาง"
                  />
                </div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">ค่าขนส่ง</label>
                  <input 
                    v-model="newTrip.transportFee" 
                    type="number" 
                    readonly 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="ค่าขนส่ง"
                  />
                </div>
  
                <div>
                  <label class="block text-gray-700 text-sm font-bold mb-2">ค่าทางด่วน</label>
                  <input 
                    v-model="newTrip.tollFee" 
                    type="number" 
                    class="w-full px-3 py-2 border rounded"
                    placeholder="ค่าทางด่วน"
                  />
                </div>
  
                  <!-- Checkbox ควบคุมการแสดงผล -->
          <div class="col-span-2">
            <label class="flex items-center text-gray-700 text-sm font-bold mb-2">
              <input 
                type="checkbox" 
                v-model="isLoadStatusEnabled"
                class="mr-2"
              />
              นอนโหลด
            </label>
          </div>


                <!-- ช่องนอนโหลด -->
          <div v-if="isLoadStatusEnabled" class="col-span-2">
            <label class="block text-gray-700 text-sm font-bold mb-2">สถานะนอนโหลด</label>
            <multiselect 
              v-model="newTrip.loadStatus" 
              :options="loadStatusOptions"
              placeholder="เลือกสถานะโหลด"
            ></multiselect>
          </div>
  
                <div class="col-span-2">
                  <label class="block text-gray-700 text-sm font-bold mb-2">หมายเหตุ</label>
                  <textarea 
                    v-model="newTrip.remarks"
                    class="w-full px-3 py-2 border rounded"
                    placeholder="กรอกหมายเหตุ"
                  ></textarea>
                </div>
              </div>
            </div>
            
            <div class="flex items-center justify-end p-6 border-t border-solid rounded-b">
              <button
                @click="closeTripModal"
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-4"
              >
                ยกเลิก
              </button>
              <button
                @click="saveTripDetails"
                class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</TransportLayout>
  </template>
  
  <script>   
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import TransportLayout from '@/components/transport/TransportSidebar.vue';
  export default {
  components: {
    TransportLayout,
    Multiselect
  },
  data() {
    return {
      // ข้อมูล พขร. และรถ
      driver1: {
        employee: null,
        headVehicleNumber: null,
        headVehicleLicense: null
      },
      driver2: {
        employee: null,
        headVehicleNumber: null,
        headVehicleLicense: null
      },
      tailVehicleOptions: [],
      // ข้อมูลส่วนเพิ่มเติม
      department: null,
      workGroup: null,
      startDate: null, // วันที่เริ่มงาน
      completionDate: null,
      totalTips: 0,
      totalDistance: 0,
      isModalVisible: false,
      // ตัวเลือกต่างๆ
      employeeOptions: [],
      headVehicleOptions: [],
      headVehicleNumberOptions: [],
      vehiclePairOptions: [],
      selectedVehiclePair: null,
      departmentOptions: [],
      workGroupOptions: [],
      productTypeOptions: [],
      routeOriginOptions: [],
      routeDestinationOptions: [],
      loadStatusOptions: [
        'เต็ม', 
        'ครึ่งหนึ่ง', 
        'ว่าง'
      ],
      isLoadStatusEnabled: false,
      // รายการ Trip
      newTrip: {
      ticketNumber: '',
      productType: null,
      tripSequence: '',
      capacity: '',
      route: null,
      distance: '',
      transportFee: '',
      tollFee: '',
      loadStatus: null,
      driver1Id: '',
      driver2Id: '',
      remarks: '',
      departmentId: '',
      workGroupId: '',
      startDate: null,
      completionDate: null,
      totalDistance: null,
      vehiclePairId: null,
      productTypeId: null,
      routeId: null,

    },
      trips: [],
      showTripModal: false,
      route: [],
      // ข้อมูล Trip ใหม่
      newTicket: {
        trip_no: null,
        ticket_number: '',
        product_type_id: null,
        trip_sequence: null,
        capacity: null,
        route_id: null,
        distance: null,
        transport_fee: null,
        toll_fee: null,
        load_status: 'ไม่โหลด',
        remarks: ''
      },
    }
  },
  created() {
    this.fetchInitialData()
  },
  methods: {
    showVehiclePairDetails() {
    if (this.selectedVehiclePair) {
      this.isModalVisible = true;
    }
  },
    async fetchInitialData() {
  try {
    // ดึงข้อมูลพนักงาน
    const employeesResponse = await axios.get('http://localhost:3000/api/ts/employees');
    this.employeeOptions = employeesResponse.data.map(emp => ({
      id: emp.id,
      fullName: `${emp.prefix} ${emp.firstName} ${emp.lastName}`,
      employeeCode: emp.employee_code,
    }));

    // ดึงข้อมูลคู่รถจาก /vehicle-pairs
    const vehiclePairsResponse = await axios.get('http://localhost:3000/api/ts/vehicle-pairs');
    this.vehiclePairOptions = vehiclePairsResponse.data.map(pair => ({
      
      id: pair.id,
      departmentId: pair.departmentId,
      departmentName: pair.departmentName,
      groupId: pair.groupId,
      groupName: pair.groupName,
      headLicensePlate: pair.headLicensePlate,
      headVehicleCode: pair.headVehicleCode,
      trailerLicensePlate: pair.trailerLicensePlate,
      trailerVehicleCode: pair.trailerVehicleCode,
      vehicleCodeLabel: `${pair.headVehicleCode} + ${pair.trailerVehicleCode}`,
    }));

    // ดึงข้อมูลหน่วยงาน
    const departmentsResponse = await axios.get('http://localhost:3000/api/ts/departments');
    this.departmentOptions = departmentsResponse.data;

    // ดึงข้อมูลกลุ่มงาน
    const workGroupsResponse = await axios.get('http://localhost:3000/api/ts/groups');
    this.workGroupOptions = workGroupsResponse.data;

    // ดึงประเภทสินค้า
    const productTypesResponse = await axios.get('http://localhost:3000/api/ts/product-types');
    this.productTypeOptions = productTypesResponse.data.map(option => ({
  id: option.id,
  name: option.name
}));
    // ดึงข้อมูลเส้นทาง
    const routesResponse = await axios.get('http://localhost:3000/api/ts/routes');
this.routeOptions = routesResponse.data.map(route => ({
    id: route.id,
    name: `${route.route_name} (ต้นทาง: ${route.origin}, ปลายทาง: ${route.destination})`, // ปรับให้แสดงต้นทางและปลายทาง
    distance_km: route.distance_km, // Preserve original data
    transport_fee: route.transport_fee // Preserve original data
}));
this.routeDestinationOptions = routesResponse.data.map(route => ({
  id: route.id,
  name: route.destination
}));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถโหลดข้อมูลเริ่มต้นได้',
    });
  }
}
,
openTripModal() {
  if (this.trips.length >= 6) {
    Swal.fire({
      icon: 'warning',
      title: 'จำกัดการเพิ่ม Trip',
      text: 'สามารถเพิ่ม Trip ได้สูงสุด 6 Trip เท่านั้น'
    })
    return
  }
  
  // กำหนดเลข Trip โดยอัตโนมัติ
  const tripNo = this.trips.length + 1
  
  // รีเซ็ตข้อมูล Trip ใหม่
  this.newTrip = {
    tripNo: tripNo,
    ticketNumber: '',
    productType: [], // เปลี่ยนจาก null เป็น array ว่าง
    tripSequence: tripNo.toString(), // แปลงเป็น string เพื่อความปลอดภัย
    capacity: '',
    origin: null, // เปลี่ยนกลับเป็น null
    destination: null, // เปลี่ยนกลับเป็น null
    distance: '',
    transportFee: '',
    tollFee: '',
    loadStatus: '',
    remarks: ''
  }
  
  this.showTripModal = true
},
    async calculateRouteDetails() {
      // ดึงระยะทางและค่าขนส่งจากเส้นทาง
      if (this.newTrip.origin && this.newTrip.destination) {
        try {
          const routeResponse = await axios.get('http://localhost:3000/api/ts/routes/details', {
            params: {
              origin: this.newTrip.origin.id,
              destination: this.newTrip.destination.id
            }
          })
          
          // กำหนดระยะทางและค่าขนส่งจากผลลัพธ์
          this.newTrip.distance = routeResponse.data.distance
          this.newTrip.transportFee = routeResponse.data.transportFee
        } catch (error) {
          console.error('Error fetching route details:', error)
        }
      }
    },
    saveTripDetails() {
    if (!this.validateTripDetails()) return;
    const tripNo = this.trips.length + 1;  // กำหนด trip_no โดยอัตโนมัติ
    const newTrip = {
      tripNo: tripNo,
        ticketNumber: this.newTrip.ticketNumber,
        productType: this.newTrip.productType,
        tripSequence: this.newTrip.tripSequence,
        capacity: this.newTrip.capacity,
        route: this.newTrip.route,
        distance: this.newTrip.distance,
        transportFee: this.newTrip.transportFee,
        tollFee: this.newTrip.tollFee,
        loadStatus: this.isLoadStatusEnabled ? this.newTrip.loadStatus : null,
        remarks: this.newTrip.remarks,
    };

    this.trips.push(newTrip);
    this.updateTotalDistance();
    this.closeTripModal();
},
    validateTripDetails() {
      const {
        ticketNumber, 
        productType, 
        tripSequence, 
        capacity, 
        // origin, 
        // destination, 
        // loadStatus
      } = this.newTrip

      // ตรวจสอบข้อมูลที่จำเป็น
      const requiredFields = [
        { value: ticketNumber, message: 'กรุณากรอกเลขที่ตั๋ว' },
        { value: productType, message: 'กรุณาเลือกประเภทสินค้า' },
        { value: tripSequence, message: 'กรุณากรอกเที่ยวที่' },
        { value: capacity, message: 'กรุณากรอกความจุ' },
        // { value: origin, message: 'กรุณาเลือกต้นทาง' },
        // { value: destination, message: 'กรุณาเลือกปลายทาง' },
        // { value: loadStatus, message: 'กรุณาเลือกสถานะโหลด' }
      ]

      for (let field of requiredFields) {
        if (!field.value) {
          Swal.fire({
            icon: 'error',
            title: 'ข้อมูลไม่ครบ',
            text: field.message
          })
          return false
        }
      }

      return true
    },
    updateTotalDistance() {
      // คำนวณระยะทางรวมจาก Trip
      this.totalDistance = this.trips.reduce((total, trip) => {
        return total + (parseFloat(trip.distance) || 0)
      }, 0)
    },
//     updateTotalDistance() {
//     this.totalDistance = this.trips.reduce((sum, trip) => sum + (trip.distance || 0), 0);
// },
    closeTripModal() {
      this.showTripModal = false
    },
    clearTripModalData() {
      
      // รีเซ็ตข้อมูลใน Modal
      this.newTrip = {
        tripNo: null,
        ticketNumber: '',
        productType: null,
        tripSequence: '',
        capacity: '',
        origin: null,
        destination: null,
        distance: '',
        transportFee: '',
        tollFee: '',
        loadStatus: '',
        remarks: ''
      }
    },
    async saveTransportTicket() {
    if (!this.validateMainFormData()) return;

    try {
        // ตรวจสอบความพร้อมของพนักงานขับรถ
        const checkDriverResponse = await axios.post('http://localhost:3000/api/ts/check-driver-availability', {
            driver1Id: this.driver1?.id || null,
            driver2Id: this.driver2?.id || null,
            startDate: this.startDate,
        });

        // ไม่มีข้อขัดแย้ง: ดำเนินการต่อ
        if (checkDriverResponse.status === 200) {
            return this.performSave(); // เรียกฟังก์ชันบันทึกข้อมูล
        }
    } catch (error) {
        // ตรวจสอบว่าเป็นข้อผิดพลาด 409 หรือไม่
        if (error.response?.status === 409) {
            const conflicts = error.response.data.conflicts;

            // สร้างข้อความแจ้งเตือน
            let conflictMessage = 'พนักงานขับรถมีงานในวันดังกล่าว:\n\n';
            if (conflicts.driver1?.length) {
                conflictMessage += `พนักงานขับรถ 1 (${this.driver1?.fullName || 'ไม่ทราบชื่อ'}):\n`;
                conflicts.driver1.forEach(job => {
                  const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
                  conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
                });
                conflictMessage += '\n';
            }
            if (conflicts.driver2?.length) {
                conflictMessage += `พนักงานขับรถ 2 (${this.driver2?.fullName || 'ไม่ทราบชื่อ'}):\n`;
                conflicts.driver2.forEach(job => {
                  const formattedDate = new Date(job.start_date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' });
                  conflictMessage += `- Trip No: ${job.trip_no}, Ticket No: ${job.ticket_number}, วันที่: ${formattedDate}\n`;
                });
                conflictMessage += '\n';
            }

            // แสดงข้อความยืนยัน
            const confirmResult = await Swal.fire({
                icon: 'warning',
                title: 'พนักงานขับรถมีงานแล้วในวันดังกล่าว',
                html: `<pre style="text-align:left;">${conflictMessage}</pre>`,
                showCancelButton: true,
                confirmButtonText: 'ยืนยันบันทึก',
                cancelButtonText: 'ยกเลิก',
            });

            if (confirmResult.isConfirmed) {
                // ผู้ใช้ยืนยัน: ดำเนินการบันทึกข้อมูล
                return this.performSave();
            } else {
                // ผู้ใช้ยกเลิก
                return; // ยกเลิกการบันทึก
            }
        }

        // ข้อผิดพลาดอื่น ๆ
        Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
        });
    }
},

// ฟังก์ชันสำหรับบันทึกข้อมูล
performSave() {
    const transportTicketData = {
        tripNo: (this.trip?.length || 0) + 1,
        ticketNumber: this.newTrip.ticketNumber,
        driver1Id: this.driver1?.id || null,
        driver2Id: this.driver2?.id || null,
        departmentId: this.department?.id || null,
        workGroupId: this.workGroup?.id || null,
        tripSequence: this.newTrip.tripSequence,
        startDate: this.startDate,
        completionDate: this.completionDate,
        totalDistance: Number(this.totalDistance) || 0,
        totalTrips: this.trips.length,
        vehiclePairId: this.selectedVehiclePair?.id || null,
        productTypeId: this.trips[0]?.productType?.id || null,
        capacity: Number(this.newTrip.capacity) || 0,
        routeId: this.newTrip.route?.id || null,
        distance: Number(this.newTrip.distance) || 0,
        transportFee: Number(this.newTrip.transportFee) || 0,
        tollFee: Number(this.newTrip.tollFee) || 0,
        loadStatus: this.isLoadStatusEnabled ? this.newTrip.loadStatus : null,
        remarks: this.newTrip.remarks || '',
        trips: this.trips.map((trip, index) => ({
            tripNo: index + 1,
            ticketNumber: trip.ticketNumber,
            productTypeId: trip.productType?.id || null,
            tripSequence: index + 1,
            capacity: Number(trip.capacity) || 0,
            routeId: trip.route?.id || null,
            distance: Number(trip.distance) || 0,
            transportFee: Number(trip.transportFee) || 0,
            tollFee: Number(trip.tollFee) || 0,
            loadStatus: trip.loadStatus === 'เต็ม' ? 'โหลด' : 'ไม่โหลด',
            remarks: trip.remarks || '',
        })),
    };

    if (!this.driver2) {
        delete transportTicketData.driver2Id; // ลบ driver2Id หากไม่มีการเลือกค่า
    }

    axios
        .post('http://localhost:3000/api/ts/transport-tickets', transportTicketData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            Swal.fire({
                icon: 'success',
                title: 'บันทึกสำเร็จ',
                text: response.data.message || 'บันทึกข้อมูลตั๋วขนส่งเรียบร้อยแล้ว',
            });
            this.resetForm();
        })
        .catch(error => {
            console.error('Full Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: error.response?.data?.message || 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง',
            });
        });
}
,

removeTrip(index) {
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: `ต้องการลบ Trip หมายเลข ${index + 1} หรือไม่?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        this.trips.splice(index, 1); // ลบ Trip ออกจากอาเรย์
        Swal.fire('ลบแล้ว!', `Trip หมายเลข ${index + 1} ถูกลบเรียบร้อย`, 'success');
      }
    });
  },


//     async saveTransportTicket() {
//     // ตรวจสอบข้อมูล
//     if (!this.validateMainFormData()) return;

//     try {
//       const transportTicketData = {
//           driver1: this.driver1.id, // ใช้ ID ของพนักงาน
//           driver2: this.driver2.id, // ใช้ ID ของพนักงาน
//           department: this.department.id, // ใช้ ID ของหน่วยงาน
//           workGroup: this.workGroup.id, // ใช้ ID ของกลุ่มงาน
//           startDate: this.startDate,
//           completionDate: this.completionDate,
//           totalDistance: this.totalDistance,
//           totalTips: this.totalTips,
//           trips: this.trips.map(trip => ({
//             tripNo: trip.tripNo, // ส่ง tripNo ที่คำนวณไว้ใน trips
//             ticketNumber: trip.ticketNumber,
//             productType: trip.productType.id,
//             tripSequence: trip.tripSequence,
//             capacity: trip.capacity,
//             route: trip.route.id,
//             distance: trip.distance,
//             transportFee: trip.transportFee,
//             tollFee: trip.tollFee,
//             loadStatus: trip.loadStatus,
//             remarks: trip.remarks,
//           }))
//         };

//         await axios.post('http://localhost:3000/api/ts/transport-tickets', transportTicketData, {
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });
        

//         Swal.fire({
//             icon: 'success',
//             title: 'บันทึกสำเร็จ',
//             text: 'บันทึกข้อมูลตั๋วขนส่งเรียบร้อยแล้ว'
//         });

//         this.resetForm();
//     } catch (error) {
//         Swal.fire({
//             icon: 'error',
//             title: 'เกิดข้อผิดพลาด',
//             text: 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง'

            
//         });
//     }
// },
validateMainFormData() {
    if (!this.driver1  || !this.department || !this.workGroup) {
        Swal.fire({
            icon: 'error',
            title: 'ข้อมูลไม่ครบ',
            text: 'กรุณากรอกข้อมูลพนักงานขับรถ หน่วยงาน และกลุ่มงาน'
        });
        return false;
    }
    if (!this.startDate) {
    Swal.fire({ icon: 'error', title: 'ข้อมูลไม่ครบถ้วน', text: 'กรุณากรอกวันที่เริ่มต้น' });
    return false;
  }
    if (!this.trips.length) {
        Swal.fire({
            icon: 'error',
            title: 'ไม่มีข้อมูล Trip',
            text: 'กรุณาเพิ่มอย่างน้อยหนึ่ง Trip'
        });
        return false;
    }

    return true;
},
    // validateMainFormData() {
    //   // ตรวจสอบข้อมูลหลักก่อนบันทึก
    //   const {
    //     employee: driver1Employee,
    //     headVehicleNumber: driver1VehicleNumber,
    //     headVehicleLicense: driver1VehicleLicense
    //   } = this.driver1

    //   const {
    //     employee: driver2Employee,
    //     headVehicleNumber: driver2VehicleNumber,
    //     headVehicleLicense: driver2VehicleLicense
    //   } = this.driver2

    //   const validationFields = [
    //     { value: driver1Employee, message: 'กรุณาเลือกพนักงานขับรถคนที่ 1' },
    //     { value: driver1VehicleNumber, message: 'กรุณาเลือกเบอร์รถหัวคนที่ 1' },
    //     { value: driver1VehicleLicense, message: 'กรุณาเลือกทะเบียนรถหัวคนที่ 1' },
    //     { value: driver2Employee, message: 'กรุณาเลือกพนักงานขับรถคนที่ 2' },
    //     { value: driver2VehicleNumber, message: 'กรุณาเลือกเบอร์รถหัวคนที่ 2' },
    //     { value: driver2VehicleLicense, message: 'กรุณาเลือกทะเบียนรถหัวคนที่ 2' },
    //     { value: this.department, message: 'กรุณาเลือกหน่วยงาน' },
    //     { value: this.workGroup, message: 'กรุณาเลือกกลุ่มงาน' },
    //     { value: this.trips.length > 0, message: 'กรุณาเพิ่มอย่างน้อยหนึ่ง Trip' }
    //   ]

    //   for (let field of validationFields) {
    //     if (!field.value) {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'ข้อมูลไม่ครบ',
    //         text: field.message
    //       })
    //       return false
    //     }
    //   }

    //   return true
    // },
    resetForm() {
      // รีเซ็ตข้อมูลทั้งหมด
      this.trips.forEach((trip, i) => {
  trip.tripNo = i + 1;
  trip.tripSequence = i + 1;
});

      this.driver1 = {
        employee: null,
        headVehicleNumber: null,
        headVehicleLicense: null
      }
      this.driver2 = {
        employee: null,
        headVehicleNumber: null,
        headVehicleLicense: null
      }
      this.department = null
      this.workGroup = null
      this.startDate = null
      this.completionDate = null
      this.trips = []
      this.totalDistance = 0
      this.totalTips = 0
      this.selectedVehiclePair = null

      this.newTicket = {
        trip_no: null,
        ticket_number: '',
        product_type_id: null,
        trip_sequence: null,
        capacity: null,
        route_id: null,
        distance: null,
        transport_fee: null,
        toll_fee: null,
        load_status: 'ไม่โหลด',
        remarks: ''
      };
    }
  },
  watch: {
    // ติดตามการเปลี่ยนแปลงเส้นทางเพื่อดึงระยะทาง
    'newTrip.origin': function() {
      this.calculateRouteDetails()
    },
    'newTrip.destination': function() {
      this.calculateRouteDetails()
    },
    'newTrip.route'(selectedRoute) {
    if (selectedRoute) {
      // Find the full route details from routeOptions
      const fullRouteDetails = this.routeOptions.find(route => route.id === selectedRoute.id);
      
      if (fullRouteDetails) {
        // Assuming the original route data has these properties
        this.newTrip.distance = fullRouteDetails.distance_km || 0;
        this.newTrip.transportFee = fullRouteDetails.transport_fee || 0;
      }
    }
  }
},
}
// </sciprt>
</script>