<template>
  <TransportLayout>
    <div class="container mx-auto px-4 py-6">
      <h2 class="text-2xl font-bold mb-6 text-gray-800">จัดการข้อมูลพนักงาน</h2>

      <!-- Search and Action Bar -->
      <div class="mb-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 items-center">
        <div class="w-full md:flex-grow">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="ค้นหาพนักงาน (ชื่อ, นามสกุล, หน่วยงาน)..."
            class="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        
        <div class="flex space-x-4">
          <button 
            @click="deleteSelectedEmployees" 
            :disabled="isLoading || selectedEmployees.length === 0"
            class="bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg transition duration-300 
                   disabled:opacity-50 flex items-center"
          >
            <i class="fas fa-trash-alt mr-2"></i> ลบพนักงานที่เลือก ({{ selectedEmployees.length }})
          </button>
          
          <button 
            @click="openAddModal" 
            :disabled="isLoading"
            class="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg transition duration-300 
                   disabled:opacity-50 flex items-center"
          >
            <i class="fas fa-plus mr-2"></i> เพิ่มพนักงาน
          </button>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="isLoading" class="flex justify-center items-center py-6">
        <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
      </div>

      <!-- Employee Table -->
      <div v-else-if="filteredEmployees.length" class="overflow-x-auto rounded-lg shadow-md">
        <table class="w-full bg-white">
          <thead class="bg-gray-100">
            <tr>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input 
                  type="checkbox" 
                  @change="toggleSelectAll" 
                  :checked="isAllSelected"
                />
              </th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ลำดับ</th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อ-นามสกุล</th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงาน</th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">กลุ่มงาน</th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เบอร์โทร</th>
              <th class="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">การดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(employee, index) in filteredEmployees" 
              :key="employee.id" 
              class="border-b hover:bg-gray-50"
            >
              <td class="p-3">
                <input 
                  type="checkbox" 
                  v-model="selectedEmployees" 
                  :value="employee.id" 
                />
              </td>
              <td class="p-3">{{ index + 1 }}</td>
              <td class="p-3">
                {{ employee.prefix }} {{ employee.firstName }} {{ employee.lastName }}
              </td>
              <td class="p-3">{{ employee.department }}</td>
              <td class="p-3">{{ employee.group || 'ไม่ระบุ' }}</td>
              <td class="p-3">{{ employee.phone || '-' }}</td>
              <td class="p-3 text-center">
                <div class="flex justify-center space-x-2">
                  <button 
                    @click="openEditModal(employee)" 
                    class="text-blue-500 hover:text-blue-600"
                  >
                    <!-- แก้ไข -->
                    <i class="fas fa-edit"></i>
                  </button>
                  <button 
                    @click="confirmDelete(employee.id)" 
                    class="text-red-500 hover:text-red -600"
                  >
                    <!-- ลบ -->
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- No Data State -->
      <div v-else class="text-center py-6 bg-gray-100 rounded-lg">
        <p class="text-gray-600">ไม่มีข้อมูลพนักงาน</p>
      </div>
      <div class="flex justify-between items-center mt-4">
  <button
    @click="currentPage--"
    :disabled="currentPage === 1"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ก่อนหน้า
  </button>
  <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
  <button
    @click="currentPage++"
    :disabled="currentPage === totalPages"
    class="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
  >
    ถัดไป
  </button>
</div>
      <!-- Employee Modal -->
      <div v-if="isModalVisible" class="fixed inset-0 z-50 flex items-center justify-center">
        <div class="bg-white rounded-lg shadow-xl w-full max-w-2xl p-6">
          <h2 class="text-xl font-bold mb-4">
            {{ isEditing ? 'แก้ไขข้อมูลพนักงาน' : 'เพิ่มพนักงาน' }}
          </h2>
          
          <form @submit.prevent="submitEmployee">
            <div class="grid grid-cols-2 gap-4">
              <!-- Departments Dropdown -->
              <div>
                <label class="block mb-2">หน่วยงาน</label>
                <select 
                  v-model="employeeForm.departmentId" 
                  required 
                  class="w-full border rounded p-2"
                >
                <option value="">เลือกกลุ่มงาน</option>
                  <option 
                    v-for="dept in departments" 
                    :key="dept.id" 
                    :value="dept.id"
                  >
                    {{ dept.name }}
                  </option>
                </select>
              </div>

              <!-- Groups Dropdown -->
              <div>
                <label class="block mb-2">กลุ่มงาน</label>
                <select 
                  v-model="employeeForm.groupId" 
                  class="w-full border rounded p-2"
                >
                  <option value="">เลือกกลุ่มงาน</option>
                  <option 
                    v-for="group in groups" 
                    :key="group.id" 
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>

              <!-- Prefix Dropdown -->
              <div>
                <label class="block mb-2">คำนำหน้า *</label>
                <select 
                  v-model="employeeForm.prefix" 
                  required 
                  class="w-full border rounded p-2"
                >
                  <option value="">เลือกคำนำหน้า</option>
                  <option value="นาย">นาย</option>
                  <option value="นางสาว">นางสาว</option>
                  <option value="นาง">นาง</option>
                </select>
              </div>
              
              <div>
                <label class="block mb-2">ชื่อจริง *</label>
                <input 
                  v-model="employeeForm.firstName" 
                  required 
                  class="w-full border rounded p-2" 
                />
              </div>
              
              <div>
                <label class="block mb-2">นามสกุล *</label>
                <input 
                  v-model="employeeForm.lastName" 
                  required 
                  class="w-full border rounded p-2" 
                />
              </div>
              
              <div>
                <label class="block mb-2">เบอร์โทร</label>
                <input 
                  v-model="employeeForm.phoneNumber" 
                  class="w-full border rounded p-2" 
                />
              </div>
            </div>

            <div class="flex justify-end space-x-4 mt-6">
              <button 
                type="button" 
                @click="closeModal" 
                class="bg-gray-200 px-4 py-2 rounded"
              >
                ยกเลิก
              </button>
              <button 
                type="submit" 
                class="bg-blue-600 text-white px-4 py-2 rounded"
              >
                บันทึก
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </TransportLayout>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import TransportLayout from '@/components/transport/TransportSidebar.vue';

export default {
  components: { TransportLayout },
  data() {
    return {
      employees: [],
      departments: [],
      groups: [],
      searchQuery: '',
      selectedEmployees: [],
      isLoading: false,
      isModalVisible: false,
      isEditing: false,
      editingEmployeeId: null,
      employeeForm: {
        departmentId: '',
        groupId: null,
        prefix: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter(employee => 
        `${employee.firstName} ${employee.lastName} ${employee.department}`
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedEmployees() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredEmployees.slice(start, start + this.itemsPerPage);
  },
  totalPages() {
    return Math.ceil(this.filteredEmployees.length / this.itemsPerPage);
  },
    isAllSelected() {
      return this.selectedEmployees.length === this.filteredEmployees.length;
    }
  },
  mounted() {
    this.fetchInitialData();
  },
  methods: {
    changePage(page) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
  },
    async fetchInitialData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.fetchEmployees(),
          this.fetchDepartments(),
          this.fetchGroups()
        ]);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchEmployees() {
      const response = await axios.get('https://brk-group.org/api/ts/employees');
      this.employees = response.data;
    },
    async fetchDepartments() {
      const response = await axios.get('https://brk-group.org/api/ts/departments');
      this.departments = response.data;
    },
    async fetchGroups() {
      const response = await axios.get('https://brk-group.org/api/ts/groups');
      this.groups = response.data;
    },
    openAddModal() {
      this.resetForm();
      this.isEditing = false;
      this.isModalVisible = true;
    },
    openEditModal(employee) {
      this.isEditing = true;
      this.editingEmployeeId = employee.id;
      this.employeeForm = {
        departmentId: employee.departmentId,
        groupId: employee.groupId,
        prefix: employee.prefix,
        firstName: employee.firstName,
        lastName: employee.lastName,
        phoneNumber: employee.phone
      };
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.employeeForm = {
        departmentId: '',
        groupId: null,
        prefix: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      };
      this.editingEmployeeId = null;
    },
    async submitEmployee() {
  this.isLoading = true;
  
  // ตรวจสอบให้แน่ใจว่ามีการกรอกข้อมูลในทุกช่องที่จำเป็น
  if (!this.validateForm()) {
    this.isLoading = false;
    this.showErrorAlert('กรุณากรอกข้อมูลให้ครบถ้วน');
    return;
  }

  try {
    const payload = { 
      departmentId: this.employeeForm.departmentId,
      groupId: this.employeeForm.groupId,
      prefix: this.employeeForm.prefix,
      firstName: this.employeeForm.firstName,
      lastName: this.employeeForm.lastName,
      phoneNumber: this.employeeForm.phoneNumber,
      status: this.isEditing ? undefined : 'active' // ใช้ 'active' เมื่อเพิ่มใหม่
    };

    if (this.isEditing) {
      await axios.put(`https://brk-group.org/api/ts/employees/${this.editingEmployeeId}`, payload);
      this.showSuccess('แก้ไขข้อมูลพนักงานสำเร็จ');
    } else {
      await axios.post('https://brk-group.org/api/ts/employees', payload);
      this.showSuccess('เพิ่มพนักงานสำเร็จ');
    }

    await this.fetchEmployees();
    this.closeModal();
  } catch (error) {
    this.showErrorAlert(error);
  } finally {
    this.isLoading = false;
  }
},

// ฟังก์ชันสำหรับตรวจสอบข้อมูล
validateForm() {
  return (
    this.employeeForm.departmentId &&
    this.employeeForm.groupId &&
    this.employeeForm.prefix &&
    this.employeeForm.firstName &&
    this.employeeForm.lastName
  );
},
    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'ยืนยันการลบ',
        text: 'คุณต้องการลบพนักงานรายนี้ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'ใช่, ลบเลย',
        cancelButtonText: 'ยกเลิก'
      });

      if (result.isConfirmed) {
        await this.deleteEmployee(id);
      }
    },
    async deleteEmployee(id) {
      this.isLoading = true;
      try {
        await axios.delete(`https://brk-group.org/api/ts/employees/${id}`);
        this.showSuccess('ลบพนักงานสำเร็จ');
        await this.fetchEmployees();
        this.selectedEmployees = this.selectedEmployees.filter(empId => empId !== id);
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteSelectedEmployees() {
  if (this.selectedEmployees.length === 0) return;

  const result = await Swal.fire({
    title: 'ยืนยันการลบ',
    text: `คุณต้องการลบพนักงาน ${this.selectedEmployees.length} คนใช่หรือไม่?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'ใช่, ลบเลย',
    cancelButtonText: 'ยกเลิก'
  });

  if (result.isConfirmed) {
    this.isLoading = true;
    try {
      await axios.delete('https://brk-group.org/api/ts/employees', {
        data: { ids: this.selectedEmployees } // ส่ง ids ในรูปแบบที่ backend คาดหวัง
      });
      this.showSuccess(`ลบพนักงาน ${this.selectedEmployees.length} คนสำเร็จ`);
      await this.fetchEmployees();
      this.selectedEmployees = [];
    } catch (error) {
      this.showErrorAlert(error);
    } finally {
      this.isLoading = false;
    }
  }
},
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedEmployees = [];
      } else {
        this.selectedEmployees = this.filteredEmployees.map(emp => emp .id);
      }
    },
    showSuccess(message) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: message,
        timer: 2000,
        showConfirmButton: false
      });
    },
    showErrorAlert(message, error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: message,
        confirmButtonText: 'ตกลง'
      });
    }
  }
}
</script>