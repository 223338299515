<!-- <template>
  <div class="approve-po">
    <div class="sidebar">
      <ul>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('approvalPending')">อนุมัติใบสั่งซื้อ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('approvePurchaseRequest')">อนุมัติใบขอซื้อ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('statusChange')">อนุมัติเปลี่ยนสถานะ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('productImport')">อนุมัตินำเข้าสินค้า</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('productTransfer')">อนุมัติโอนย้ายสินค้า</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('stockCheck')">อนุมัติตรวจสอบคลัง</a>
      </ul>
    </div>


    <div class="content">
      <div v-if="activeSection === 'approvalPending'">
      <h1 class="text-lg font-bold">การอนุมัติ</h1><br>
      <div class="filters">
        <input type="text" v-model="search.name" placeholder="ค้นหาชื่อ">
        <select v-model="search.status">
          <option value="">ค้นหาสถานะ</option>
          <option value="ยกเลิกใบสั่งซื้อ">ยกเลิกใบสั่งซื้อ</option>
          <option value="รอผู้จัดการฝ่ายจัดซื้อ">รอผู้จัดการฝ่ายจัดซื้อ</option>
          <option value="รอการอนุมัติ">รอการอนุมัติ</option>
          <option value="รอการสั่งซื้อ">รอการสั่งซื้อ</option>
          <option value="รอรับอะไหล่">รอรับอะไหล่</option>
          <option value="รับอะไหล่แล้ว">รับอะไหล่แล้ว</option>
        </select>
        <input type="date" v-model="search.startDate">
        <input type="date" v-model="search.endDate">
      </div>
      <table class="approval-table">
        <thead>
          <tr>
            <th>ชื่อ</th>
            <th>เลขที่ใบสั่งซื้อ</th>
            <th>วันที่แจ้งซ่อม</th>
            <th>สถานที่รับอะไหล่</th>
            <th>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredItems" :key="item.po_id" @click="goToDetail(item.po_id, item.status)" :class="getStatusColorClass(item.status)">
            <td>{{ item.recorder_name }}</td>
            <td>{{ item.po_number }}</td>
            <td>{{ formatDate(item.po_date) }}</td>
            <td>{{ item.warehouse_name }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">ย้อนกลับ</button>
        <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
        <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">ถัดไป</button>
      </div>
    </div>


    <div v-if="activeSection === 'approvePurchaseRequest'">
  <h1>อนุมัติใบขอซื้อ</h1>
  <div class="filters">
    <input type="text" v-model="search.name" placeholder="ค้นหาชื่อ">
    <input type="date" v-model="search.startDate">
    <input type="date" v-model="search.endDate">
  </div>
  <table class="approval-table">
    <thead>
      <tr>
        <th>ชื่อ</th>
        <th>เลขที่ใบขอซื้อ</th>
        <th>วันที่ขอซื้อ</th>
        <th>หน่วยงาน</th>
        <th>สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="request in filteredApprovePurchaseRequests" :key="request.pr_id" @click="goToPRDetail(request.pr_id)">
        <td>{{ request.recorder_name }}</td>
        <td>{{ request.purchase_request_number }}</td>
        <td>{{ formatDate(request.request_date) }}</td>
        <td>{{ request.warehouse_name }}</td>
        <td>{{ request.status_pr }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
    <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">Previous</button>
    <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
    <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">Next</button>
  </div>
  </div>



</div>
</div>
</template> -->
<template>
  <div class="approve-po grid grid-cols-12">
    <div class="sidebar col-span-2 bg-gray-100 h-full p-4">
      <ul class="space-y-2">
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'approvalPending' }" @click="setActiveSection('approvalPending')">
          <i class="fas fa-file-alt mr-2"></i> อนุมัติใบสั่งซื้อ
        </router-link>
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'approvePurchaseRequest' }" @click="setActiveSection('approvePurchaseRequest')">
          <i class="fas fa-file-signature mr-2"></i> อนุมัติใบขอซื้อ
        </router-link>
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'statusChange' }" @click="setActiveSection('statusChange')">
          <i class="fas fa-exchange-alt mr-2"></i> อนุมัติเปลี่ยนสถานะ
        </router-link>
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'productImport' }" @click="setActiveSection('productImport')">
          <i class="fas fa-box-open mr-2"></i> อนุมัตินำเข้าสินค้า
        </router-link>
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'productTransfer' }" @click="setActiveSection('productTransfer')">
          <i class="fas fa-shipping-fast mr-2"></i> อนุมัติโอนย้ายสินค้า
        </router-link>
        <router-link to="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" :class="{ 'bg-gray-300': activeSection === 'stockCheck' }" @click="setActiveSection('stockCheck')">
          <i class="fas fa-warehouse mr-2"></i> อนุมัติตรวจสอบคลัง
        </router-link>
      </ul>
    </div>

    <div class="content col-span-10 p-6">
      <div v-if="activeSection === 'approvalPending'">
        <h1 class="text-lg font-bold mb-4">การอนุมัติใบสั่งซื้อ</h1>

        

        <div class="filters flex space-x-4 mb-4">
          <input type="text" v-model="search.name" class="p-2 border border-gray-300 rounded w-1/4" placeholder="ค้นหาชื่อ">
          <select v-model="search.status" class="p-2 border border-gray-300 rounded w-1/4">
            <option value="">ค้นหาสถานะ</option>
            <option value="ยกเลิกใบสั่งซื้อ">ยกเลิกใบสั่งซื้อ</option>
            <option value="รอผู้จัดการฝ่ายจัดซื้อ">รอผู้จัดการฝ่ายจัดซื้อ</option>
            <option value="รอการอนุมัติ">รอการอนุมัติ</option>
            <option value="รอการสั่งซื้อ">รอการสั่งซื้อ</option>
            <option value="รอรับอะไหล่">รอรับอะไหล่</option>
            <option value="รับอะไหล่แล้ว">รับอะไหล่แล้ว</option>
          </select>
          <input type="date" v-model="search.startDate" class="p-2 border border-gray-300 rounded w-1/4">
          <input type="date" v-model="search.endDate" class="p-2 border border-gray-300 rounded w-1/4">
          <button @click="clearFilters" class="p-2 bg-gray-500 text-white rounded">ล้างค่า</button>
        </div>
  <!-- สถานะแต่ละสี (Legend) -->
  <div class="status-legend mb-4">
          <div class="flex items-center space-x-4">
            <!-- Example of status with color indicators -->
            <div class="flex items-center">
              <span class="bg-red-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-red-700 font-bold">ยกเลิกใบสั่งซื้อ</span>
            </div>
            <div class="flex items-center">
              <span class="bg-yellow-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-yellow-700 font-bold">รอผู้จัดการฝ่ายจัดซื้อ</span>
            </div>
            <div class="flex items-center">
              <span class="bg-blue-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-blue-700 font-bold">รอการอนุมัติ</span>
            </div>
            <div class="flex items-center">
              <span class="bg-green-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-green-700 font-bold">รอการสั่งซื้อ</span>
            </div>
            <div class="flex items-center">
              <span class="bg-indigo-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-indigo-700 font-bold">รอรับอะไหล่</span>
            </div>
            <div class="flex items-center">
              <span class="bg-gray-200 w-4 h-4 inline-block mr-2 rounded-full"></span>
              <span class="text-gray-700 font-bold">รับอะไหล่แล้ว</span>
            </div>
          </div>
        </div>
       <!-- Card Display -->
       <!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div 
          v-for="item in filteredItems" 
          :key="item.po_id" 
          @click="goToDetail(item.po_id, item.status)" 
          class="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition cursor-pointer border border-gray-200"
          :class="getStatusColorClass(item.status)"
        >
      
          <h2 class="font-bold text-xl mb-2">{{ item.recorder_name }}</h2>
          
    
          <p class="text-gray-700 font-bold"><strong>เลขที่ใบสั่งซื้อ:</strong> {{ item.po_number }}</p>
          <p class="text-gray-700 font-bold"><strong>วันที่แจ้งซ่อม:</strong> {{ formatDate(item.po_date) }}</p>
          <p class="text-gray-700 font-bold"><strong>สถานที่รับอะไหล่:</strong> {{ item.warehouse_name }}</p>

          <div class="mt-4">
            <span :class="statusBadgeClass(item.status)">
              {{ item.status }}
            </span>
          </div>
        </div>
      </div> -->


      <!-- Table Display -->
<div class="overflow-x-auto">
  <table class="min-w-full bg-white shadow-md rounded-lg border border-gray-200">
    <thead>
      <tr>
        <th class="px-4 py-2 border-b font-semibold text-center">ชื่อ</th>
        <th class="px-4 py-2 border-b font-semibold text-center">เลขที่ใบสั่งซื้อ</th>
        <th class="px-4 py-2 border-b font-semibold text-center">ทะเบียนรถ</th>
        <th class="px-4 py-2 border-b font-semibold text-center">วันที่แจ้งซ่อม</th>
        <th class="px-4 py-2 border-b font-semibold text-center">สถานที่รับอะไหล่</th>
        <th class="px-4 py-2 border-b font-semibold text-center">สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr 
        v-for="item in filteredItems" 
        :key="item.po_id" 
        @click="goToDetail(item.po_id, item.status)" 
        class="hover:bg-gray-100 transition cursor-pointer"
        :class="getStatusColorClass(item.status)"
      >
        <!-- ชื่อ -->
        <td class="px-4 py-2 border-b">{{ item.recorder_name }}</td>
        
        <!-- เลขที่ใบสั่งซื้อ -->
        <td class="px-4 py-2 border-b">{{ item.po_number }}</td>
        
        <!-- ทะเบียนรถ -->
        <td class="px-4 py-2 border-b">{{ item.LicensePlate }}</td>

        <!-- วันที่แจ้งซ่อม -->
        <td class="px-4 py-2 border-b">{{ formatDate(item.po_date) }}</td>
        
        <!-- สถานที่รับอะไหล่ -->
        <td class="px-4 py-2 border-b">{{ item.warehouse_name }}</td>
        
        <!-- สถานะ -->
        <td class="px-4 py-2 border-b">
          <span :class="statusBadgeClass(item.status)">
            {{ item.status }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>


        <div class="pagination mt-4 flex justify-between items-center">
          <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)" class="bg-gray-500 text-white p-2 rounded">ย้อนกลับ</button>
          <div>หน้า {{ currentPage }} จาก {{ totalPages }}</div>
          <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)" class="bg-gray-500 text-white p-2 rounded">ถัดไป</button>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ApprovePO',
  data() {
    return {
      activeSection: 'approvalPending',
      search: {
        name: '',
        status: '',
        startDate: '',
        endDate: ''
      },
      items: [],
      approvePurchaseRequests: [], // เพิ่มส่วนนี้สำหรับการดึงข้อมูลใบขอซื้อ
      user: {},
      currentPage: 1,
      itemsPerPage: 12,
    };
  },
  computed: {
    filteredItems() {
    const filtered = this.items.filter(item => {
      const recorderName = item.recorder_name || ''; 
      const status = item.status || ''; 
      const poDate = item.po_date ? new Date(item.po_date) : null; 
      const startDate = this.search.startDate ? new Date(this.search.startDate) : null;
      const endDate = this.search.endDate ? new Date(this.search.endDate) : null;

      return (
        recorderName.includes(this.search.name) && 
        status.includes(this.search.status) && 
        (!startDate || (poDate && poDate >= startDate)) && 
        (!endDate || (poDate && poDate <= endDate))
      );
    });

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = this.currentPage * this.itemsPerPage;
    return filtered.slice(start, end);
  },
    filteredApprovePurchaseRequests() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.approvePurchaseRequests.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }
  },
  async created() {
      const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }

     
      this.fetchData();
      this.fetchApprovePurchaseRequests();
    },
 
  methods: {
    clearFilters() {
      // รีเซ็ตค่า search กลับไปเป็นค่าเริ่มต้น
      this.search = {
        name: '',
        status: '',
        startDate: '',
        endDate: ''
      };
      this.filterItems(); // เรียกฟังก์ชันเพื่อทำการกรองข้อมูลใหม่
    },
    filterItems() {
      // ฟังก์ชันกรองข้อมูลตามค่า search
      this.filteredItems = this.items.filter(item => {
        const matchesName = !this.search.name || item.recorder_name.includes(this.search.name);
        const matchesStatus = !this.search.status || item.status === this.search.status;
        const matchesDateRange = (!this.search.startDate || new Date(item.po_date) >= new Date(this.search.startDate)) &&
                                 (!this.search.endDate || new Date(item.po_date) <= new Date(this.search.endDate));
        
        return matchesName && matchesStatus && matchesDateRange;
      });
    },
    getStatusColorClass(status) {
    switch (status) {
      case 'ยกเลิกใบสั่งซื้อ':
        return 'bg-red-50 text-black-100';
      case 'รอผู้จัดการฝ่ายจัดซื้อ':
        return 'bg-yellow-50 text-black-100';
      case 'รอการอนุมัติ':
        return 'bg-orange-50 text-black-100';
      case 'รอการสั่งซื้อ':
        return 'bg-blue-50 text-black-100';
      case 'รอรับอะไหล่':
        return 'bg-purple-50 text-black-100';
      case 'รับอะไหล่แล้ว':
        return 'bg-green-100 text-black-100';
      default:
        return ''; // หรือ class อื่นๆ สำหรับสถานะที่ไม่ได้ระบุ
    }
  },
  statusBadgeClass(status) {
      switch (status) {
        case 'ยกเลิกใบสั่งซื้อ':
          return 'text-red-700 bg-red-200 p-1 rounded';
        case 'รอผู้จัดการฝ่ายจัดซื้อ':
          return 'text-yellow-700 bg-yellow-200 p-1 rounded';
        case 'รอการอนุมัติ':
          return 'text-blue-700 bg-blue-200 p-1 rounded';
        case 'รอการสั่งซื้อ':
          return 'text-green-700 bg-green-200 p-1 rounded';
        case 'รอรับอะไหล่':
          return 'text-indigo-700 bg-indigo-200 p-1 rounded';
        case 'รับอะไหล่แล้ว':
          return 'text-gray-700 bg-gray-200 p-1 rounded';
        default:
          return 'text-gray-700 bg-gray-200 p-1 rounded';
      }
    },
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        // console.log(this.user); // ตรวจสอบข้อมูลผู้ใช้
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    goToDetail(poId, status) {
    const query = status === 'รอการอนุมัติ' ? { fromApprove: true } : {};
    this.$router.push({ name: 'PODetail', params: { poId }, query });
    },
    goToPRDetail(prId) {
    this.$router.push({ name: 'PRDetail', params: { id: prId }, query: { fromApprovePO: true } });
  },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    setActiveSection(section) {
      this.activeSection = section;
      this.fetchData();
      this.fetchApprovePurchaseRequests();
    },
    changePage(page) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  },
    fetchData() {
      axios.get('https://brk-group.org/api/approve_purchase_orders')
        .then(response => {
          // เรียงข้อมูลตาม po_id โดยเรียงจากมากไปน้อย
          this.items = response.data.sort((a, b) => b.po_id - a.po_id);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    fetchApprovePurchaseRequests() {
    axios.get('https://brk-group.org/api/approve_purchase_requests')
      .then(response => {
        this.approvePurchaseRequests = response.data;
      })
      .catch(error => {
        console.error('Error fetching purchase requests:', error);
      });
  }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.approve-po {
  display: flex;
  flex-direction: column;
}

.sidebar {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.sidebar li.active {
  background-color: #ddd;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 5px;
  flex: 1;
  min-width: 150px;
}

.approval-table {
  width: 100%;
  border-collapse: collapse;
}

.approval-table th, .approval-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.approval-table th {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination .active {
  font-weight: bold;
}

@media (min-width: 600px) {
  .approve-po {
    flex-direction: row;
  }

  .sidebar {
    width: 250px;
    flex-direction: column;
  }

  .sidebar ul {
    flex-direction: column;
  }

  .sidebar li {
    text-align: left;
  }

  .filters {
    flex-direction: row;
  }
}

.bg-red-500 { background-color: #ef4444; } /* สีแดง */
.bg-yellow-500 { background-color: #eab308; } /* สีเหลือง */
.bg-orange-500 { background-color: #f97316; } /* สีส้ม */
.bg-blue-400 { background-color: #60a5fa; } /* สีฟ้า */
.bg-purple-500 { background-color: #a855f7; } /* สีม่วง */
.bg-green-500 { background-color: #22c55e; } /* สีเขียว */
.text-white-100 { color: white; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; } /* ปรับระยะห่างด้านข้าง */
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; } /* ปรับระยะห่างด้านบนล่าง */
.rounded-md { border-radius: 0.375rem; } /* ปรับความโค้งมุม */
</style>