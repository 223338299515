<template>
  <div class="container mx-auto p-4 sm:p-6 max-w-4xl">
    <h1 class="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">สรุปรายการรถ</h1>

    <!-- Input สำหรับการเปิด Modal -->
    <div class="mb-4 sm:mb-6">
      <button 
        @click="openModal"
        class="border border-gray-300 p-2 rounded w-full text-left"
      >
        {{ selectedVehicle ? selectedVehicle.VehicleNumber : 'เลือกทะเบียนรถ...' }}
      </button>
    </div>

    <!-- แสดงข้อมูลรถที่เลือก -->
    <div v-if="selectedVehicle" class="mb-6 p-4 border rounded shadow">
      <h2 class="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">รายละเอียดรถ: {{ selectedVehicle.VehicleNumber }}</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <p><strong>ร้านคู่ค้า:</strong> {{ selectedVehicle.supplier_name }}</p>
        <p><strong>ยี่ห้อ:</strong> {{ selectedVehicle.brand }}</p>
        <p><strong>รุ่น:</strong> {{ selectedVehicle.model }}</p>
      </div>
      <!-- Element สำหรับกราฟ -->
      <div class="mt-4 sm:mt-6 w-full h-64 sm:h-80 md:h-96" ref="chart"></div>
    </div>

<!-- ส่วนของ div สำหรับแสดงข้อมูลจำนวนการซ่อมในแต่ละเดือน -->
<div v-if="repairData.length > 0" class="mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
  <div 
    v-for="(data, index) in repairData" 
    :key="index" 
    :style="{ backgroundColor: getBackgroundColor(index) }" 
    class="p-4 text-black border rounded shadow hover:shadow-lg transition-shadow duration-200"
  >
    <h3 class="text-lg font-semibold mb-2">เดือน: {{ data.repair_month }}</h3>
    <p>จำนวนการซ่อม: <strong>{{ data.repair_count }}</strong> ครั้ง</p>
  </div>
</div>


<!-- ตารางแสดงประวัติการซ่อม -->
<div v-if="repairHistory.length > 0" class="mb-6">
  <h2 class="text-lg sm:text-xl font-semibold mb-3">ประวัติการซ่อม</h2>
  <table class="min-w-full bg-white border border-gray-300">
    <thead>
      <tr class="bg-gray-100">
        <th class="py-2 px-4 border-b">วันที่ขอซ่อม</th>
        <th class="py-2 px-4 border-b">รายละเอียด</th>

        <th class="py-2 px-4 border-b">ใบ PR</th>
        <th class="py-2 px-4 border-b">สถานะ PR</th>
        <th class="py-2 px-4 border-b">ใบ PO</th>
        <th class="py-2 px-4 border-b">สถานะ PO</th>
      </tr>
    </thead>
    <tbody>
      <tr 
        v-for="(repair, index) in repairHistory" 
        :key="index" 
        class="hover:bg-gray-50 cursor-pointer"
        @click="repair.po_number ? fetchPOItems(repair.po_number) : null"
      >
        <td class="py-2 px-4 border-b">{{ formatDate(repair.request_date) }}</td>
        <td class="py-2 px-4 border-b">{{ repair.details }}</td>

        <td class="py-2 px-4 border-b">{{ repair.pr_number || '-' }}</td>
        <td class="py-2 px-4 border-b">{{ repair.status_pr }}</td>
        <td class="py-2 px-4 border-b">{{ repair.po_number || '-' }}</td>
        <td class="py-2 px-4 border-b">{{ repair.po_status || '-' }}</td>
      </tr>
    </tbody>
  </table>
</div>


    <!-- Modal สำหรับการค้นหาหมายเลขทะเบียนรถ -->
    <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-white w-11/12 sm:w-3/4 lg:w-1/2 p-4 rounded-lg shadow-lg">
        <h2 class="text-xl sm:text-2xl font-bold mb-4">ค้นหาทะเบียนรถ</h2>
        <input 
          v-model="modalSearchQuery"
          @input="searchVehiclesInModal"
          type="text" 
          placeholder="ค้นหาทะเบียนรถ..."
          class="border border-gray-300 p-2 rounded w-full mb-4"
        />

        <ul class="max-h-64 overflow-y-auto">
          <li 
            v-for="vehicle in filteredVehicles" 
            :key="vehicle.id" 
            @click="selectVehicle(vehicle)"
            class="p-2 cursor-pointer hover:bg-gray-100"
          >
          {{ vehicle.VehicleNumber }} - ID: {{ vehicle.VehicleID }}
          </li>
        </ul>

        <button 
          @click="closeModal"
          class="mt-4 w-full bg-red-500 text-white p-2 rounded"
        >
          ปิด
        </button>
      </div>
    </div>

<!-- Modal สำหรับแสดงรายการสินค้าที่อยู่ในใบ PO -->
<div v-if="isPOItemsModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div class="bg-white w-11/12 sm:w-3/4 lg:w-1/2 p-4 rounded-lg shadow-lg">
    <h2 class="text-xl sm:text-2xl font-bold mb-4">รายการสินค้าในใบ PO</h2>
    <table class="min-w-full bg-white border border-gray-300 mb-4">
      <thead>
        <tr class="bg-gray-100">
          <th class="py-2 px-4 border-b">ชื่อสินค้า</th>
          <th class="py-2 px-4 border-b">จำนวน</th>
          <th class="py-2 px-4 border-b">ราคาต่อหน่วย</th>
          <th class="py-2 px-4 border-b">ยอดรวม</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in poItems" :key="index" class="hover:bg-gray-50">
          <td class="py-2 px-4 border-b">{{ item.product_name }}</td>
          <td class="py-2 px-4 border-b">{{ item.quantity }}</td>
          <td class="py-2 px-4 border-b">{{ item.unit_price }}</td>
          <td class="py-2 px-4 border-b">{{ (item.quantity * item.unit_price).toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="text-right font-bold mb-4">
      ยอดรวมทั้งหมด: {{ calculateTotalPrice() }} บาท
    </div>
    <button 
      @click="closePOItemsModal"
      class="mt-4 w-full bg-red-500 text-white p-2 rounded"
    >
      ปิด
    </button>
  </div>
</div>








    <!-- กรณีไม่มีข้อมูลแสดงข้อความ -->
    <div v-if="!selectedVehicle && searchQuery" class="text-red-500 text-center mt-4">ไม่พบข้อมูลรถตามที่ค้นหา</div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
import Swal from 'sweetalert2';


export default {
  data() {
    return {
      searchQuery: '',
      modalSearchQuery: '',
      selectedVehicle: null,
      vehicles: [],
      filteredVehicles: [],
      repairHistory: [], // เก็บประวัติการซ่อม
      repairData: [], // เก็บข้อมูลการซ่อมต่อเดือน
      poItems: [], // เก็บรายการสินค้าที่ดึงมาจากใบ PO
      chartInstance: null,
      isModalOpen: false,
      isPOItemsModalOpen: false, // สถานะของ modal สำหรับแสดงสินค้าใน PO
    };
  },
  methods: {
    async fetchPOItems(poNumber) {
    try {
      const response = await axios.get('https://brk-group.org/api/po-items', {
        params: {
          poNumber: poNumber,
        },
      });

      if (response.data.items) {
        this.poItems = response.data.items;
        this.openPOItemsModal();
      } else {
        this.poItems = [];
        Swal.fire({
          icon: 'warning',
          title: 'ไม่พบข้อมูลสินค้า',
          text: 'ไม่พบข้อมูลสินค้าในใบ PO นี้',
        });
      }
    } catch (error) {
      console.error('Error fetching PO items:', error);
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: `ไม่สามารถดึงข้อมูลได้: ${error.response?.data?.error || error.message}`,
      });
    }
  },
  openPOItemsModal() {
    this.isPOItemsModalOpen = true;
  },
  closePOItemsModal() {
    this.isPOItemsModalOpen = false;
    this.poItems = []; // รีเซ็ตข้อมูลสินค้าเมื่อปิด modal
  },
  calculateTotalPrice() {
    return this.poItems.reduce((total, item) => {
      return total + item.quantity * item.unit_price;
    }, 0).toFixed(2);
  },
    getBackgroundColor(index) {
    const colors = ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB', '#B3E5FC', '#B2EBF2', '#B2DFDB', '#C8E6C9', '#DCEDC8', '#F0F4C3'];
    return colors[index % colors.length];
  },
    formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('th-TH', options);
  },
    async searchVehiclesInModal() {
      if (this.modalSearchQuery.trim() === '') {
        this.filteredVehicles = [];
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/vehicle-repair-summary', {
          params: {
            vehicleNumber: this.modalSearchQuery,
          },
        });

        if (response.data.vehicles && response.data.vehicles.length > 0) {
          this.filteredVehicles = response.data.vehicles;
        } else {
          this.filteredVehicles = [];
        }
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.modalSearchQuery = '';
      this.filteredVehicles = [];
    },
    async selectVehicle(vehicle) {
  // ตรวจสอบโครงสร้างของ vehicle
  console.log('Vehicle data:', JSON.stringify(vehicle, null, 2));

  if (!vehicle || !vehicle.VehicleID) {
    console.error('Vehicle data is missing or invalid:', vehicle);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่พบ Vehicle ID หรือข้อมูลยานพาหนะไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
    });
    return;
  }

  this.selectedVehicle = vehicle;
  this.closeModal();
  
  try {
    // เรียก API เพื่อดึงข้อมูลการซ่อมแซมสำหรับกราฟ
    const chartResponse = await axios.get('https://brk-group.org/api/vehicle-repair-details', {
      params: {
        vehicleId: vehicle.VehicleID,
      },
    });

    if (chartResponse.data.repairData && chartResponse.data.repairData.length > 0) {
      this.repairData = chartResponse.data.repairData; 
      this.$nextTick(() => {
        this.initChart(chartResponse.data.repairData);
      });
    } else {
      this.repairData = [];
      Swal.fire({
        icon: 'warning',
        title: 'ไม่พบข้อมูลการซ่อมแซม',
        text: 'ไม่พบข้อมูลการซ่อมแซมสำหรับรถคันนี้',
      });
    }

    // เรียก API เพื่อดึงข้อมูลประวัติการซ่อมแซม
    const historyResponse = await axios.get('https://brk-group.org/api/vehicle-repair-history', {
      params: {
        vehicleId: vehicle.VehicleID,
      },
    });

    if (historyResponse.data.repairHistory && historyResponse.data.repairHistory.length > 0) {
      this.repairHistory = historyResponse.data.repairHistory;
    } else {
      this.repairHistory = [];
      Swal.fire({
        icon: 'warning',
        title: 'ไม่พบประวัติการซ่อมแซม',
        text: 'ไม่พบประวัติการซ่อมแซมสำหรับรถคันนี้',
      });
    }

  } catch (error) {
    console.error('Error fetching repair data:', error);
    console.error('Error details:', error.response?.data || error.message);

    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: `ไม่สามารถดึงข้อมูลได้: ${error.response?.data?.error || error.message}`,
    });
  }
},
initChart(repairData) {
  // ทำลายกราฟเก่าก่อนที่จะสร้างใหม่
  this.destroyChart();
  
  const chartDom = this.$refs.chart;
  if (!chartDom) {
    console.error('Chart DOM element is not available.');
    return;
  }

  this.chartInstance = echarts.init(chartDom);

  // ตรวจสอบว่า repairData มีข้อมูลที่ถูกต้อง
  if (!repairData || !Array.isArray(repairData) || repairData.length === 0) {
    console.error('repairData is invalid or empty.');
    return;
  }

  // สร้าง array สำหรับเดือนและจำนวนการซ่อมแซม
  const months = repairData.map(item => item.repair_month);
  const repairCounts = repairData.map(item => item.repair_count);
   // สร้าง array สำหรับสีของแต่ละแท่ง
   const colors = [
    '#5470C6', '#91CC75', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', 
    '#9A60B4', '#EA7CCC', '#FAC858', '#B785D6', '#79A2E7', '#FF8C00'
  ];

  const option = {
    title: {
      text: 'จำนวนการซ่อมต่อเดือน',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: months,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'จำนวนการซ่อม',
        type: 'bar',
        data: repairCounts,
        // ใช้สีที่แตกต่างกันสำหรับแต่ละแท่ง
        itemStyle: {
          color: (params) => {
            // กำหนดสีตาม index ของแท่งกราฟ
            return colors[params.dataIndex % colors.length];
          }
        },
        label: {
          show: true,
          position: 'top',
          formatter: '{c}',
        },
      },
    ],
  };

  this.chartInstance.setOption(option);
  this.handleResize = () => {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  };
  window.addEventListener('resize', this.handleResize);
},
    destroyChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
        this.chartInstance = null;
      }
      if (this.handleResize) {
        window.removeEventListener('resize', this.handleResize);
      }
    },
  },
  beforeUnmount() {
    this.destroyChart();
  },
};
</script>
