<template>
  <TransportLayout>
    <div class="container mx-auto p-4">
      <!-- Header Section -->
      <div class="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <h1 class="text-2xl font-bold text-gray-800">ค่าขนส่ง</h1>
        <div class="flex space-x-2">
          <button
            v-if="hasSelectedFees"
            @click="confirmBulkDelete"
            class="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
          >
            <i class="fas fa-trash"></i>
            <span>ลบที่เลือก ({{ selectedFees.length }})</span>
          </button>
          <button
            @click="openModal"
            class="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
          >
            <i class="fas fa-plus"></i>
            <span>เพิ่มค่าขนส่ง</span>
          </button>
        </div>
      </div>

      <!-- Modal for Adding/Editing Fee -->
      <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg w-96">
          <h2 class="text-xl font-bold mb-4">{{ editingFee ? 'แก้ไขค่าขนส่ง' : 'เพิ่มค่าขนส่ง' }}</h2>
          <input 
  type="number" 
  v-model="formData.amount" 
  placeholder="ระบุค่าขนส่ง" 
  step="0.00000001"
  min="0"
  max="9999999999.99999999"
  pattern="^\d{1,10}(\.\d{1,8})?$"
  oninput="
    this.value = this.value.replace(/[^0-9.]/g, '');
    const parts = this.value.split('.');
    if (parts[0].length > 10) parts[0] = parts[0].slice(0, 10);
    if (parts.length > 1) parts[1] = parts[1].slice(0, 8);
    this.value = parts.join('.');
  "
  class="w-full p-2 border rounded mb-4"
/>
          <div class="flex justify-end space-x-2">
            <button 
              @click="closeModal" 
              class="bg-gray-200 text-gray-800 px-4 py-2 rounded"
            >
              ยกเลิก
            </button>
            <button 
              @click="saveFee" 
              class="bg-blue-600 text-white px-4 py-2 rounded"
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>

      <!-- Loader -->
      <div v-if="isLoading" class="flex justify-center items-center py-4">
        <svg class="animate-spin h-10 w-10 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
        </svg>
      </div>

      <!-- Fees Table -->
      <div v-if="!isLoading" class="bg-white rounded-lg shadow">
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3">
                  <input
                    type="checkbox"
                    :checked="selectedFees.length === paginatedFees.length"
                    @change="toggleAllFees"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">ลำดับ</th>
                <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">ค่าขนส่ง</th>
                <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">จัดการ</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(fee, index) in paginatedFees" :key="fee.id">
                <td class="px-6 py-4">
                  <input
                    type="checkbox"
                    :checked="selectedFees.includes(fee.id)"
                    @change="toggleFeeSelection(fee.id)"
                    class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </td>
                <td class="px-6 py-4 text-sm">{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                <td class="px-6 py-4">{{ formatCurrency(fee.amount) }}</td>
                <td class="px-6 py-4 text-right">
                  <button @click="editFee(fee)" class="text-blue-600 hover:text-blue-800 mr-2">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button @click="confirmDelete(fee.id)" class="text-red-600 hover:text-red-800">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr v-if="paginatedFees.length === 0">
                <td colspan="4" class="px-6 py-4 text-center text-gray-500">ไม่พบข้อมูลค่าขนส่ง</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination -->
        <div class="flex justify-between items-center mt-4 px-4 pb-4">
          <button
            :disabled="currentPage === 1"
            @click="prevPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg disabled:opacity-50"
          >
            ก่อนหน้า
          </button>
          <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
          <button
            :disabled="currentPage === totalPages"
            @click="nextPage"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg disabled:opacity-50"
          >
            ถัดไป
          </button>
        </div>
      </div>
    </div>
  </TransportLayout>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import TransportLayout from '@/components/transport/TransportSidebar.vue';

export default {
  components: {
    TransportLayout,
  },
  data() {
    return {
      isModalOpen: false,
      editingFee: null,
      formData: { amount: '' },
      selectedFees: [],
      fees: [],
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    paginatedFees() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.fees.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.fees.length / this.itemsPerPage);
    },
    hasSelectedFees() {
      return this.selectedFees.length > 0;
    },
  },
  methods: {
    formatCurrency(value) {
    return `${parseFloat(value).toFixed(8)} บาท`;
  },

    openModal() {
      this.isModalOpen = true;
      this.editingFee = null;
      this.formData = { amount: '' };
    },
    closeModal() {
      this.isModalOpen = false;
      this.editingFee = null;
    },
    async fetchFees() {
      this.isLoading = true;
      try {
        const response = await axios.get('http://localhost:3000/api/ts/fees');
        this.fees = response.data.data;
      } catch (error) {
        Swal.fire('ข้อผิดพลาด!', 'เกิดข้อผิดพลาดในการโหลดข้อมูล', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async saveFee() {
      if (!this.formData.amount) {
        Swal.fire('ข้อผิดพลาด!', 'กรุณาระบุค่าขนส่ง', 'warning');
        return;
      }

      try {
        if (this.editingFee) {
          // Update existing fee
          await axios.put(`http://localhost:3000/api/ts/fees/${this.editingFee.id}`, {
            amount: this.formData.amount
          });
          
          // Update fee in local array
          const index = this.fees.findIndex(f => f.id === this.editingFee.id);
          if (index !== -1) {
            this.fees[index].amount = this.formData.amount;
          }
        } else {
          // Create new fee
          const response = await axios.post('http://localhost:3000/api/ts/fees', {
            amount: this.formData.amount
          });
          
          // Add new fee to local array
          this.fees.unshift({
            id: response.data.id,
            amount: this.formData.amount
          });
        }

        Swal.fire('สำเร็จ!', this.editingFee ? 'แก้ไขค่าขนส่งสำเร็จ' : 'เพิ่มค่าขนส่งสำเร็จ', 'success');
        this.closeModal();
      } catch (error) {
        Swal.fire('ข้อผิดพลาด!', error.response?.data?.message || 'เกิดข้อผิดพลาด', 'error');
      }
    },
    editFee(fee) {
      this.editingFee = fee;
      this.formData.amount = fee.amount;
      this.isModalOpen = true;
    },
    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'ยืนยันการลบ?',
        text: 'คุณต้องการลบค่าขนส่งนี้หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ลบเลย!',
        cancelButtonText: 'ยกเลิก',
      });

      if (result.isConfirmed) {
        this.deleteFee(id);
      }
    },
    async deleteFee(id) {
      try {
        await axios.delete(`http://localhost:3000/api/ts/fees/${id}`);
        this.fees = this.fees.filter(fee => fee.id !== id);
        Swal.fire('ลบเรียบร้อย!', 'ข้อมูลค่าขนส่งถูกลบแล้ว', 'success');
      } catch (error) {
        Swal.fire('ข้อผิดพลาด!', 'เกิดข้อผิดพลาดในการลบข้อมูล', 'error');
      }
    },
    async confirmBulkDelete() {
      const result = await Swal.fire({
        title: 'ยืนยันการลบหลายรายการ?',
        text: `คุณต้องการลบค่าขนส่ง ${this.selectedFees.length} รายการหรือไม่?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ลบเลย!',
        cancelButtonText: 'ยกเลิก',
      });

      if (result.isConfirmed) {
        this.bulkDeleteFees();
      }
    },
    async bulkDeleteFees() {
      try {
        await axios.delete('http://localhost:3000/api/ts/fees/bulk', { 
          data: { ids: this.selectedFees } 
        });
        
        // Remove deleted fees from local array
        this.fees = this.fees.filter(fee => !this.selectedFees.includes(fee.id));
        
        // Clear selected fees
        this.selectedFees = [];
        
        Swal.fire('ลบเรียบร้อย!', `ลบค่าขนส่งสำเร็จ ${this.selectedFees.length} รายการ`, 'success');
      } catch (error) {
        Swal.fire('ข้อผิดพลาด!', 'เกิดข้อผิดพลาดในการลบข้อมูล', 'error');
      }
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    toggleAllFees(event) {
      this.selectedFees = event.target.checked 
        ? this.paginatedFees.map(fee => fee.id) 
        : [];
    },
    toggleFeeSelection(id) {
      if (this.selectedFees.includes(id)) {
        this.selectedFees = this.selectedFees.filter(feeId => feeId !== id);
      } else {
        this.selectedFees.push(id);
      }
    },
    // formatCurrency(value) {
    //   return `${parseFloat(value).toFixed(2)} บาท`;
    // },
  },
  mounted() {
    this.fetchFees();
  },
};
</script>