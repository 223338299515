<template>
  <div class="container mx-auto px-4 py-8 shadow-lg rounded-lg">
    <div class="flex justify-between mb-6">
      <h2 class="text-2xl font-semibold">ประวัติการซ่อม</h2>
      <div class="space-x-4">
        <button 
  @click="setPurchaseType('repair')" 
  :class="['px-4 py-2 rounded', purchaseType === 'repair' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700']">
  ซื้อเพื่อซ่อม
</button>

  <router-link 
    to="/vehicle-summary"
    class="bg-cyan-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-block"
  >
    ไปที่สรุปรายการรถ
  </router-link>

<!-- <button 
  @click="setPurchaseType('stock')" 
  :class="['px-4 py-2 rounded', purchaseType === 'stock' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700']">
  ซื้อเพื่อลงสต็อค
</button> -->
      </div>
    </div>

    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200 shadow overflow-hidden rounded-lg">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียนรถ</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่เอกสาร</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ร้านคู่ค้า</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ PO</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="item in displayedData" :key="item.pr_id">
            <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.VehicleNumber }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.purchase_request_number }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.supplier_name }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.po_status || 'ยังไม่มี PO' }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <button @click="viewDetail(item)" class="text-blue-600 hover:text-blue-900">ดูรายละเอียด</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-between mt-6">
      <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">ย้อนกลับ</button>
      <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">ถัดไป</button>
    </div>

     <!-- Modal -->
     <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
    <div class="bg-white rounded-lg p-6 w-full max-w-2xl max-h-full overflow-y-auto shadow-lg">
      <h3 class="text-2xl font-semibold mb-4 border-b pb-2">รายละเอียดการซ่อม</h3>
      <div v-if="selectedItem">
        <div class="mb-4">
          <p class="font-semibold">ทะเบียนรถ:</p>
          <p class="ml-4">{{ selectedItem.VehicleNumber }}</p>
        </div>
        <div class="mb-4">
          <p class="font-semibold">ร้านคู่ค้า:</p>
          <p class="ml-4">{{ selectedItem.supplier_name }}</p>
        </div>
        <div class="mb-4">
          <p class="font-semibold">เลขที่ PR:</p>
          <p class="ml-4">{{ selectedItem.purchase_request_number }}</p>
        </div>
        <div class="mb-4">
          <p class="font-semibold">เลขที่ PO:</p>
          <p class="ml-4">{{ selectedItem.po_number || 'ยังไม่มี PO' }}</p>
        </div>
        <div class="mb-4">
          <p class="font-semibold">สถานะ PO:</p>
          <p class="ml-4">{{ selectedItem.po_status || 'ยังไม่มี PO' }}</p>
        </div>
        <h4 class="font-semibold mt-6 mb-2 border-t pt-4">รายการสินค้า:</h4>
        <ul class="mb-4">
          <li v-for="(item, index) in selectedItem.items" :key="index" class="mb-4 border-b border-gray-200 pb-2">
            <p><strong>{{ item.product_name }}</strong> ({{ item.product_type }})</p>
            <div class="flex justify-center">
                <img 
                  :src="transformImageUrl(item.image_url)" 
                  alt="Product Image" 
                  class="w-24 h-24 object-cover my-2 cursor-pointer"
                  @click="openImageModal(transformImageUrl(item.image_url))"
                />
              </div>
            <p class="ml-4">จำนวนที่ขอ: {{ item.requested_quantity }} {{ item.unit }}</p>
            <p class="ml-4">ราคาต่อหน่วย: {{ item.requested_unit_price }} บาท</p>
            <p class="ml-4">ราคารวม: {{ item.requested_total_price }} บาท</p>
            <p v-if="item.ordered_quantity" class="ml-4">จำนวนที่สั่งซื้อ: {{ item.ordered_quantity }} {{ item.unit }}</p>
          </li>
        </ul>
      </div>
      <button @click="showModal = false" class="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
        ปิด
      </button>
    </div>
  </div>

   <!-- Modal สำหรับแสดงรูปภาพขนาดใหญ่ -->
   <div v-if="showImageModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div class="relative">
        <img :src="modalImageUrl" alt="Full Size Image" class="max-w-full max-h-screen" />
        <button 
  @click="closeImageModal" 
  class="absolute top-0 right-0 mt-2 mr-2 bg-red-600 text-white text-3xl font-bold rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-700 shadow-lg"
>
  &times;
</button>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      purchaseType: 'repair',
      displayedData: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      showModal: false,
      selectedItem: null,
      showImageModal: false,
      modalImageUrl: '',
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    transformImageUrl(imagePath) {
    // ตรวจสอบว่า `imagePath` ไม่เป็น null หรือ undefined
    if (!imagePath) {
      return null; // หรือคืนค่า default image ถ้าต้องการ
    }
    // เตรียม Base URL สำหรับ images
    const baseUrl = 'https://brkmeeting-room.online/';
    return baseUrl + imagePath.split('/').pop(); // ใช้ .pop() เพื่อเอาชื่อไฟล์ตอนท้าย
  },
    setPurchaseType(type) {
      this.purchaseType = type;
      this.currentPage = 1; // Reset to first page when changing purchase type
      this.fetchData();
    },
    async fetchData() {
      try {
        const response = await axios.get(`https://brk-group.org/api/purchase-requisitions`, {
          params: {
          purchaseType: this.purchaseType,
            page: this.currentPage,
            limit: this.itemsPerPage,
            search: '', // In case you want to add search functionality later
          },
        });

        this.displayedData = response.data.items;
        this.totalItems = response.data.total;
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire('Error', 'เกิดข้อผิดพลาดในการดึงข้อมูล', 'error');
      }
    },
    openImageModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      this.showImageModal = true;
    },
    closeImageModal() {
      this.showImageModal = false;
      this.modalImageUrl = '';
    },
    viewDetail(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
/* Add responsive styles */
@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    max-height: 80vh;
  }
  .container {
    padding: 1rem;
  }
  table, thead, tbody, th, td, tr {
    display: block;
  }
  tr {
    margin-bottom: 1rem;
  }
  th, td {
    padding: 0.5rem;
    text-align: left;
  }
}
</style>