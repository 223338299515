<template>
  <div class="flex">
    <TransportSidebar v-bind:isOpen="isOpen" @toggleSidebar="toggleSidebar" />
    <main class="flex-grow p-4">
      <h1 class="text-2xl font-bold">Transport Maintenance</h1>
      <!-- เนื้อหาของ Transport Maintenance ที่นี่ -->
    </main>
  </div>
</template>

<script>
import TransportSidebar from '@/components/transport/TransportSidebar.vue';

export default {
  name: 'TransportMaintenance',
  components: {
    TransportSidebar,
  },
};
</script>

<style scoped>
/* @media (max-width: 768px) {
  aside {
    width: 100%;
  }
} */
</style>